import { onBeforeUnmount, onMounted, ref, unref, type Ref, watch } from 'vue';
import { useUUID } from './uuid';
import { useView } from './view';

type TitleModel = {
  title: Ref<string>;
};

type TitleArguments = {
  uuid: ReturnType<typeof useUUID>['uuid'];
};

export function useTitle(props: TitleArguments): TitleModel {
  const uuid = unref(props.uuid);

  const { loadComponent, unloadComponent } = useView();

  const title = ref('');

  onMounted(() => {
    loadComponent({ uuid, title: title.value });
  });

  onBeforeUnmount(() => {
    unloadComponent(uuid);
  });

  watch(title, () => {
    loadComponent({ uuid, title: title.value });
  });

  return { title };
}
