<template>
  <v-list-item
    :class="{ 'px-3': !miniVariant, 'px-2': miniVariant }"
    class="pb-10"
  >
    <v-menu
      v-model="userMenu"
      content-class="user-menu"
    >
      <template #activator="{ props }">
        <v-btn
          variant="outlined"
          block
          :class="{ 'px-1': miniVariant, 'user-menu-button': true }"
          v-bind="props"
        >
          <span
            v-if="miniVariant"
            v-text="initials"
          />
          <div
            v-else
            style="display: flex; overflow: hidden; width: 100%; align-items: center"
          >
            <div style="flex: 1 0 0px; overflow: hidden; text-overflow: ellipsis">
              {{ isNameTooLong ? initials : name }}
            </div>
          </div>
          <v-icon
            style="flex: 0 0 auto"
            :class="{ rotate: !userMenu }"
          >
            mdi-menu-down
          </v-icon>
        </v-btn>
      </template>

      <v-list density="compact">
        <v-list-item
          prepend-icon="mdi-account"
          @click="showChangeProfileDialog = true"
        >
          <span v-t="'profile'" />
        </v-list-item>
        <v-list-item
          v-for="(l, idx) in links"
          :key="idx"
          v-bind="l.link"
          :prepend-icon="l.icon"
        >
          <span v-t="l.text" />
        </v-list-item>

        <v-list-item
          class="color-error"
          prepend-icon="mdi-exit-to-app"
          @click="() => emit('logout')"
        >
          <span v-t="'logout'" />
        </v-list-item>
      </v-list>
    </v-menu>
  </v-list-item>

  <change-profile v-model="showChangeProfileDialog" />
</template>

<script setup lang="ts">
import { useUser } from '@web-ui-root/composables/user';
import { useUUID } from '@web-ui-root/composables/uuid';
import initialsFromName from '@web-ui-root/helpers/initials';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import ChangeProfile from '../../views/user/change-profile.vue';

type Props = {
  miniVariant?: boolean;
};

withDefaults(defineProps<Props>(), {
  miniVariant: false,
});

const emit = defineEmits<{
  logout: [];
}>();

useI18n({
  messages: {
    en: {
      profile: 'Profile',
      instructionVideo: 'Instruction videos',
      documentation: 'Documentation',
      logout: 'Logout',
      security: 'Security',
    },
    nl: {
      profile: 'Profiel',
      instructionVideo: 'Instructievideos',
      documentation: 'Documentatie',
      logout: 'Log uit',
      security: 'Beveiliging',
    },
  },
});

const { name } = useUser();
const { uuid } = useUUID();
uuid.value.toString();

const showChangeProfileDialog = ref(false);

const isNameTooLong = computed(() => (name.value === null ? false : name.value.length > 19));
const initials = computed(() => initialsFromName(name.value ?? ''));

const links = [
  {
    icon: 'mdi-lock',
    text: 'security',
    link: {
      to: '/security-settings',
    },
  },
  {
    icon: 'mdi-video-box',
    text: 'instructionVideo',
    link: {
      href: 'https://www.youtube.com/playlist?list=PL6_hltcRWyYFTP9d95ZrJTvF0LQJxltSi',
      target: '_blank',
    },
  },
  {
    icon: 'mdi-file-cog',
    text: 'documentation',
    link: {
      href: 'https://docs.withthegrid.com/',
      target: '_blank',
    },
  },
];

const userMenu = ref(false);
</script>

<style scoped>
.rotate {
  transform: rotate(180deg);
}
.v-list-item {
  color: rgba(0, 0, 0, 87);
}
.v-list-item span {
  font-size: 13px;
  font-weight: 500;
}
.v-list-item.color-error {
  color: rgb(229, 115, 115, 1);
}

.user-menu-button :deep(.v-btn__content) {
  width: 100%;
}
</style>

<style>
.v-list-item.color-error .v-icon {
  opacity: 1;
}
</style>
