<template>
  <v-list-item :class="{ 'px-3': !miniVariant, 'px-2': miniVariant }">
    <v-btn
      :style="{ minWidth: miniVariant ? '40px' : '100%' }"
      :class="{ 'px-1': miniVariant }"
      variant="outlined"
      @click="showDialog = true"
    >
      <v-icon v-if="miniVariant"> mdi-email-fast </v-icon>
      <span
        v-else
        v-t="'gotFeedback'"
      />
    </v-btn>

    <w-dialog
      v-model="showDialog"
      :title="t('gotFeedback')"
      :state="dialogState"
    >
      <p class="mb-4">
        {{ t('dialogBody') }}
      </p>
      <v-form v-model="isValid">
        <v-text-field
          v-model="theme"
          :label="t('theme')"
          :rules="[stringNotTooLong(255)]"
          counter="255"
          variant="underlined"
        />
        <v-textarea
          v-model="feedback"
          :label="t('feedback')"
          :rules="[nonEmptyString, stringNotTooLong(1000)]"
          auto-grow
          rows="3"
          counter="1000"
          variant="underlined"
        />
      </v-form>

      <template #main-action>
        <v-btn
          :disabled="!isValid"
          :loading="sending"
          color="primary"
          variant="elevated"
          @click="send"
        >
          <span v-t="'send'" />
        </v-btn>
      </template>
    </w-dialog>
  </v-list-item>
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useBusHandler } from '@web-ui-root/composables/bus-handler';
import { useUUID } from '@web-ui-root/composables/uuid';

import WDialog from '@web-ui-root/components/dialogs/w-dialog.vue';
import { useSDK } from '@web-ui-root/composables/sdk';

import { nonEmptyString, stringNotTooLong } from '@web-ui-root/helpers/rules';

type Props = {
  miniVariant?: boolean;
};

withDefaults(defineProps<Props>(), {
  miniVariant: false,
});
const { emit } = useBusHandler();
const { sdk } = useSDK();
const { uuid } = useUUID();
uuid.value.toString();

const { t } = useI18n({
  messages: {
    en: {
      gotFeedback: 'Got feedback?',
      theme: 'Subject',
      feedback: 'Write your feedback',
      dialogBody: 'Share your thoughts about Withthegrid with us.',
      send: 'Send',
      thankYou: 'Thank you for you feedback',
    },
    nl: {
      gotFeedback: 'Feedback geven?',
      theme: 'Onderwerp',
      feedback: 'Schrijf uw feedback',
      dialogBody: 'Deel uw mening over Withthegrid met ons.',
      send: 'Versturen',
      thankYou: 'Bedankt voor je feedback',
    },
  },
});

const showDialog = ref(false);
const theme = ref('');
const feedback = ref('');
const isValid = ref(true);
const sending = ref(false);

const dialogState = computed(() => {
  if ((theme.value && theme.value !== '') || (feedback.value && feedback.value !== '')) {
    return 'tainted';
  }

  return 'pristine';
});

watch(showDialog, (val) => {
  if (!val) {
    reset();
  }
});

async function send() {
  sending.value = true;

  try {
    const result = sdk.routes.settings.feedback({
      body: {
        theme: theme.value === '' ? undefined : theme.value,
        feedback: feedback.value,
      },
    });
    await result.response;
  } catch (err) {
    emit('commsError', err);
    sending.value = false;
    return;
  }

  sending.value = false;
  reset();
  showDialog.value = false;
  emit('success', t('thankYou'));
}

function reset() {
  theme.value = '';
  feedback.value = '';
}
</script>
