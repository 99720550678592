import type { models } from '@withthegrid/amp-sdk';

export default {
  en: {
    '5m': 'Last 5 minutes',
    '15m': 'Last 15 minutes',
    '30m': 'Last 30 minutes',
    '1h': 'Last hour',
    '3h': 'Last 3 hours',
    '6h': 'Last 6 hours',
    '12h': 'Last 12 hours',
    '24h': 'Last 24 hours',
    '48h': 'Last 48 hours',
    '7d': 'Last 7 days',
    '30d': 'Last 30 days',
    '90d': 'Last 90 days',
    '6M': 'Last 6 months',
    '1y': 'Last year',
    '2y': 'Last 2 years',
    '3y': 'Last 3 years',
    '4y': 'Last 4 years',
    '5y': 'Last 5 years',
    today: 'Today',
    yesterday: 'Yesterday',
    week: 'This week',
    lastWeek: 'Previous week',
    month: 'This month',
    lastMonth: 'Previous month',
    year: 'This year',
    lastYear: 'Previous year',
  } satisfies Record<models.timeRange.KnownTimeRangesKey, string>,
  nl: {
    '5m': 'Laatste 5 minuten',
    '15m': 'Laatste 15 minuten',
    '30m': 'Laatste 30 minuten',
    '1h': 'Laatste uur',
    '3h': 'Laatste 3 uur',
    '6h': 'Laatste 6 uur',
    '12h': 'Laatste 12 uur',
    '24h': 'Laatste 24 uur',
    '48h': 'Laatste 48 uur',
    '7d': 'Laatste 7 dagen',
    '30d': 'Laatste 30 dagen',
    '90d': 'Laatste 90 dagen',
    '6M': 'Laatste 6 maanden',
    '1y': 'Laatste jaar',
    '2y': 'Laatste 2 jaar',
    '3y': 'Laatste 3 jaar',
    '4y': 'Laatste 4 jaar',
    '5y': 'Laatste 5 jaar',
    today: 'Vandaag',
    yesterday: 'Gisteren',
    week: 'Deze week',
    lastWeek: 'Vorige week',
    month: 'Deze maand',
    lastMonth: 'Vorige maand',
    year: 'Dit jaar',
    lastYear: 'Vorig jaar',
  } satisfies Record<models.timeRange.KnownTimeRangesKey, string>,
};
