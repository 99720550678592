import { models } from '@withthegrid/amp-sdk';
import { type DateTimeUnit } from '@web-ui-root/helpers/date-utils';
import timeRanges from '../../../../helpers/i18n/time-ranges';

type AnyRecord = Record<string, string>;
type TimeUnitsRecord = { timeUnits: Record<DateTimeUnit, string> };
type L10n = Record<models.locale.Locale, Record<string, AnyRecord> & TimeUnitsRecord>;

const t: L10n = {
  en: {
    comparison: {
      '=': '=',
      '<>': '!=',
      '>': '>',
      '>=': '>=',
      '<': '<',
      '<=': '<=',
      like: 'like',
      notLike: 'not like',
      isNull: 'is not set',
      isNotNull: 'is set',
      isNamedRange: 'is',
      inGrid: 'is in group',
    },
    namedRange: timeRanges.en,
    timeUnits: {
      year: 'year | years',
      month: 'month | months',
      day: 'day | days',
      hour: 'hour | hours',
      minute: 'minute | minutes',
      second: 'second | seconds',
      quarter: 'quarter | quarters',
      millisecond: 'millisecond | milliseconds',
      week: 'week | weeks',
    },
  },
  nl: {
    comparison: {
      '=': '=',
      '<>': '!=',
      '>': '>',
      '>=': '>=',
      '<': '<',
      '<=': '<=',
      like: 'like',
      notLike: 'niet like',
      isNull: 'is niet ingevuld',
      isNotNull: 'is ingevuld',
      isNamedRange: 'is',
      inGrid: 'is in groep',
    },
    namedRange: timeRanges.nl,
    timeUnits: {
      year: 'jaar | jaren',
      month: 'maand | maanden',
      day: 'dag | dagen',
      hour: 'uur | uren',
      minute: 'minuut | minuten',
      second: 'seconde | seconden',
      quarter: 'kwartaal | kwartalen',
      millisecond: 'milliseconde | milliseconden',
      week: 'week | weken',
    },
  },
};

export default t;
