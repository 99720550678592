import { DateTime } from 'luxon';
import { zones } from 'tzdata';

const luxonValidTimezones = [
  ...new Set<string>(
    Object.keys(zones).filter((tz) => tz.includes('/') && DateTime.local().setZone(tz).isValid),
  ),
].sort((a, b) => (a < b ? -1 : 1));

export default luxonValidTimezones;
