import { type AsyncComponentLoader, type Component, defineAsyncComponent } from 'vue';
import ErrorComponent from '@web-ui-root/components/views-loading/error-view.vue';
import LoadingComponent from '@web-ui-root/components/views-loading/loading-bar.vue';
import LoadingDialogComponent from '@web-ui-root/components/views-loading/loading-dialog.vue';
import ErrorDialogComponent from '@web-ui-root/components/views-loading/error-dialog.vue';
import { useRollbar } from '@web-ui-root/composables/rollbar';
import { DEV } from './vite';

const timeout = DEV ? 20000 : 10000;
const { warn } = useRollbar();

function tryCapturingRollbarTelemetry(error: Error, componentLoaderName: string) {
  warn(`${componentLoaderName} got an error event while lazy loading`, error);
}

export const lazyComponentLoader = (component: AsyncComponentLoader): unknown =>
  defineAsyncComponent({
    loader: component,
    loadingComponent: LoadingComponent,
    errorComponent: ErrorComponent,
    timeout,
    onError(error: Error, _retry, fail) {
      tryCapturingRollbarTelemetry(error, 'lazyComponentLoader');
      fail();
    },
  });

export const lazyDialogLoader = (component: AsyncComponentLoader): unknown =>
  defineAsyncComponent({
    loader: component,
    loadingComponent: LoadingDialogComponent,
    errorComponent: ErrorDialogComponent,
    timeout,
    onError(error: Error, _retry, fail) {
      tryCapturingRollbarTelemetry(error, 'lazyDialogLoader');
      fail();
    },
  });

export function lazyRouterViewLoader(component: AsyncComponentLoader, isDialog = false): Component {
  return defineAsyncComponent({
    loader: component,
    loadingComponent: isDialog ? LoadingDialogComponent : LoadingComponent,
    errorComponent: isDialog ? ErrorDialogComponent : ErrorComponent,
    timeout,
    onError(error: Error, _retry, fail) {
      tryCapturingRollbarTelemetry(error, 'lazyRouterViewLoader');
      fail();
    },
  });
}
