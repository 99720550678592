<template>
  <v-list-item
    v-if="hasAnUpdate"
    :class="{ 'px-3': !miniVariant, 'px-2': miniVariant }"
  >
    <div
      class="bg-colorful"
      :class="{ mini: miniVariant }"
    >
      <v-btn
        :style="{ minWidth: miniVariant ? '40px' : '100%' }"
        :class="{ 'px-1': miniVariant }"
        variant="outlined"
        @click="showDialog = true"
      >
        <v-icon v-if="miniVariant"> mdi-new-box </v-icon>
        <span
          v-else
          v-t="'whatsnew'"
          :style="{ 'font-size': fontSize }"
        />
      </v-btn>
    </div>

    <w-dialog
      :model-value="showDialog"
      :title="title"
      state="pristine"
      max-width="600"
      @update:model-value="onClose"
    >
      <!-- eslint-disable vue/no-v-html -->
      <div
        class="content"
        v-html="renderedLatestUpdate"
      />
      <!-- eslint-enable vue/no-v-html -->
    </w-dialog>
  </v-list-item>
</template>

<script lang="ts" setup>
import { useUser } from '@web-ui-root/composables/user';
import { useUUID } from '@web-ui-root/composables/uuid';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { marked } from 'marked';

import WDialog from '@web-ui-root/components/dialogs/w-dialog.vue';
import { useTitle } from '@web-ui-root/composables/title';
import Invariant from '@web-ui-root/helpers/invariant';
import { useLastFeatureUpdate } from './last-feature-update';

const DOCS_BASE_URL = 'https://docs.withthegrid.com';

type Props = {
  miniVariant?: boolean;
};

withDefaults(defineProps<Props>(), {
  miniVariant: false,
});

const { uuid } = useUUID();
uuid.value.toString();
const { title } = useTitle({ uuid });
const { locale, lastFeatureUpdateSeen, markUpdateAsSeen } = useUser();
const { hasAnUpdate, markAsSeen, latestUpdate } = useLastFeatureUpdate({
  locale: locale.value,
  lastSeen: lastFeatureUpdateSeen,
  markAsSeen: markUpdateAsSeen,
});

const { t } = useI18n({
  messages: {
    en: {
      whatsnew: "See what's new!",
      title: 'New feature',
      docs: 'Documentation',
    },
    nl: {
      whatsnew: 'Nieuwe feature!',
      title: 'Nieuwe functionaliteit',
      docs: 'Documentatie',
    },
  },
});

title.value = `${t('title')} - ${latestUpdate.value.title}`;

const showDialog = ref(false);

const renderer = new marked.Renderer();
const tokenizer = new marked.Tokenizer();

/**
 * This whole function is to add support to a "component" style tag
 * in the markdown content.
 * This will replace `<to-docs>url#anchor|optionalTitle</to-docs>` with a button
 * leading to the withthegrid documentation page
 */
function toDocsTag(tag: string) {
  const inner = tag.replace('<to-docs>', '').replace('</to-docs>', '');
  const [loc, docsTitle] = inner.split('|');
  Invariant.assertPresent(loc, 'A target link is required for the to-docs tag');
  Invariant.assertNotEq(loc, '', 'A target link is required for the to-docs tag');

  const location = `${DOCS_BASE_URL}/${loc.trim()}`;
  const docsTitleTrimmed = docsTitle?.trim();

  const button = `
    <div class="to-docs-btn">
      <a href="${location}">
        ${docsTitleTrimmed || t('docs')}
      </a>
    </div>
    `;

  return {
    type: 'html',
    raw: tag,
    inLink: false,
    inRawBlock: false,
    text: button,
    block: true,
  };
}

/* eslint-disable consistent-return */
// @ts-expect-error - the tokenizer changes the token only if returned
tokenizer.tag = (tag) => {
  if (tag.startsWith('<to-docs>') && tag.endsWith('</to-docs>')) {
    return toDocsTag(tag);
  }
};
/* eslint-enable consistent-return */

// clickable + figcaption under
renderer.image = (href, ttl, text) => {
  let out = `<a target="_blank" href="${href}"><img src="${href}" alt="${text}"`;
  if (title) {
    out += ` title="${ttl}"`;
  }
  out += `></a><figcaption>${text}</figcaption>`;
  return out;
};

marked.setOptions({
  breaks: false,
  gfm: true,
});

function render(raw: string) {
  return marked(raw, { renderer, tokenizer });
}

const fontSize = computed(() => (locale.value === 'en' ? '14px' : '.825rem'));

const renderedLatestUpdate = computed(() => {
  if (latestUpdate.value) {
    return render(latestUpdate.value.content);
  }
  return '';
});

async function onClose() {
  await markAsSeen();
  showDialog.value = false;
}
</script>

<style scoped lang="scss">
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.bg-colorful {
  --bg-colorful-border-radius: 4px;
  --bg-colorful-border-width: 3px;

  :deep(button) {
    border: none;
  }

  &.mini {
    --bg-colorful-border-width: 2px;

    width: 40px;

    &::before {
      width: 64px;
      height: 64px;
      top: -35%;
      left: -27%;
    }
  }

  position: relative;
  overflow: hidden;
  border: none;
  border-radius: var(--bg-colorful-border-radius);

  &::after,
  &::before {
    content: '';
    display: block;
    position: absolute;
    z-index: 0;
  }

  &::before {
    width: calc(100% + 0.5rem);
    padding-top: calc(100% + 0.5rem);
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      from(#9650fd),
      color-stop(#f24323),
      color-stop(#ff9288),
      color-stop(#1d78e2),
      color-stop(#1ab3fa),
      to(#0dc775)
    );
    background: linear-gradient(90deg, #9650fd, #f24323, #ff9288, #1d78e2, #1ab3fa, #0dc775);
    top: -215%;
    left: -0.25rem;
    animation: spin 2.5s linear infinite;
  }

  &::after {
    background-color: white;
    top: var(--bg-colorful-border-width);
    right: calc(var(--bg-colorful-border-width) + 1px);
    bottom: var(--bg-colorful-border-width);
    left: var(--bg-colorful-border-width);
    border-radius: var(--bg-colorful-border-radius);
  }

  > * {
    z-index: 10;
  }
}

@media (max-width: 959px) {
  .content {
    :deep(img) {
      width: 100%;
      max-height: 500px;
      object-fit: contain;
    }
  }
}

@media (min-width: 960px) {
  .content {
    :deep(img) {
      width: 552px;
      max-height: 500px;
      object-fit: contain;
    }
  }
}

.content {
  text-align: justify;

  :deep(h2:not(:first-child)) {
    margin: 1.5rem 0;
    color: rgba(black, 0.7);
  }

  :deep(h3) {
    margin: 1rem 0;
    color: rgba(black, 0.7);
  }

  :deep(figcaption) {
    width: 100%;
    text-align: center;
    font-size: 0.75rem;
    color: #888;
    margin-bottom: 0.5rem;
  }

  :deep(hr) {
    margin-top: 0.5rem;
    border-top: 2px dashed rgba(black, 0.4);
    margin-bottom: 1.5rem;
  }

  :deep(.to-docs-btn) {
    margin-top: 0.5rem;
    width: fit-content;
    height: 2.25rem;
    background-color: rgb(var(--v-theme-info), 0.87) !important;
    border-radius: 0.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow:
      0 3px 1px -2px var(--v-shadow-key-umbra-opacity, rgba(0, 0, 0, 0.2)),
      0 2px 2px 0 var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.14)),
      0 1px 5px 0 var(--v-shadow-key-ambient-opacity, rgba(0, 0, 0, 0.12));
    transition: all 0.1s ease-in-out;

    &:hover {
      box-shadow:
        0 2px 4px -1px var(--v-shadow-key-umbra-opacity, rgba(0, 0, 0, 0.2)),
        0 4px 5px 0 var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.14)),
        0 1px 10px 0 var(--v-shadow-key-ambient-opacity, rgba(0, 0, 0, 0.12));
      background-color: rgb(var(--v-theme-info), 1) !important;
    }

    > a {
      margin: 0.125rem 0.75rem;
      color: rgba(0, 0, 0, 0.87);
      text-decoration: none;
      text-transform: uppercase;
      font-family: 'Roboto', sans-serif;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 1.25px;
    }
  }
}
</style>
