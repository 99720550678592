<template>
  <v-card ref="card">
    <v-list
      class="py-0"
      density="compact"
    >
      <v-list-subheader
        class="bg-grey-lighten-5 filter-list-subheader py-2"
        :class="{ 'pr-1': !inMenu }"
      >
        <div class="d-flex align-center justify-space-between">
          {{ title }}
          <div>
            <v-tooltip
              v-if="modelValue.length > 0 && clearable"
              location="bottom"
            >
              <template #activator="{ props: tooltipProps }">
                <v-btn
                  variant="text"
                  size="small"
                  class="mr-0 header-button"
                  v-bind="tooltipProps"
                  density="compact"
                  icon="mdi-delete"
                  @click="clear"
                />
              </template>
              <span v-t="'clear'" />
            </v-tooltip>
            <v-tooltip
              v-if="!inMenu"
              location="bottom"
            >
              <template #activator="{ props: tooltipProps }">
                <v-btn
                  variant="text"
                  size="small"
                  class="header-button"
                  v-bind="tooltipProps"
                  density="compact"
                  icon="mdi-close"
                  @click="$emit('close')"
                />
              </template>
              <span v-t="'close'" />
            </v-tooltip>
          </div>
        </div>
      </v-list-subheader>

      <template v-if="!hideSearch">
        <v-divider />
        <v-list-item class="text-field-list-tile">
          <v-text-field
            ref="textField"
            v-model="input"
            variant="outlined"
            hide-details
            single-line
            :placeholder="placeholder"
            density="compact"
            class="text-field"
            color="primary"
            @update:model-value="updateFilter"
            @change="addOptionInManualMode"
            @keydown.enter="earlySelect"
          />
        </v-list-item>
      </template>
      <div
        ref="scrollParent"
        class="scroll-parent"
        :style="scrollParentStyle"
      >
        <div
          v-for="item in items"
          :key="item.key"
        >
          <v-divider />
          <v-list-item @click="toggle(item)">
            <div class="d-flex align-center">
              <div
                v-if="hasSelected && checkmark"
                style="width: 36px"
              >
                <v-icon
                  v-if="modelValue.includes(item.key)"
                  color="info"
                >
                  mdi-check
                </v-icon>
              </div>
              <v-avatar
                v-if="item.color !== undefined"
                :style="{
                  background: `${transparentLabelBackground(item.color)}`,
                  border: `2px solid ${item.color}`,
                }"
                size="16"
                class="mr-2"
              />
              <!-- eslint-disable vue/no-v-html vue/no-v-text-v-html-on-component -->
              <v-list-item-title
                :class="{
                  'font-italic': item.value === null,
                  'font-weight-regular': true,
                  'info--text': modelValue.includes(item.key),
                }"
                style="max-width: 300px"
              >
                <text-overflow>
                  <span v-html="genFilteredText(item.title)" />
                </text-overflow>
              </v-list-item-title>
              <!-- eslint-enable vue/no-v-html vue/no-v-text-v-html-on-component -->
            </div>
          </v-list-item>
        </div>
      </div>
      <v-progress-linear
        v-if="loading"
        :indeterminate="true"
        color="primary"
        class="ma-0"
        height="4"
      />
      <template v-else-if="items.length === 0 && options !== null">
        <v-divider />
        <v-list-item
          class="bg-grey-lighten-5"
          style="font-size: 0.8125rem"
        >
          <v-icon
            size="small"
            color="info"
            class="mr-1"
          >
            mdi-information
          </v-icon>
          <span v-t="{ path: 'noResults', args: [namePlural] }" />
        </v-list-item>
      </template>
      <template v-if="items.length > rowsToShow && !fullyScrolledDown">
        <v-divider />
        <v-list-item
          class="bg-grey-lighten-5"
          style="font-size: 0.8125rem"
        >
          <v-icon
            size="small"
            color="info"
            class="mr-1"
          >
            mdi-alert-circle
          </v-icon>
          <span v-t="{ path: 'scroll', args: [namePlural] }" />
        </v-list-item>
      </template>
      <template v-else>
        <v-list-item class="hidden-hint">
          <v-icon
            size="small"
            color="info"
            class="mr-1 h-0"
          >
            mdi-alert-circle
          </v-icon>
          <span v-t="{ path: 'scroll', args: [namePlural] }" />
        </v-list-item>
      </template>
      <v-btn
        v-if="!allRowsAvailable && !allowDuplicates && fullyScrolledDown"
        width="100%"
        color="grey-lighten-4"
        @click="loadMoreRows(true)"
      >
        <span v-t="'loadMore'" />
      </v-btn>
    </v-list>
  </v-card>
</template>

<script setup lang="ts">
import { ref, type Ref, computed, onMounted, onBeforeUnmount, nextTick } from 'vue';
import { useI18n } from 'vue-i18n';
import { errors as sdkErrors, models } from '@withthegrid/amp-sdk';
import { useUser } from '@web-ui-root/composables/user';
import { useUUID } from '@web-ui-root/composables/uuid';
import transparentLabelBg from '@web-ui-root/views/monitoring-environment/label/label-helper';
import { useBusHandler } from '@web-ui-root/composables/bus-handler';
import TextOverflow from '@web-ui-root/components/text-overflow.vue';
import textWithHighlight from '../../helpers/text-with-highlight';
import type { FilterOptionsOption, GenericFilter } from '../server-table/types';

const rowHeight = 41;

const { t } = useI18n({
  messages: {
    en: {
      filter: 'Filter {0}',
      manualEntry: 'Enter a {0}',
      noResults: 'No {0} found.',
      scroll: 'Scroll down to see more {0}.',
      close: 'Close this dialog',
      clear: 'Clear the filter',
      loadMore: 'Load more',
    },
    nl: {
      filter: 'Filter {0}',
      manualEntry: 'Voer een {0} in',
      noResults: 'Geen {0} gevonden.',
      scroll: 'Scroll om meer {0} te zien.',
      close: 'Sluit dit dialoogvenster',
      clear: 'Wis het filter',
      loadMore: 'Laad meer',
    },
  },
});

const { uuid } = useUUID();
uuid.value.toString();
const { locale } = useUser();
const { emit: busEmit } = useBusHandler();

type Item = {
  key: string;
  title: string;
  value: unknown;
  color?: string;
};

type Props = {
  modelValue: Array<string>;
  title: string;
  nameManualEntry?: string;
  namePlural: string;
  options: GenericFilter['options'] | null;
  multiple?: boolean;
  clearable?: boolean;
  hideSearch?: boolean;
  checkmark?: boolean;
  emptyOption?: string | null;
  inMenu?: boolean;
  allowDuplicates?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  nameManualEntry: undefined,
  multiple: true,
  clearable: true,
  hideSearch: false,
  checkmark: true,
  emptyOption: null,
  inMenu: false,
  allowDuplicates: true,
});

const emit = defineEmits<{
  'update:modelValue': [items: string[]];
  close: [];
}>();

const items: Ref<Array<Item>> = ref([]);
const input = ref('');
const loading = ref(false);
const rowsToShow = ref(10);
const fullyScrolledDown = ref(false);
const allRowsAvailable = ref(false);
const cancelFunction: Ref<null | (() => void)> = ref(null);
const nextPageOffset: Ref<undefined | null | string> = ref(undefined);
const card: Ref<{
  $el: HTMLElement;
} | null> = ref(null);

const scrollParent: Ref<HTMLElement | null> = ref(null);
const textField: Ref<HTMLElement | null> = ref(null);

const hasSelected = computed(() => items.value.some((i) => props.modelValue.includes(i.key)));

const placeholder = computed(() => {
  if (props.options === null) {
    return t('manualEntry', [props.nameManualEntry]);
  }
  return t('filter', [props.namePlural]);
});

const scrollParentStyle = computed(() => `max-height: ${rowsToShow.value * rowHeight}px`);

onMounted(() => {
  initialize();
  if (scrollParent.value !== null) {
    scrollParent.value.addEventListener('scroll', updateScrolledDown);
  }
});

onBeforeUnmount(() => {
  if (scrollParent.value !== null) {
    scrollParent.value.removeEventListener('scroll', updateScrolledDown);
  }
});

// suggestion from
// https://github.com/withthegrid/platform/discussions/3525#discussioncomment-7789045
// if we want to use this in some more places in the future ((w|v)-autocomplete), we should
// make this a small composable to compose the feature into the components
function earlySelect(): void {
  if (items.value.length > 0) {
    const first = items.value[0];
    toggle(first);
  }
}

function initialize(): void {
  input.value = '';
  if (scrollParent.value !== null) {
    scrollParent.value.scrollTop = 0;
  }
  nextPageOffset.value = undefined;
  updateFilter();
  nextTick(() => {
    if (textField.value !== null) {
      textField.value.focus();
    }
  });
}

function toggle(item: Item): void {
  const selected = props.modelValue.includes(item.key);

  if (!props.multiple) {
    emit('close');
    if (selected) {
      emit('update:modelValue', []);
      return;
    }
    emit('update:modelValue', [item.key]);
    return;
  }
  if (selected) {
    emit(
      'update:modelValue',
      props.modelValue.filter((v) => v !== item.key),
    );
    if (props.options === null) {
      items.value = items.value.filter((i) => i.key !== item.key);
    }
    return;
  }
  emit('update:modelValue', props.modelValue.concat(item.key));
}

function clear(): void {
  emit('update:modelValue', []);
  emit('close');
}

function addOptionInManualMode(): void {
  if (props.options !== null) {
    return;
  }
  const item = { value: input.value, title: input.value, key: input.value };
  items.value.push(item);
  toggle(item);
  input.value = '';
  allRowsAvailable.value = true;
}

function updateRowsToShow(): void {
  if (card.value === null) {
    return;
  }

  const header = 2 * rowHeight;
  const footer = 2 * rowHeight; // max. single row, but let's include margin
  const cardClientRect = card.value.$el.getBoundingClientRect();
  const distanceToBottom = document.documentElement.clientHeight - cardClientRect.bottom;
  const maxRowsInView = Math.floor((distanceToBottom - header - footer) / rowHeight);
  rowsToShow.value = Math.max(Math.min(15, maxRowsInView), 3);
}

async function updateFilter(): Promise<void> {
  updateRowsToShow();
  items.value = [];
  if (input.value === '' && props.emptyOption !== null) {
    items.value.push({
      value: null,
      key: 'null',
      title: props.emptyOption,
    });
  }
  if (props.options === null) {
    items.value = props.modelValue
      .filter((v) => v !== 'null')
      .map((key) => ({
        value: key,
        key,
        title: key,
      }));
  } else {
    allRowsAvailable.value = false;
    await loadMoreRows(true);
  }
  if (scrollParent.value !== null) {
    scrollParent.value.scrollTop = 0;
    fullyScrolledDown.value =
      scrollParent.value.scrollHeight -
        scrollParent.value.scrollTop -
        scrollParent.value.clientHeight <
      1;
  }
}

async function loadMoreRows(force: boolean): Promise<void> {
  if (props.options === null || typeof props.options !== 'function') {
    return;
  }

  if (loading.value) {
    if (force) {
      if (typeof cancelFunction.value === 'function') {
        cancelFunction.value();
        cancelFunction.value = null;
      }
    } else {
      return;
    }
  }

  const rowsToLoad = Math.max(rowsToShow.value * 3, 10);
  const result = props.options(
    input.value,
    rowsToLoad,
    nextPageOffset.value === null ? undefined : nextPageOffset.value,
  );
  cancelFunction.value = result.cancelFunction;
  loading.value = true;
  let options: Array<
    | FilterOptionsOption
    | {
        value?: string | object;
        title: models.stringOrTranslations.StringOrTranslations;
        key: string;
      }
  > = [];
  try {
    const data = await result.options;
    options = data.options;
    nextPageOffset.value = data.nextPageOffset;
  } catch (err) {
    if (!(err instanceof sdkErrors.CommsCanceled)) {
      busEmit('commsError', err);
    }
    return;
  }
  loading.value = false;
  cancelFunction.value = null;
  options.forEach((o) => {
    if (!props.allowDuplicates && items.value.some((i) => i.key === o.key)) {
      return;
    }
    items.value.push(o as Item);
  });
  allRowsAvailable.value = (nextPageOffset.value ?? null) === null;
}

async function updateScrolledDown(): Promise<void> {
  if (scrollParent.value !== null) {
    fullyScrolledDown.value =
      scrollParent.value.scrollHeight -
        scrollParent.value.scrollTop -
        scrollParent.value.clientHeight <
      1;
    const rows = rowsToShow.value === 0 ? 1 : rowsToShow.value;
    const offset = scrollParent.value.scrollHeight - scrollParent.value.scrollTop;
    const rowsLeftToScroll = Math.max(offset / rowHeight - rows, 0);
    if (rowsLeftToScroll < rowsToShow.value && !allRowsAvailable.value) {
      await loadMoreRows(false);
    }
  }
}

function genFilteredText(text: string): string {
  return textWithHighlight(
    models.stringOrTranslations.getTranslatedString(text, locale.value),
    input.value,
  );
}

const transparentLabelBackground = transparentLabelBg;
</script>

<style scoped>
.text-field {
  font-size: 13px; /* to match dense list */
}
.text-field :deep(input) {
  margin: 0px;
  min-height: 32px;
  font-size: 12px;
}
.scroll-parent {
  overflow-y: auto;
}
.v-list-subheader {
  font-size: 12px;
  color: #0009 !important;
}

.v-list-item-title {
  font-size: 12px;
}

.header-button {
  height: 28px;
  width: 28px;
}

.hidden-hint {
  font-size: 0.8125rem;
  opacity: 0;
  height: 0;
  min-height: 0;
  padding: 2px 10px;

  & > :deep(.v-list-item__content) {
    height: 0;
  }

  span {
    height: 0;
  }
}
</style>

<style>
.filter-list-subheader .v-list-subheader__text {
  width: 100%;
}
</style>
