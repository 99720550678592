function getMostImportantCharacter(word: string): string {
  // see https://stackoverflow.com/questions/4547609/how-to-get-character-array-from-a-string/34717402#34717402
  const letters = Array.from(word);
  if (letters.length === 0) {
    return '';
  }
  const firstUpperCaseLetter = letters.find((l) => /[A-Z]/.test(l));
  if (firstUpperCaseLetter !== undefined) {
    return firstUpperCaseLetter;
  }
  return letters[0];
}

function initials(name: string): string {
  const words = (name ?? '').split(' ');
  if (words.length === 0) {
    return '?';
  }
  if (words.length === 1) {
    return words[0].slice(0, 2);
  }
  const first = words[0];
  const bestOfRest = words.slice(1).reduce((coll, val) => {
    const collHasUppercase = /[A-Z]/.test(coll);
    const valHasUppercase = /[A-Z]/.test(val);
    if (valHasUppercase && !collHasUppercase) {
      return val;
    }
    if (!valHasUppercase && collHasUppercase) {
      return coll;
    }
    if (val.length > coll.length) {
      return val;
    }
    return coll;
  });
  return `${getMostImportantCharacter(first)}${getMostImportantCharacter(bestOfRest)}`;
}

export default initials;
