import escapeStringRegexp from './escape-string-regex';

const tagsToReplace: Record<string, string> = {
  '&': '&amp;',
  '<': '&lt;',
  '>': '&gt;',
};

function escapeHTML(str: string): string {
  return str.replace(/[&<>]/g, (tag: string) =>
    tagsToReplace[tag] !== undefined ? tagsToReplace[tag] : tag,
  );
}

function genHighlight(text: string): string {
  return `<span class="v-list-item__mask">${escapeHTML(text)}</span>`;
}

type MaskedChars = {
  start: string;
  middle: string;
  end: string;
};

function getMaskedCharacters(text: string, searchInput: string): MaskedChars {
  const searchInputLC = searchInput.toString().toLocaleLowerCase();
  const escapedSearchInput = escapeStringRegexp(searchInputLC);

  const regex = new RegExp(`\\b${escapedSearchInput}`);
  const match = text.toLocaleLowerCase().match(regex);
  if (match !== null && match.index !== undefined) {
    const start = text.slice(0, match.index);
    const middle = text.slice(match.index, match.index + match[0].length);
    const end = text.slice(match.index + middle.length);
    return { start, middle, end };
  }
  return { start: text, middle: '', end: '' };
}

function textWithHighlight(text: string, searchInput: string): string {
  if (searchInput === '') return escapeHTML(text);

  const { start, middle, end } = getMaskedCharacters(text, searchInput);

  return `${escapeHTML(start)}${genHighlight(middle)}${escapeHTML(end)}`;
}

export default textWithHighlight;
