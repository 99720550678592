<template>
  <w-dialog
    :addable="addable"
    :fullscreen="fullscreen"
    :hide-actions="false"
    :max-width="maxWidth"
    :show-toolbar="searchable || showBulkActions"
    :title="title ?? ''"
    :model-value="modelValue"
    :state="dialogState"
    :content-classes="contentClasses"
    card-text-classes="pa-0"
    @update:model-value="(val: boolean) => emit('update:modelValue', val)"
    @new="emit('new')"
  >
    <template
      v-if="$slots.title !== undefined"
      #title
    >
      <slot name="title" />
    </template>
    <template #toolbar>
      <div class="pa-1">
        <span v-if="showBulkActions">
          <v-btn
            color="grey-darken-1"
            variant="text"
            icon
            @click="selectedAll ? unselectAll() : selectAll()"
          >
            <v-icon>
              {{
                selectedAll
                  ? 'mdi-checkbox-multiple-marked-outline'
                  : 'mdi-checkbox-multiple-blank-outline'
              }}
            </v-icon>
            <v-tooltip
              activator="parent"
              location="bottom"
            >
              <span
                v-text="
                  selectedAll
                    ? t('unselectAll', [availableToSelectRows.length])
                    : t('selectAll', [availableToSelectRows.length])
                "
              />
            </v-tooltip>
          </v-btn>
          <slot name="bulkActions"> </slot>
        </span>
      </div>
      <search-and-sort
        v-if="searchable"
        ref="searchAndSort"
        v-model="searchInput"
        :placeholder="t('searchPlaceholder', [objectsName])"
        :sort-by-options="sortByOptions"
        :sort-by="sortBy"
        :descending="descending"
        :filters="filters"
        :filter-values="selectedFilterKeys"
        :immediate-search="immediateSearch"
        class="mx-5"
        @change="(c) => initialize(false, c.sortBy, c.search, c.descending)"
        @update:filters="(key, value) => updateSearchToFilters(key, value)"
      >
        <template #append-outer>
          <slot name="append-outer" />
        </template>
      </search-and-sort>
    </template>
    <template
      v-if="$slots.header !== undefined"
      #header
    >
      <slot name="header" />
      <v-divider />
    </template>

    <v-list
      lines="two"
      class="py-0"
    >
      <div
        v-for="(item, index) in rows"
        :key="`${index}-${String(dotReach(item, rowKey))}`"
      >
        <slot
          name="item"
          :item="item"
          :select-item="selectItem"
          :index="index"
          :rows="rows"
        >
          <v-list-item
            :to="clickable ? undefined : item.to"
            class="px-6"
            v-on="clickable ? { click: () => emit('row-click', item) } : {}"
          >
            <div>
              <v-list-item-title :class="item.titleClass">
                {{ item.title }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ item.subTitle }}
              </v-list-item-subtitle>
            </div>
            <v-list-item-action v-if="item.action !== undefined">
              <span>{{ item.action }}</span>
            </v-list-item-action>
          </v-list-item>
        </slot>
        <v-divider v-if="index + 1 < rows.length" />
      </div>
      <div
        v-if="rows.length === 0 && dialogState !== 'loading'"
        class="pb-4"
      >
        <v-alert
          color="info"
          icon="mdi-information"
          variant="outlined"
          class="ma-4"
        >
          <span v-t="{ path: 'noFound', args: [objectsName] }" />
        </v-alert>
      </div>
    </v-list>

    <template #left-actions>
      <v-tooltip location="top">
        <template #activator="{ props: tooltipProps }">
          <v-btn
            rounded
            min-width="36"
            height="36"
            size="x-large"
            density="compact"
            variant="text"
            :disabled="dialogState === 'loading' || page <= 0"
            v-bind="tooltipProps"
            @click="changePage(false)"
          >
            <v-icon icon="mdi-chevron-left" />
          </v-btn>
        </template>
        <span v-text="t('previous')" />
      </v-tooltip>
      <v-tooltip location="top">
        <template #activator="{ props: tooltipProps }">
          <v-btn
            rounded
            min-width="36"
            height="36"
            size="x-large"
            density="compact"
            variant="text"
            :disabled="dialogState === 'loading' || (lastPage !== null && page >= lastPage)"
            v-bind="tooltipProps"
            @click="changePage()"
          >
            <v-icon icon="mdi-chevron-right" />
          </v-btn>
        </template>
        <span v-text="t('next')" />
      </v-tooltip>
      <v-tooltip location="top">
        <template #activator="{ props: tooltipProps }">
          <v-btn
            rounded
            min-width="36"
            height="36"
            size="x-large"
            density="compact"
            variant="text"
            :disabled="dialogState === 'loading'"
            v-bind="tooltipProps"
            @click="refresh"
          >
            <v-icon icon="mdi-refresh" />
          </v-btn>
        </template>
        <span v-text="t('refresh')" />
      </v-tooltip>

      <span
        v-if="showPaginationLabel"
        class="my-auto ml-3 text--secondary"
        v-text="paginationLabel"
      />
    </template>
    <template #right-actions>
      <slot name="right-actions" />
    </template>
    <template #main-action>
      <slot name="main-action" />
    </template>
  </w-dialog>
</template>

<script
  lang="ts"
  setup
  generic="
    C extends Controller,
    M extends DefaultMapper<unknown, ReturnType<C>['rows'][0]> = DefaultMapper<
      ReturnType<C>['rows'][0],
      ReturnType<C>['rows'][0]
    >
  "
>
import { ref, type Ref, computed, nextTick, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useUUID } from '@web-ui-root/composables/uuid';
import WDialog from '@web-ui-root/components/dialogs/w-dialog.vue';
import { useDotReach } from './dot-reach';
import SearchAndSort from './search-and-sort.vue';
import {
  type ServerTableProps,
  useServerTable,
  type Controller,
  type DefaultMapper,
} from './server-table';
import { type ServerTableRow } from './types';

type ViewProps = {
  fullscreen?: boolean;
  maxWidth?: string | number;
  searchable?: boolean;
  title?: string | null;
  modelValue?: boolean;
  showPaginationLabel?: boolean;
  immediateSearch?: boolean;
  contentClasses?: Array<string> | string;
  selectAllLabel?: string;
  showBulkActions?: boolean;
  isAvailableToSelect?: (item: ReturnType<M>) => boolean;
};
type Props = ViewProps &
  Required<Pick<ServerTableProps<C, M>, 'tableController' | 'objectsName'>> &
  Partial<Omit<ServerTableProps<C, M>, 'tableController' | 'objectsName'>>;

const props = withDefaults(defineProps<Props>(), {
  rowKey: '',
  addable: false,
  clickable: false,
  sortByOptions: () => [{ value: 'hashId', title: 'HashId', descending: false }],
  additionalParams: () => ({}),
  filters: () => [],
  query: () => ({}),
  defaultSearch: '',
  disableRouteUpdating: false,
  showFiltersAsDropdown: false,
  flatList: false,
  fullHeight: true,
  headerClass: '',
  footerClass: '',

  fullscreen: undefined,
  maxWidth: '500px',
  searchable: false,
  title: null,
  modelValue: true,
  showPaginationLabel: true,
  immediateSearch: false,
  contentClasses: () => [],
  selectAllLabel: '',
  showBulkActions: false,
  isAvailableToSelect: () => true,
});

const defaultResponseMapper: M = ((v: unknown) => v) as M;

const {
  loading,
  messages,
  rows,
  page,
  lastPage,
  searchInput,
  sortBy,
  descending,
  selectedFilterKeys,
  paginationLabel,
  refresh,
  changePage,
  initialize,
  updateSearchToFilters,
  changeController,
} = useServerTable<C, M>({
  tableController: props.tableController,
  responseMapper: props.responseMapper ?? defaultResponseMapper,
  rowKey: props.rowKey,
  addable: props.addable,
  clickable: props.clickable,
  sortByOptions: props.sortByOptions,
  additionalParams: props.additionalParams,
  objectsName: props.objectsName,
  filters: props.filters,
  query: props.query,
  defaultSearch: props.defaultSearch,
  disableRouteUpdating: props.disableRouteUpdating,
  onPageChanged,
});

watch(
  () => props.tableController,
  (val: Props['tableController']) => {
    changeController(val);
  },
);

const { t } = useI18n({ messages });

const emit = defineEmits<{
  'update:modelValue': [item: unknown];
  new: [];
  'row-click': [item: ServerTableRow];
  'pagination-change': [page: number];
}>();

const exposed: ServerTableDialogExposed<ReturnType<M>> = {
  refresh,
  getRowByHashId,
};

// for some reason if generics are used then exposed methods are not inferred correctly, but they are exposed. just type is missing.
// https://github.com/vuejs/core/issues/10077
defineExpose(exposed);

const { uuid } = useUUID();
uuid.value.toString();
const { dotReach } = useDotReach();

const searchAndSort: Ref<typeof SearchAndSort | null> = ref(null);

const selectedHashIds = defineModel<string[]>('selectedHashIds');

const dialogState = computed(() => (loading.value ? 'loading' : 'pristine'));
const availableToSelectRows = computed(() => rows.value.filter(props.isAvailableToSelect));
const selectedAll = computed(
  () => (selectedHashIds.value ?? []).length === availableToSelectRows.value.length,
);

function onPageChanged(pageNb: number): void {
  emit('pagination-change', pageNb);
  nextTick(updateDimensions);
}

function getRowByHashId(hashId: string): ReturnType<M> | undefined {
  return rows.value.find((r) => r.hashId === hashId);
}

function updateDimensions(): void {
  if (searchAndSort.value !== undefined && searchAndSort.value !== null) {
    // https://github.com/vuejs/core/issues/5540#issuecomment-1541473789
    searchAndSort.value.$.exposed.updateDimensions();
  }
}

function selectAll() {
  selectedHashIds.value = availableToSelectRows.value.map((r) => r.hashId);
}

function unselectAll() {
  selectedHashIds.value = [];
}

function selectItem(hashId: string) {
  if (selectedHashIds.value === undefined) {
    return;
  }

  if (selectedHashIds.value.includes(hashId)) {
    selectedHashIds.value = selectedHashIds.value.filter((h) => h !== hashId);
  } else if (availableToSelectRows.value.some((r) => r.hashId === hashId)) {
    selectedHashIds.value = [...selectedHashIds.value, hashId];
  }
}
</script>

<script lang="ts">
export type ServerTableDialogExposed<T extends ServerTableRow = ServerTableRow> = {
  refresh(): void;
  getRowByHashId(hashId: string): T | undefined;
};
</script>
