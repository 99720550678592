<template>
  <v-text-field
    v-model="input"
    clearable
    single-line
    :placeholder="placeholder"
    prepend-inner-icon="mdi-magnify"
    variant="underlined"
    bg-color="transparent"
    color="primary"
    :rules="[isSearchNotTooLong]"
    @click:clear="change({ search: '' })"
    @update:model-value="emitInput"
    @keydown.enter="change({ search: input })"
  >
    <template
      v-if="filters.length > 0"
      #prepend
    >
      <v-menu
        ref="filterMenu"
        :close-on-content-click="false"
        :offset="[0, 12]"
      >
        <template #activator="{ props: menuProps }">
          <v-tooltip location="bottom">
            <template #activator="{ props: tooltipProps }">
              <v-icon
                :color="hasFilters ? 'info' : undefined"
                v-bind="{
                  ...tooltipProps,
                  ...menuProps,
                }"
              >
                mdi-filter
              </v-icon>
            </template>
            {{ t('filterMenu') }}
          </v-tooltip>
        </template>
        <v-list density="compact">
          <w-filter
            v-for="f in filters"
            ref="filter"
            v-bind="f"
            :key="f.key"
            :model-value="getFilterValue(f)"
            :in-menu="true"
            @update:model-value="(val) => handleFilterUpdate(f, val)"
          />
        </v-list>
      </v-menu>
    </template>

    <template
      v-if="canToggleOrder || sortByOptions.length > 1"
      #append
    >
      <v-menu
        v-if="sortByOptions.length > 1"
        :offset="[0, 8]"
      >
        <template #activator="{ props: menuProps }">
          <v-tooltip location="bottom">
            <template #activator="{ props: tooltipProps }">
              <v-icon v-bind="{ ...tooltipProps, ...menuProps }"> mdi-sort </v-icon>
            </template>
            {{ t(`sort.${sortByOption === undefined ? 'undefined' : 'defined'}`, [sortByName]) }}
          </v-tooltip>
        </template>
        <v-list>
          <v-list-item
            v-for="item in sortByOptions"
            :key="item.value"
            :active="sortBy === item.value"
            @click="change({ sortBy: item.value, descending: item.descending })"
          >
            <v-list-item-title>
              {{ item.title }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-tooltip
        v-if="canToggleOrder"
        location="bottom"
      >
        <template #activator="{ props: tooltipProps }">
          <v-icon
            v-bind="tooltipProps"
            @click="change({ descending: !descending })"
          >
            {{ descending ? 'mdi-arrow-up' : 'mdi-arrow-down' }}
          </v-icon>
        </template>
        {{ t(`order.${descending ? 'desc' : 'asc'}`) }}
      </v-tooltip>
      <slot name="append-outer" />
    </template>
    <slot name="append" />
  </v-text-field>
</template>

<script setup lang="ts">
import { computed, ref, type Ref, nextTick, onBeforeUnmount } from 'vue';
import { useI18n } from 'vue-i18n';
import Throttle from '@web-ui-root/helpers/throttle';
import { stringNotTooLong } from '@web-ui-root/helpers/rules';
import {
  type Filter,
  type SortByOption,
  type InputEvent,
  type SearchChanges,
  isDateInputEvent,
  isAutocompleteInputEvent,
} from './types';
import WFilter from './server-table-filter.vue';

const MAX_SEARCH_LENGTH = 255;
const isSearchNotTooLong = stringNotTooLong(MAX_SEARCH_LENGTH);

type Props = {
  modelValue?: string;
  placeholder?: string;
  sortByOptions?: Array<SortByOption>;
  sortBy?: string | null;
  descending?: boolean;
  filters?: Array<Filter>;
  filterValues?: Record<string, Array<string>>;
  immediateSearch?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  modelValue: undefined,
  placeholder: '',
  sortByOptions: () => [],
  sortBy: null,
  descending: false,
  filters: () => [],
  filterValues: () => ({}),
  immediateSearch: false,
});

const emit = defineEmits<{
  'update:modelValue': [input: string | undefined];
  change: [changes: Required<SearchChanges>];
  'update:filters': [key: string, value: Array<string>];
}>();

const { t } = useI18n({
  messages: {
    en: {
      filterMenu: 'Filter results',
      sort: {
        defined: 'Sort by: {0}',
        undefined: 'Sort by',
      },
      order: {
        asc: 'Sort ascending (click for descending)',
        desc: 'Sort descending (click for ascending)',
      },
    },
    nl: {
      filterMenu: 'Filter resultaten',
      sort: {
        defined: 'Sorteert op: {0}',
        undefined: 'Sorteer op',
      },
      order: {
        asc: 'Sorteer oplopend (klik voor aflopend)',
        desc: 'Sorteer aflopend (klik voor oplopend)',
      },
    },
  },
});

const throttle = new Throttle(1000);

const input = computed<string | undefined>({
  get(): string | undefined {
    return props.modelValue;
  },
  set(val: string | undefined): void {
    emit('update:modelValue', val);
  },
});

const sortByOption = computed(() => props.sortByOptions.find((sBO) => sBO.value === props.sortBy));

const sortByName = computed(() =>
  sortByOption.value === undefined ? '' : sortByOption.value.title,
);

const canToggleOrder = computed(() =>
  sortByOption.value === undefined ? false : sortByOption.value.toggleable !== false,
);

const hasFilters = computed(() =>
  props.filters.some(
    (f) => Array.isArray(props.filterValues[f.key]) && props.filterValues[f.key].length > 0,
  ),
);

onBeforeUnmount(() => {
  throttle.stop();
});

type UpdateDimensions = {
  updateDimensions: () => void;
};

const filterMenu: Ref<UpdateDimensions | null> = ref(null);
const filter: Ref<Array<UpdateDimensions> | null> = ref(null);

defineExpose({
  updateDimensions,
});

function updateDimensions() {
  filterMenu.value?.updateDimensions();

  nextTick(() => {
    filter.value?.forEach((f) => f.updateDimensions());
  });
}

function change(changes: SearchChanges): void {
  if (changes.search !== undefined && isSearchNotTooLong(changes.search) !== true) {
    // skip search
    return;
  }

  const data: Required<SearchChanges> = {
    search: input.value || '',
    sortBy: props.sortBy ?? 'hashId',
    descending: props.descending,
  };
  if (typeof changes.search === 'string') {
    data.search = changes.search;
  } else if (changes.search !== undefined) {
    data.search = '';
  }

  if (changes.sortBy !== undefined) {
    data.sortBy = changes.sortBy;
  }
  if (changes.descending !== undefined) {
    data.descending = changes.descending;
  }

  emit('change', data);
}

function handleFilterUpdate(fil: Filter, value: InputEvent): void {
  if (fil.isDate && isDateInputEvent(value)) {
    if (fil.keyStartDate !== undefined) {
      emit('update:filters', fil.keyStartDate, value[0]);
    }

    if (fil.keyEndDate !== undefined) {
      emit('update:filters', fil.keyEndDate, value[1]);
    }
  } else if (isAutocompleteInputEvent(value)) {
    emit('update:filters', fil.key, value);
  }
}

function getFilterValue(filterToUpdate: Filter): Array<string> | [Array<string>, Array<string>] {
  if (
    filterToUpdate.isDate &&
    filterToUpdate.keyStartDate !== undefined &&
    filterToUpdate.keyEndDate !== undefined
  ) {
    return [
      props.filterValues[filterToUpdate.keyStartDate],
      props.filterValues[filterToUpdate.keyEndDate],
    ];
  }

  return props.filterValues[filterToUpdate.key];
}

function emitInput(value: string) {
  throttle.run(() => {
    if (props.immediateSearch) {
      change({ search: value });
    }
  });
}
</script>

<style scoped>
.mdi-filter {
  opacity: 0.6;
}
</style>
