import { type Ref, ref } from 'vue';
import { type RouteLocationNormalized } from 'vue-router';
import { models, errors as sdkErrors, routes } from '@withthegrid/amp-sdk';
import {
  clearUser,
  getLastEnvironmentHashId,
  setLastEnvironmentHashId,
} from '@web-ui-root/helpers/local-storage/local-storage-user';
import { useSDK } from '@web-ui-root/composables/sdk';
import {
  removeLocalStorageItem,
  setLocalStorageItem,
} from '@web-ui-root/helpers/local-storage/local-storage';
import Invariant from '@web-ui-root/helpers/invariant';
import { useEnvironment, mapLayersStorageKey } from './environment';
import { useIotEnvironment } from './iot-environment';
import { useRollbar } from './rollbar';
import { useBusHandler } from './bus-handler';

type EnvironmentType = 'supplier' | 'environment';
type UserEnvironmentSettings = models.userEnvironmentSettings.UserEnvironmentSettings;
type Environment = models.environment.Environment;
type Supplier = models.supplier.Supplier;
type UpdateRequestBody = routes.settings.update.Request['body'];
type Locale = models.locale.Locale;

type UserState = {
  isLoggingIn: Ref<boolean>;
  isLoggedIn: Ref<boolean>;
  hashId: Ref<string | null>;
  name: Ref<string | null>;
  email: Ref<string | null>;
  timezone: Ref<string>;
  locale: Ref<Locale>;
  phone: Ref<string | null>;
  company: Ref<string | null>;
  rights: Ref<Array<string>>;
  environmentType: Ref<EnvironmentType | null>;
  twoFactorAuthenticationEnabled: Ref<boolean>;
  lastFeatureUpdateSeen: Ref<number>;
  federatedAuthentication: Ref<boolean>;
};

export type StateEnvironment<T extends EnvironmentType> = {
  type: T;
  environment: T extends 'supplier' ? Supplier : Environment;
  environmentRights: Array<string>;
  userEnvironmentSettings: UserEnvironmentSettings;
};

export type UserModel = {
  twoFactorAuthenticationEnabled: UserState['twoFactorAuthenticationEnabled'];
  name: UserState['name'];
  locale: UserState['locale'];
  hashId: UserState['hashId'];
  timezone: UserState['timezone'];
  isLoggedIn: UserState['isLoggedIn'];
  isLoggingIn: UserState['isLoggingIn'];
  environmentType: UserState['environmentType'];
  email: UserState['email'];
  lastFeatureUpdateSeen: UserState['lastFeatureUpdateSeen'];
  federatedAuthentication: UserState['federatedAuthentication'];

  /**
   * @deprecated Use the globally defined `hasRight` function instead.
   */
  hasRight: (right: string) => boolean;

  login: (data: {
    email: string;
    password: string;
    setEnvironment: boolean;
    code?: string;
  }) => Promise<void>;
  checkAuthentication: (data: {
    type: routes.authentication.checkAuthentication.Request['body']['type'];
  }) => Promise<boolean | undefined>;
  authChallenge: (data: {
    type: 'full' | 'simple';
    password?: string;
    code?: string;
  }) => Promise<boolean | undefined>;
  validateEmail: (data: { userHashId: string; userToken: string }) => Promise<void>;
  resumeSession: (route: RouteLocationNormalized) => Promise<void>;
  exchangeJwt: (data: { jwt: string; ssoClientId: string }) => Promise<void>;
  loadEnvironment: (data: { type: EnvironmentType; hashId: string }) => Promise<void>;
  setEnvironment: <T extends EnvironmentType>(data: StateEnvironment<T>) => Promise<void>;
  clearEnvironment: () => void;
  updateSettings: () => Promise<void>;
  logOut: () => Promise<void>;
  clearEmail: () => void;
  update: (data: UpdateRequestBody) => Promise<void>;
  deleteAccount: () => Promise<void>;
  enableTwoFactorAuthentication: (code: string) => Promise<void>;
  disableTwoFactorAuthentication: () => Promise<void>;
  markUpdateAsSeen: (id: number) => Promise<void>;
};

const intlResolvedOptions = new Intl.DateTimeFormat().resolvedOptions();

const browserLocale: Locale = ['en', 'nl'].includes(window.navigator.language.substring(0, 2))
  ? (window.navigator.language.substring(0, 2) as Locale)
  : 'en';

const emailLocalStorageKey = 'email';

const {
  hashId: iotEnvironmentHashId,
  load: loadIotEnvironment,
  clear: clearIotEnvironment,
  set: setIotEnvironment,
} = useIotEnvironment();

const {
  hashId: environmentHashId,
  load: loadMonitoringEnvironment,
  clear: clearMonitoringEnvironment,
  set: setMonitoringEnvironment,
} = useEnvironment();

const { rollbar, info } = useRollbar();

export const state: UserState = {
  isLoggingIn: ref(true),
  isLoggedIn: ref(false),
  hashId: ref(null),
  name: ref(null),
  email: ref(''),
  timezone: ref(intlResolvedOptions.timeZone),
  locale: ref(browserLocale),
  phone: ref(''),
  company: ref(''),
  rights: ref([]),
  environmentType: ref(null),
  twoFactorAuthenticationEnabled: ref(false),
  lastFeatureUpdateSeen: ref(0),
  federatedAuthentication: ref(false),
};

export function useUser(): UserModel {
  const { emit } = useBusHandler();
  const { sdk } = useSDK();

  const hasRight = (right: string): boolean => {
    if (state.rights.value.includes('ADMIN')) {
      return true;
    }

    return state.rights.value.includes(right);
  };

  const loggingIn = () => {
    info('Calling useUser.loggingIn() clearing user.state');
    state.isLoggingIn.value = true;
    state.isLoggedIn.value = false;
    state.hashId.value = null;
    state.name.value = null;
    state.email.value = '';
    state.timezone.value = intlResolvedOptions.timeZone;
    state.locale.value = browserLocale;
    state.phone.value = '';
    state.company.value = '';
    state.rights.value = [];
    state.twoFactorAuthenticationEnabled.value = false;
    state.lastFeatureUpdateSeen.value = 0;
    state.federatedAuthentication.value = false;
  };

  const loggedIn = (user: routes.authentication.humanLogin.Response['user']): void => {
    info('Calling useUser.loggedIn() filling user.state');
    state.isLoggingIn.value = false;
    state.isLoggedIn.value = true;
    state.hashId.value = user.hashId;
    state.name.value = user.name;
    state.email.value = user.email;
    state.timezone.value = user.timezone;
    state.locale.value = user.locale;
    state.phone.value = user.phone;
    state.company.value = user.company;
    state.rights.value = user.rights;
    state.twoFactorAuthenticationEnabled.value = user.twoFactorAuthenticationEnabled ?? false;
    state.lastFeatureUpdateSeen.value = user.lastFeatureUpdateSeen;
    state.federatedAuthentication.value = user.federatedAuthentication;
  };

  const loggedOut = (): void => {
    info('Calling useUser.loggedOut() clearing user.state');
    state.isLoggingIn.value = false;
    state.isLoggedIn.value = false;
    state.hashId.value = null;
    state.name.value = null;
    // do not mutate state.email
    state.timezone.value = intlResolvedOptions.timeZone;
    state.locale.value = browserLocale;
    state.phone.value = '';
    state.company.value = '';
    state.rights.value = [];
    state.environmentType.value = null;
    state.twoFactorAuthenticationEnabled.value = false;
    state.lastFeatureUpdateSeen.value = 0;
    state.federatedAuthentication.value = false;
  };

  const clearEnvironment = (): void => {
    state.environmentType.value = null;
    sdk.changeEnvironment();
    clearMonitoringEnvironment();
    clearIotEnvironment();
  };

  const setEnvironment = async (
    env: StateEnvironment<'supplier' | 'environment'>,
  ): Promise<void> => {
    const { environment, type, environmentRights, userEnvironmentSettings } = env;
    const clear = type === 'supplier' ? clearMonitoringEnvironment : clearIotEnvironment;
    const hashId = type === 'supplier' ? iotEnvironmentHashId.value : environmentHashId.value;

    if (state.environmentType.value !== type || hashId !== environment.hashId) {
      clearEnvironment();
      state.environmentType.value = type;
      sdk.changeEnvironment(environment.hashId, type);
    }

    if (type === 'supplier') {
      setIotEnvironment({
        environment,
        environmentRights,
      });
    } else {
      await setMonitoringEnvironment({
        environment: environment as Environment,
        environmentRights,
        userEnvironmentSettings,
      });
    }

    clear();
  };

  const logOut = async (): Promise<void> => {
    try {
      await sdk.logout();
    } catch (e) {
      if (!(e instanceof sdkErrors.Authentication)) {
        emit('commsError', e);
      }
    } finally {
      clearUser();
      removeLocalStorageItem(mapLayersStorageKey);
      if (rollbar !== undefined) {
        rollbar.configure({ payload: { person: undefined } });
        info('Using useUser.logOut()');
      }
      loggedOut();
      setLastEnvironmentHashId(null);
      clearEnvironment();
    }
  };

  const login = async ({
    email,
    password,
    code,
    setEnvironment: shouldSetEnvironment,
  }: {
    email: string;
    password: string;
    setEnvironment: boolean;
    code?: string;
  }): Promise<void> => {
    setLocalStorageItem(emailLocalStorageKey, email);
    loggingIn();

    let userData: Awaited<ReturnType<typeof sdk.humanLogin>>;
    try {
      userData = await sdk.humanLogin(email, password, code);
    } catch (e) {
      await logOut();
      throw e;
    }
    const { user, environment, environmentRights, userEnvironmentSettings } = userData;

    if (rollbar !== undefined) {
      rollbar.configure({
        payload: {
          person: {
            id: user.hashId,
            email: user.email ?? '',
          },
        },
      });
    }

    loggedIn(user);

    if (
      shouldSetEnvironment &&
      environment !== undefined &&
      userEnvironmentSettings !== undefined
    ) {
      await setEnvironment({
        type: 'environment',
        environment,
        environmentRights: environmentRights ?? [],
        userEnvironmentSettings,
      });
    }
  };

  const checkAuthentication = async ({
    type,
  }: {
    type: routes.authentication.checkAuthentication.Request['body']['type'];
  }): Promise<boolean | undefined> => {
    if (state.isLoggedIn.value && state.email.value !== null) {
      const result = sdk.routes.authentication.checkAuthentication({
        body: { type },
      });
      const response = await result.response;

      return response.valid;
    }
    return false;
  };

  const authChallenge = async ({
    type,
    password,
    code,
  }: {
    type: 'full' | 'simple';
    password?: string;
    code?: string;
  }): Promise<boolean | undefined> => {
    if (state.isLoggedIn.value && state.email.value !== null) {
      let response;
      try {
        const result = sdk.routes.authentication.authChallenge({
          body: { type, password, code },
        });
        response = await result.response;
      } catch (e) {
        if (
          e instanceof sdkErrors.Authentication &&
          (e.key === 'session_expired' || e.key === 'user_expired')
        ) {
          await logOut();
          throw e;
        }
        throw e;
      }

      return response?.valid ?? false;
    }
    return false;
  };

  const validateEmail = async ({
    userHashId,
    userToken,
  }: {
    userHashId: string;
    userToken: string;
  }) => {
    loggingIn();

    const { response } = sdk.routes.authentication.validateEmail({
      body: {
        userHashId,
        userToken,
      },
    });

    const { user, environment, environmentRights, userEnvironmentSettings } = await response.catch(
      (e: Error) => {
        loggedOut();
        throw e;
      },
    );

    setLocalStorageItem(emailLocalStorageKey, user.email);

    if (rollbar !== undefined) {
      rollbar.configure({
        payload: {
          person: {
            id: user.hashId,
            email: user.email ?? '',
          },
        },
      });
    }

    if (environment !== undefined && userEnvironmentSettings !== undefined) {
      await setEnvironment({
        type: 'environment',
        environment,
        environmentRights: environmentRights ?? [],
        userEnvironmentSettings,
      });
    }
  };

  const updateSettings = async (): Promise<void> => {
    let response: Awaited<ReturnType<typeof sdk.routes.settings.get>['response']>;
    try {
      const result = sdk.routes.settings.get({});
      response = await result.response;
    } catch (e) {
      if (e instanceof sdkErrors.Authentication) {
        await logOut();
      } else {
        emit('commsError', e, undefined, 0);
      }
      return;
    }

    if (rollbar !== undefined) {
      rollbar.configure({
        payload: {
          person: {
            id: response.user.hashId ?? '',
            email: response.user.email ?? '',
          },
        },
      });
    }
    loggedIn(response.user);

    // 'environment' | null
    if (state.environmentType.value !== 'supplier') {
      /**
       * This function can be triggered while the user is working in an iot
       * environment. The Environment-Hash-Id header in the sdk then is
       * different from the hashId of this environment, which could cause
       * settings.get() to return another environment than this one. In that
       * case, ignore the returned environment.
       */

      if (response.environment !== undefined && response.userEnvironmentSettings !== undefined) {
        await setEnvironment({
          type: 'environment',
          environment: response.environment,
          environmentRights: response.environmentRights ?? [],
          userEnvironmentSettings: response.userEnvironmentSettings,
        });
      } else {
        clearEnvironment();
      }
    }
  };

  const resumeSession = async (route: RouteLocationNormalized) => {
    const lastEnvironmentHashId = getLastEnvironmentHashId();

    loggingIn();

    if (route.matched.some((m) => m.meta.environment === 'asset')) {
      sdk.resumeCookieSession(route.params.environmentHashId.toString(), 'environment');
      state.environmentType.value = 'environment';
    } else if (route.matched.some((m) => m.meta.environment === 'deviceSupplier')) {
      sdk.resumeCookieSession(route.params.environmentHashId.toString(), 'supplier');
      state.environmentType.value = 'supplier';
    } else if (lastEnvironmentHashId !== null && lastEnvironmentHashId !== undefined) {
      sdk.resumeCookieSession(lastEnvironmentHashId, 'environment');
      state.environmentType.value = 'environment';
    } else {
      sdk.resumeCookieSession();
      state.environmentType.value = null;
    }

    await updateSettings();

    if (!state.isLoggedIn.value) {
      clearEnvironment();
      await updateSettings();
    }
  };

  const exchangeJwt = async ({
    jwt,
    ssoClientId,
  }: {
    jwt: string;
    ssoClientId: string;
  }): Promise<void> => {
    loggingIn();

    let userData: Awaited<
      ReturnType<typeof sdk.routes.authentication.exchangeJwtFromSso>['response']
    >;
    try {
      const result = sdk.routes.authentication.exchangeJwtFromSso({
        params: { ssoClientId },
        body: { jwt },
      });
      userData = await result.response;
      sdk.routes.comms.login('human', undefined, userData.environment?.hashId);
    } catch (e) {
      await logOut();
      throw e;
    }

    const { user, environment, environmentRights, userEnvironmentSettings } = userData;

    if (rollbar !== undefined) {
      rollbar.configure({
        payload: {
          person: {
            id: user.hashId ?? '',
            email: user.email ?? '',
          },
        },
      });
    }

    loggedIn(user);

    if (environment !== undefined && userEnvironmentSettings !== undefined) {
      await setEnvironment({
        type: 'environment',
        environment,
        environmentRights: environmentRights ?? [],
        userEnvironmentSettings,
      });
    }
  };

  const loadEnvironment = async ({
    type,
    hashId,
  }: {
    type: EnvironmentType;
    hashId: string;
  }): Promise<void> => {
    const load = type === 'supplier' ? loadIotEnvironment : loadMonitoringEnvironment;
    const clear = type === 'supplier' ? clearMonitoringEnvironment : clearIotEnvironment;

    sdk.changeEnvironment(hashId, type);
    state.environmentType.value = type;
    try {
      await load(hashId, state.locale.value);
    } catch (e) {
      clearEnvironment(); // from this composable
      throw e;
    }

    clear();
  };

  const clearEmail = (): void => {
    removeLocalStorageItem(emailLocalStorageKey);
    state.email.value = '';
  };

  const update = async (data: UpdateRequestBody): Promise<void> => {
    const { response } = sdk.routes.settings.update({ body: data });

    try {
      await response;
    } catch (e) {
      emit('commsError', e);
      throw e;
    }

    state.name.value = data.name ?? state.name.value;
    state.locale.value = data.locale ?? state.locale.value;
    state.timezone.value = data.timezone ?? state.timezone.value;
    state.phone.value = data.phone ?? state.phone.value;
    state.company.value = data.company ?? state.company.value;
  };

  const deleteAccount = async (): Promise<void> => {
    if (state.hashId.value === null) {
      throw new Error('Must have hashId to delete self');
    }

    const { response } = sdk.routes.user.deleteAccount({ params: { hashId: state.hashId.value } });

    try {
      await response;
    } catch (e) {
      emit('commsError', e);
      throw e;
    }
  };

  const enableTwoFactorAuthentication = async (code: string): Promise<void> => {
    const { response } = sdk.routes.twoFactorAuthentication.enable({ body: { code } });

    try {
      await response;
    } catch (e) {
      emit('commsError', e);
      throw e;
    }

    state.twoFactorAuthenticationEnabled.value = true;
    sdk.resumeCookieSession(sdk.environment?.hashId, sdk.environment?.type);
  };

  const disableTwoFactorAuthentication = async (): Promise<void> => {
    const { response } = sdk.routes.twoFactorAuthentication.disable({});

    try {
      await response;
    } catch (e) {
      emit('commsError', e);
      throw e;
    }

    state.twoFactorAuthenticationEnabled.value = false;
  };

  async function markUpdateAsSeen(number: number): Promise<void> {
    const userHashId = state.hashId.value;
    Invariant.assertPresent(userHashId, 'userHashId not present');

    state.lastFeatureUpdateSeen.value = number;
    try {
      await sdk.routes.lastFeatureUpdate.markAsSeen({ body: { number } }).response;
    } catch (e) {
      emit('commsError', e);
      throw e;
    }
  }

  return {
    twoFactorAuthenticationEnabled: state.twoFactorAuthenticationEnabled,
    name: state.name,
    locale: state.locale,
    hashId: state.hashId,
    timezone: state.timezone,
    isLoggedIn: state.isLoggedIn,
    isLoggingIn: state.isLoggingIn,
    environmentType: state.environmentType,
    email: state.email,
    lastFeatureUpdateSeen: state.lastFeatureUpdateSeen,
    federatedAuthentication: state.federatedAuthentication,

    hasRight,
    login,
    checkAuthentication,
    authChallenge,
    validateEmail,
    resumeSession,
    exchangeJwt,
    clearEnvironment,
    loadEnvironment,
    setEnvironment,
    updateSettings,
    logOut,
    clearEmail,
    update,
    deleteAccount,
    enableTwoFactorAuthentication,
    disableTwoFactorAuthentication,
    markUpdateAsSeen,
  };
}
