import { computed } from 'vue';
import type { useUser } from '@web-ui-root/composables/user';
import type { ComputedRef, UnwrapRef } from 'vue';

import { updates, type Update } from './updates';

type Props = {
  lastSeen: ReturnType<typeof useUser>['lastFeatureUpdateSeen'];
  locale: UnwrapRef<ReturnType<typeof useUser>['locale']>;
  markAsSeen: ReturnType<typeof useUser>['markUpdateAsSeen'];
};

type LastSeenUpdateModel = {
  hasAnUpdate: ComputedRef<boolean>;
  latestUpdate: ComputedRef<Update>;
  markAsSeen: () => Promise<void>;
};

export function useLastFeatureUpdate(props: Props): LastSeenUpdateModel {
  const lastUpdate = 3;

  const latestUpdate = computed(() => {
    return updates[props.locale][lastUpdate] ?? null;
  });

  const hasAnUpdate = computed(() => {
    return lastUpdate > props.lastSeen.value && latestUpdate.value !== null;
  });

  async function markAsSeen(): Promise<void> {
    if (!hasAnUpdate.value) {
      return;
    }

    await props.markAsSeen(lastUpdate);
  }

  return {
    hasAnUpdate,
    latestUpdate,
    markAsSeen,
  };
}
