import {
  type LocationQueryRaw,
  type LocationQueryValue,
  type NavigationGuardNext,
  type RouteLocation,
  type RouteLocationNormalized,
  type RouteLocationRaw,
  type RouteRecordRaw,
} from 'vue-router';

import { lazyRouterViewLoader } from '@web-ui-root/helpers/lazy-load-view';
import EmptyRouterView from '@web-ui-root/components/empty-router-view.vue';

import { type InitialModes, isMode } from '../views/monitoring-environment/map-view/map/map';
import EnvironmentFramework from '../views/monitoring-environment/monitoring-framework.vue';
import { useEnvironment } from '../composables/environment';
import { useBusHandler } from '../composables/bus-handler';
import { useUser } from '../composables/user';

const EnvironmentMapFramework = lazyRouterViewLoader(
  () => import('@web-ui-root/views/monitoring-environment/map-view/map-framework.vue'),
);
const EnvironmentIssueSubscription = lazyRouterViewLoader(
  () =>
    import(
      '@web-ui-root/views/monitoring-environment/map-view/right-panel/issue-subscription/issue-subscription-dialog.vue'
    ),
);

const Pins = lazyRouterViewLoader(
  () => import('@web-ui-root/views/monitoring-environment/map-view/pins/pins-dialog.vue'),
  true,
);
const Pin = lazyRouterViewLoader(
  () => import('@web-ui-root/views/monitoring-environment/map-view/pins/pin/pin-loading.vue'),
  true,
);
const Devices = lazyRouterViewLoader(
  () => import('@web-ui-root/views/monitoring-environment/devices/device-list.vue'),
);
const DeviceDialog = lazyRouterViewLoader(
  () => import('@web-ui-root/views/monitoring-environment/device-dialog.vue'),
);
const Exports = lazyRouterViewLoader(
  () => import('@web-ui-root/views/monitoring-environment/exports/export-list.vue'),
);
const Imports = lazyRouterViewLoader(
  () => import('@web-ui-root/views/monitoring-environment/imports/import-list.vue'),
);

const EnvironmentSettingsFramework = lazyRouterViewLoader(
  () => import('@web-ui-root/views/monitoring-environment/settings/settings-framework.vue'),
);
const EnvironmentGeneral = lazyRouterViewLoader(
  () => import('@web-ui-root/views/monitoring-environment/settings/general-settings.vue'),
);
const EnvironmentMapLayers = lazyRouterViewLoader(
  () =>
    import('@web-ui-root/views/monitoring-environment/settings/map-layers/map-layers-settings.vue'),
);
const EnvironmentFieldConfigurations = lazyRouterViewLoader(
  () => import('@web-ui-root/views/monitoring-environment/settings/field-configurations.vue'),
);
const Users = lazyRouterViewLoader(
  () => import('@web-ui-root/views/monitoring-environment/settings/users/users-settings.vue'),
);
const UserDialog = lazyRouterViewLoader(
  () => import('@web-ui-root/views/monitoring-environment/settings/users/user.vue'),
);
const EnvironmentLabels = lazyRouterViewLoader(
  () => import('@web-ui-root/views/monitoring-environment/settings/labels/labels-settings.vue'),
);
const EnvironmentLabelDialog = lazyRouterViewLoader(
  () => import('@web-ui-root/views/monitoring-environment/label/label-dialog.vue'),
);
const EnvironmentQuantities = lazyRouterViewLoader(
  () =>
    import('@web-ui-root/views/monitoring-environment/settings/quantities/quantities-settings.vue'),
);
const EnvironmentIssueTriggers = lazyRouterViewLoader(
  () =>
    import(
      '@web-ui-root/views/monitoring-environment/settings/issue-triggers/issue-triggers-settings.vue'
    ),
);
const EnvironmentIssueTriggersConnectivity = lazyRouterViewLoader(
  () =>
    import(
      '@web-ui-root/views/monitoring-environment/settings/issue-triggers/connectivity-issue-triggers.vue'
    ),
);
const EnvironmentIssueTriggersMeasurement = lazyRouterViewLoader(
  () =>
    import(
      '@web-ui-root/views/monitoring-environment/settings/issue-triggers/measurement-issue-triggers.vue'
    ),
);
const EnvironmentReportTypes = lazyRouterViewLoader(
  () =>
    import(
      '@web-ui-root/views/monitoring-environment/settings/report-types/report-types-settings.vue'
    ),
);
const EnvironmentReportType = lazyRouterViewLoader(
  () => import('@web-ui-root/views/monitoring-environment/settings/report-types/report-type.vue'),
);
const AuditLog = lazyRouterViewLoader(
  () => import('@web-ui-root/views/monitoring-environment/settings/audit-log.vue'),
);

const PinGroups = lazyRouterViewLoader(
  () => import('@web-ui-root/views/monitoring-environment/pin-groups/pin-group-list.vue'),
);
const Groups = lazyRouterViewLoader(
  () => import('@web-ui-root/views/monitoring-environment/groups/group-list.vue'),
);
const Edges = lazyRouterViewLoader(
  () => import('@web-ui-root/views/monitoring-environment/edges/edge-list.vue'),
);
const Graphs = lazyRouterViewLoader(
  () => import('@web-ui-root/views/monitoring-environment/graphs/graph-panel.vue'),
);

const PinsSearch = lazyRouterViewLoader(
  () => import('@web-ui-root/views/monitoring-environment/pins/pin-list.vue'),
);

const ConditionOverviews = lazyRouterViewLoader(
  () =>
    import(
      '@web-ui-root/views/monitoring-environment/condition-overview/condition-overview-table.vue'
    ),
);
const ConditionOverviewConfiguration = lazyRouterViewLoader(
  () =>
    import(
      '@web-ui-root/views/monitoring-environment/condition-overview/condition-overview-configuration.vue'
    ),
);
const ConditionOverview = lazyRouterViewLoader(
  () =>
    import('@web-ui-root/views/monitoring-environment/condition-overview/condition-overview.vue'),
);

const Analytics = lazyRouterViewLoader(
  () => import('@web-ui-root/views/monitoring-environment/analytics/analytics-panel.vue'),
);

const rightPanelTypeKeys = ['issues', 'reports', 'commands'] as const;
type RightPanelType = (typeof rightPanelTypeKeys)[number];

function isRightPanelType(value: string): value is RightPanelType {
  return Array.from<string>(rightPanelTypeKeys).includes(value);
}

export type RightPanelProps = {
  type: RightPanelType | null;
  hashId: LocationQueryValue;
  subId?: LocationQueryValue;
  query: {
    search?: LocationQueryValue;
    sortBy?: LocationQueryValue;
    descending?: LocationQueryValue;
    reportTypeHashId?: LocationQueryValue;
  };
};

type VectorMapModeProp = {
  initialVectorMapMode: InitialModes;
};

export type MapProps = { rightPanel: RightPanelProps } & VectorMapModeProp;

const mainRoutePropsParser = (route: RouteLocationNormalized): MapProps => {
  let rightPanelQuery;
  if ((route.params.rightPanelId ?? null) === null) {
    rightPanelQuery = {
      search: route.query.search,
      sortBy: route.query.sortBy,
      descending: route.query.descending,
      reportTypeHashId: route.query.reportTypeHashId,
    };
  } else {
    rightPanelQuery = {
      reportTypeHashId: route.query.reportTypeHashId,
    };
  }

  const initialVectorMapMode = (() => {
    if (Array.isArray(route.query.initialVectorMapMode)) {
      return 'viewDetailsLeftPanel';
    }
    if (route.query.initialVectorMapMode === null) {
      return 'viewDetailsLeftPanel';
    }
    if (isMode(route.query.initialVectorMapMode)) {
      return route.query.initialVectorMapMode;
    }
    return 'viewDetailsLeftPanel';
  })();

  const type =
    Array.isArray(route.params.rightPanelType) || !isRightPanelType(route.params.rightPanelType)
      ? null
      : route.params.rightPanelType;

  const hashId =
    typeof route.params.rightPanelId === 'string' && route.params.rightPanelId !== ''
      ? route.params.rightPanelId
      : null;

  const subId =
    typeof route.params.rightPanelSubId === 'string' && route.params.rightPanelSubId !== ''
      ? route.params.rightPanelSubId
      : null;

  const search =
    rightPanelQuery.search !== undefined && !Array.isArray(rightPanelQuery.search)
      ? rightPanelQuery.search
      : null;

  const sortBy =
    rightPanelQuery.sortBy !== undefined && !Array.isArray(rightPanelQuery.sortBy)
      ? rightPanelQuery.sortBy
      : null;

  const descending =
    rightPanelQuery.descending !== undefined && !Array.isArray(rightPanelQuery.descending)
      ? rightPanelQuery.descending
      : null;

  const reportTypeHashId =
    rightPanelQuery.reportTypeHashId !== undefined &&
    !Array.isArray(rightPanelQuery.reportTypeHashId)
      ? rightPanelQuery.reportTypeHashId
      : null;

  return {
    rightPanel: {
      type,
      hashId,
      ...(subId !== null && { subId }),
      query: {
        ...(reportTypeHashId !== null && { reportTypeHashId }),
        ...(search !== null && { search }),
        ...(sortBy !== null && { sortBy }),
        ...(descending !== null && { descending }),
      },
    },
    initialVectorMapMode,
  };
};

function rightPanelCommandsGuard(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
): void {
  const { hasDevices } = useEnvironment();
  const { emit: busEmit } = useBusHandler();

  if (
    !hasDevices.value &&
    (to.params.rightPanelType === 'commands' || to.fullPath.endsWith('/commands'))
  ) {
    busEmit('info', 'No devices found in this environment. Commands are not available.');
    next(from);
    return;
  }
  next();
}

const monitoringEnvironmentRoutes: Array<RouteRecordRaw> = [
  {
    path: '/e/:environmentHashId',
    component: EnvironmentFramework,
    meta: { requiredRight: 'READ', environment: 'asset' },
    props: (route) => ({
      noAppBar: route.matched.some((m) => m.meta?.noAppBar === true),
      isMap: route.matched.some((m) => m.meta?.isMap === true),
    }),
    children: [
      {
        path: '',
        component: EnvironmentMapFramework,
        meta: { noAppBar: true, isMap: true },
        props: mainRoutePropsParser,
        children: [
          {
            path: ':leftPanelType(pinGroup|edge|pinGroupGrid|pinGrid)/:leftPanelId',
            component: EmptyRouterView,
            sensitive: true,
            name: 'left-panel',
            children: [
              {
                path: 'pin',
                props: (route) => ({ pinHashId: route.params.leftPanelPinHashId ?? null }),
                component: Pins,
                beforeEnter: function pinsDialogGuard(to: RouteLocation): boolean {
                  return to.params.leftPanelType === 'pinGroup';
                },
                children: [
                  {
                    component: EmptyRouterView,
                    path: ':leftPanelPinHashId',
                  },
                ],
              },
              {
                path: ':rightPanelType(issues|reports|commands)',
                sensitive: true,
                component: EmptyRouterView,
                beforeEnter: rightPanelCommandsGuard,
                children: [
                  {
                    component: EmptyRouterView,
                    path: ':rightPanelId/:rightPanelSubId?',
                  },
                ],
              },
            ],
          },
          {
            path: ':rightPanelType(issues)/issue-subscription',
            component: EnvironmentIssueSubscription,
          },
          {
            path: ':rightPanelType(issues|reports|commands)',
            sensitive: true,
            component: EmptyRouterView,
            beforeEnter: rightPanelCommandsGuard,
            children: [
              {
                component: EmptyRouterView,
                path: ':rightPanelId/:rightPanelSubId?',
              },
            ],
          },
          {
            path: 'pin/:pinHashId',
            component: Pin,
            props: (route) => ({ hashId: route.params.pinHashId }),
          },
        ],
      },
      {
        path: 'environment',
        component: EnvironmentSettingsFramework,
        redirect: function environmentSettingsRedirect(): RouteLocationRaw {
          const { hasRight: userHasRight } = useUser();
          const { hasRight: envHasRight } = useEnvironment();

          const hasRight = (right: string) => userHasRight(right) || envHasRight(right);

          if (hasRight('ENVIRONMENT_ADMIN')) {
            return { name: 'monitoring-settings-general' };
          }
          if (hasRight('USERS')) {
            return { name: 'monitoring-settings-users' };
          }
          return { name: 'auditLog' };
        },
        children: [
          {
            path: 'general',
            name: 'monitoring-settings-general',
            component: EnvironmentGeneral,
            meta: { requiredRight: 'ENVIRONMENT_ADMIN' },
          },
          {
            path: 'map-layers',
            component: EnvironmentMapLayers,
          },
          {
            path: 'field-configurations/:type(pinGroups|edges|pins|grids)',
            component: EnvironmentFieldConfigurations,
            props: (route) => ({ type: route.params.type }),
            meta: { requiredRight: 'ENVIRONMENT_ADMIN' },
          },
          {
            path: 'report-types',
            component: EnvironmentReportTypes,
            props: (route) => ({ query: route.query }),
            meta: { requiredRight: 'ENVIRONMENT_ADMIN' },
          },
          {
            path: 'report-types/:hashId',
            props: (route) => ({ query: route.query, hashId: route.params.hashId }),
            component: EnvironmentReportType,
            meta: { requiredRight: 'ENVIRONMENT_ADMIN' },
          },
          {
            path: 'quantities/:quantityHashId?',
            component: EnvironmentQuantities,
            props: (route) => ({
              query: route.query,
              hashId:
                route.params.quantityHashId !== undefined && route.params.quantityHashId !== ''
                  ? route.params.quantityHashId
                  : null,
            }),
            meta: { requiredRight: 'ENVIRONMENT_ADMIN' },
          },
          {
            path: 'users',
            component: Users,
            name: 'monitoring-settings-users',
            props: (route) => ({ query: route.query, hashId: route.params.userHashId }),
            children: [
              {
                path: ':userHashId',
                component: UserDialog,
              },
            ],
            meta: { requiredRight: 'USERS' },
          },
          {
            path: 'labels',
            name: 'labels',
            component: EnvironmentLabels,
            props: (route) => ({ query: route.query, hashId: route.params.labelHashId }),
            children: [
              {
                name: 'label',
                path: ':labelHashId',
                component: EnvironmentLabelDialog,
              },
            ],
            meta: { requiredRight: 'ENVIRONMENT_ADMIN' },
          },
          {
            path: 'issue-triggers',
            component: EnvironmentIssueTriggers,
            redirect: { name: 'measurement' },
            props: (route) => ({ view: route.name }),
            children: [
              {
                name: 'connectivity',
                path: 'connectivity',
                component: EnvironmentIssueTriggersConnectivity,
              },
              {
                name: 'measurement',
                path: 'measurement',
                component: EnvironmentIssueTriggersMeasurement,
                props: (route) => ({ query: route.query }),
              },
            ],
            meta: { requiredRight: 'THRESHOLDS' },
          },
          {
            path: 'audit-log',
            name: 'auditLog',
            component: AuditLog,
            props: (route) => ({ query: route.query }),
            meta: { requiredRight: 'AUDIT_TRAIL' },
          },
        ],
      },
      {
        path: 'pin-groups',
        name: 'PinGroupsView',
        component: PinGroups,
        props: (route) => ({ query: route.query, search: route.query.search }),
      },
      {
        path: 'groups/:type',
        component: Groups,
        props: (route) => ({ query: route.query, type: route.params.type }),
      },
      {
        path: 'pins',
        component: PinsSearch,
        props: (route) => ({ query: route.query }),
      },
      {
        path: 'condition-overview',
        component: ConditionOverviews,
        props: (route) => ({ query: route.query }),
      },
      {
        path: 'condition-overview/new',
        component: ConditionOverviewConfiguration,
        props: { hashId: null },
      },
      {
        path: 'condition-overview/:hashId/edit',
        component: ConditionOverviewConfiguration,
        props: (route) => ({ hashId: route.params.hashId }),
      },
      {
        path: 'condition-overview/:hashId',
        component: ConditionOverview,
        props: (route) => ({ hashId: route.params.hashId }),
      },
      {
        path: 'edges',
        component: Edges,
        props: (route) => ({ query: route.query }),
      },
      {
        path: ':leftPanelType(pinGroup|edge|pinGroupGrid|pinGrid)/:leftPanelId/graphs/:pinHashId?',
        component: Graphs,
        meta: { isMap: true },
        props: (route) => ({ pinHashId: route.params.pinHashId, query: route.query }),
      },
      {
        path: 'devices',
        component: Devices,
        props: (route) => ({ query: route.query, hashId: route.params.deviceHashId }),
        children: [
          {
            path: ':deviceHashId',
            component: DeviceDialog,
          },
        ],
      },
      {
        path: 'exports',
        component: Exports,
        meta: {
          requiredRight: 'EXPORT',
        },
      },
      {
        path: 'imports',
        name: 'imports',
        component: Imports,
        meta: {
          requiredRight: 'IMPORT',
        },
        props: (
          route: RouteLocationNormalized,
        ): { modelValue: boolean } & (
          | { isNew: true }
          | { hashId: string }
          | { query: LocationQueryRaw }
        ) => {
          if (route.name === 'new-import') {
            return { modelValue: true, isNew: true };
          }
          if (route.params.importHashId !== null) {
            return {
              modelValue: false,
              hashId: Array.isArray(route.params.importHashId)
                ? route.params.importHashId[0]
                : route.params.importHashId,
            };
          }
          return { modelValue: false, query: route.query };
        },
        children: [
          {
            name: 'new-import',
            path: 'new',
            component: Imports,
          },
          {
            name: 'import',
            path: ':importHashId',
            component: Imports,
          },
        ],
      },
      {
        name: 'analytics',
        path: 'analytics/:hashId?',
        component: Analytics,
        props: (route) => ({ hashId: route.params.hashId === '' ? null : route.params.hashId }),
      },
    ],
  },
  // {
  //   path: 'analytics/:hashId?',
  //   component: Analytics,
  //   props: (route) => ({ query: route.query, hashId: route.params.hashId }),
  // },
];

export default monitoringEnvironmentRoutes;
