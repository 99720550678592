<template>
  <v-card ref="card">
    <v-list class="py-0">
      <v-list-subheader class="pr-3 bg-grey-lighten-5 filter-list-subheader">
        <div class="d-flex align-center justify-space-between">
          <span v-t="{ path: 'filterBy', args: [nameSingular] }" />
          <div>
            <v-tooltip location="bottom">
              <template #activator="{ props: tooltipProps }">
                <v-btn
                  size="small"
                  class="mr-2"
                  variant="text"
                  v-bind="tooltipProps"
                  density="compact"
                  icon="mdi-delete"
                  @click="clear"
                />
              </template>
              <span v-t="'clear'" />
            </v-tooltip>
            <v-tooltip location="bottom">
              <template #activator="{ props: tooltipProps }">
                <v-btn
                  size="small"
                  variant="text"
                  v-bind="tooltipProps"
                  density="compact"
                  icon="mdi-close"
                  @click="emit('close')"
                />
              </template>
              <span v-t="'close'" />
            </v-tooltip>
          </div>
        </div>
      </v-list-subheader>
      <v-divider />
      <v-card-text>
        <v-form v-model="isValid">
          <date-time-range-selector
            v-model:start-date-model-value="startDate"
            v-model:end-date-model-value="endDate"
            :start-date-label="t('startDateLabel')"
            :end-date-label="t('endDateLabel')"
            :start-min="minDate"
            :start-max="maxDate"
            :end-min="minDate"
            :end-max="maxDate"
            :allow-empty="true"
          />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          v-t="'applyFilter'"
          color="primary"
          class="mr-1"
          variant="elevated"
          :disabled="!isValid"
          @click="applyFilter"
        />
      </v-card-actions>
    </v-list>
  </v-card>
</template>

<script setup lang="ts">
import { ref, onMounted, watch, type Ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useUUID } from '@web-ui-root/composables/uuid';
import dateTimeRangeSelector from '@web-ui-root/components/inputs/range-selector/date-time-range-selector.vue';

const { t } = useI18n({
  messages: {
    en: {
      filterBy: 'Filter by {0}',
      close: 'Close this dialog',
      clear: 'Clear the filter',
      startDateLabel: 'On or after',
      endDateLabel: 'On or before',
      applyFilter: 'Apply filter',
    },
    nl: {
      filterBy: 'Filter op {0}',
      close: 'Sluit dit dialoogvenster',
      clear: 'Wis het filter',
      startDateLabel: 'Op of na',
      endDateLabel: 'Op of voor',
      applyFilter: 'Voeg filter toe',
    },
  },
});
type Props = {
  nameSingular: string;
  modelValue?: Array<string | Array<string>>;
  isDate?: boolean;
  minDate?: Date;
  maxDate?: Date;
  keyStartDate?: string;
  keyEndDate?: string;
};
const props = withDefaults(defineProps<Props>(), {
  modelValue: () => [],
  isDate: false,
  minDate: undefined,
  maxDate: undefined,
  keyStartDate: undefined,
  keyEndDate: undefined,
});

const emit = defineEmits<{
  'update:modelValue': [value: [string[], string[]]];
  close: [];
}>();

const { uuid } = useUUID();
uuid.value.toString();

const startDate: Ref<string | undefined> = ref(undefined);
const endDate: Ref<string | undefined> = ref(undefined);
const isValid = ref(true);

watch(
  () => props.modelValue,
  () => {
    initialize();
  },
);

onMounted(() => {
  initialize();
});

function initialize() {
  if (props.modelValue.length === 0) {
    startDate.value = undefined;
    endDate.value = undefined;
  } else {
    startDate.value =
      props.modelValue[0] !== undefined && props.modelValue[0].length > 0
        ? props.modelValue[0][0]
        : undefined;
    endDate.value =
      props.modelValue[1] !== undefined && props.modelValue[1].length > 0
        ? props.modelValue[1][0]
        : undefined;
  }
}

function applyFilter() {
  emit('update:modelValue', [
    startDate.value === undefined ? [] : [startDate.value],
    endDate.value === undefined ? [] : [endDate.value],
  ]);
  emit('close');
}

function clear() {
  startDate.value = undefined;
  endDate.value = undefined;
  emit('update:modelValue', [[], []]);
  emit('close');
}
</script>

<style scoped>
.v-list-subheader {
  font-size: 12px;
  color: #0009 !important;
}

.v-list-item-title {
  font-size: 13px;
}
</style>

<style>
.filter-list-subheader .v-list-subheader__text {
  width: 100%;
}
</style>
