type DotReachModel = {
  dotReach: <A>(r: Record<string, A> | A | null | undefined, k: string) => unknown;
};

export function useDotReach(): DotReachModel {
  const dotReach = (r: unknown | null | undefined, k: string): unknown =>
    k.split('.').reduce((a, b) => {
      if (typeof a === 'object' && a !== null && b in a) {
        return (a as any)[b];
      }
      return undefined;
    }, r);

  return { dotReach };
}
