<template>
  <w-dialog
    ref="dialog"
    :sub-title="t('subTitle')"
    :title="t('title')"
    :model-value="modelValue"
    :state="dialogState"
    @update:model-value="(val) => emit('update:modelValue', val)"
  >
    <v-text-field
      v-model="name"
      variant="underlined"
      color="primary"
      :label="t('name')"
    />
    <v-select
      v-model="locale"
      :items="locales"
      variant="underlined"
      color="primary"
      :label="t('locale')"
    />
    <v-combobox
      v-model="timezone"
      :items="timezonesWithColor"
      :label="t('timezone')"
      variant="underlined"
      color="primary"
      :hint="t('timezoneDescription')"
      persistent-hint
      :return-object="false"
    />
    <template #left-actions>
      <v-btn
        v-if="!federatedAuthentication"
        color="error"
        variant="text"
        @click="showDeleteDialog"
      >
        {{ t('deleteAccount') }}
      </v-btn>
    </template>
    <template #main-action>
      <v-btn
        color="primary"
        variant="elevated"
        :loading="dialogState === 'saving'"
        @click="updateUser()"
      >
        <span v-t="'set'" />
      </v-btn>
    </template>
  </w-dialog>
  <delete-dialog
    v-if="!federatedAuthentication"
    v-model="deleteDialog"
    :deleting="processingDelete"
    :text="t('deleteDialog.text')"
    :text-to-confirm="t('deleteDialog.textToConfirm')"
    @confirm="deleteUser"
  />
  <authentication-dialog
    ref="authenticationDialog"
    v-model="showAuthenticationDialog"
    mode="full"
    :auth-on-mount="false"
    @update:authenticated="handleAuthUpdate"
  />
</template>

<script setup lang="ts">
import { ref, computed, watch, type Ref, inject } from 'vue';
import { useUser } from '@web-ui-root/composables/user';
import { useUUID } from '@web-ui-root/composables/uuid';
import { useI18n } from 'vue-i18n';
import { useHasUnsavedChanges } from '@web-ui-root/composables/has-unsaved-changes';
import { useBusHandler } from '@web-ui-root/composables/bus-handler';
import WDialog from '@web-ui-root/components/dialogs/w-dialog.vue';
import luxonValidTimezones from '@web-ui-root/helpers/timezones';
import { onBeforeRouteLeave, type RouteLocationRaw } from 'vue-router';
import DeleteDialog from '@web-ui-root/components/dialogs/delete-dialog.vue';
import AuthenticationDialog from '@web-ui-root/components/dialogs/authentication-dialog.vue';

type Props = {
  modelValue?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  modelValue: true,
});

const emit = defineEmits<{
  'update:modelValue': [value: boolean];
}>();

const pushRoute: ((location: RouteLocationRaw) => Promise<boolean>) | undefined =
  inject('pushRoute');

const { t } = useI18n({
  messages: {
    en: {
      title: 'Change your profile',
      subTitle: 'This affects the UI and all (email) communication',
      cancel: 'Cancel',
      set: 'Change',
      locale: 'Language',
      name: 'Name',
      timezone: 'Timezone',
      timezoneDescription:
        'The set timezone is primarily used on the server, in the browser the system timezone is used.',
      deleteAccount: 'Delete account',
      deleteDialog: {
        text: 'By deleting your account, you can no longer make use of any environment. Please make sure to hand over any work or rights to your colleagues before continuing.',
        textToConfirm: 'delete',
      },
      deleteThankYou: 'Your account has been deleted. Thank you for using AMP.',
    },
    nl: {
      title: 'Verander je profiel',
      subTitle: 'Dit beïnvloedt de UI en alle (email) communicatie',
      cancel: 'Annuleer',
      set: 'Stel in',
      locale: 'Taal',
      name: 'Naam',
      timezone: 'Tijdzone',
      timezoneDescription:
        'De ingestelde tijdzone wordt vooral gebruikt op de server, in de browser wordt er gebruik gemaakt van de systeem tijdzone.',
      deleteAccount: 'Account verwijderen',
      deleteDialog: {
        text: "Door je account te verwijderen, kun je geen gebruik meer maken van een omgeving. Zorg ervoor dat je werk of rechten overdraagt aan je collega's voordat je verder gaat.",
        textToConfirm: 'verwijderen',
      },
      deleteThankYou: 'Je account is verwijderd. Bedankt voor het gebruik van AMP.',
    },
  },
});

const locales = [
  { value: 'en', title: 'English', props: { color: 'primary' } },
  { value: 'nl', title: 'Nederlands', props: { color: 'primary' } },
];

const { uuid } = useUUID();
uuid.value.toString();
const {
  locale: storeLocale,
  update,
  logOut,
  deleteAccount,
  name: storeName,
  timezone: storeTimezone,
  federatedAuthentication,
} = useUser();
const { hasUnsavedChanges } = useHasUnsavedChanges();
const { emit: busEmit } = useBusHandler();

const authenticationDialog = ref<typeof AuthenticationDialog | null>(null);

const name = ref(storeName.value);
const processingUpdate = ref(false);
const locale = ref(storeLocale.value);
const baseLocale = ref(locale.value);
const timezone = ref(storeTimezone.value);
const processingDelete = ref(false);
const deleteDialog = ref(false);

const showAuthenticationDialog = defineModel<boolean>('showAuthenticationDialog');

const dialog: Ref<typeof WDialog | null> = ref(null);

const timezonesWithColor = computed(() =>
  luxonValidTimezones.map((tz) => ({
    value: tz,
    title: tz,
    props: { color: 'primary' },
  })),
);

const dialogState = computed(() => {
  if (props.modelValue === false) {
    return 'pristine';
  }
  if (processingUpdate.value) {
    return 'saving';
  }
  if (
    locale.value !== baseLocale.value ||
    name.value !== storeName.value ||
    timezone.value !== storeTimezone.value
  ) {
    return 'tainted';
  }
  return 'pristine';
});

watch(dialogState, (val) => {
  hasUnsavedChanges.value = val === 'tainted';
});

onBeforeRouteLeave((_to, _from, next) => {
  if (deleteDialog.value === true) {
    next();
    return;
  }

  if (dialog.value !== null && dialog.value.$.exposed.beforeRouteLeaveGuard !== undefined) {
    dialog.value.$.exposed.beforeRouteLeaveGuard(next);
  }
});

async function updateUser() {
  processingUpdate.value = true;
  try {
    await update({ locale: locale.value, name: name.value ?? undefined, timezone: timezone.value });
  } catch (err) {
    busEmit('commsError', err);
    processingUpdate.value = false;
    return;
  }
  processingUpdate.value = false;
  storeLocale.value = locale.value;

  emit('update:modelValue', false);
}

async function deleteUser() {
  processingDelete.value = true;

  try {
    await deleteAccount();
    busEmit('success', t('deleteThankYou'));
    // post will fail, however we want to clear the local authentication store
    await logOut();
    if (pushRoute !== undefined) {
      await pushRoute('/login');
    }
  } catch (err) {
    busEmit('commsError', err);
    processingDelete.value = false;
    return;
  }

  processingDelete.value = false;
}

function handleAuthUpdate(val: boolean) {
  if (val === true) {
    deleteDialog.value = true;
  }
}

async function showDeleteDialog() {
  const authCheck = await authenticationDialog.value?.authCheck();
  if (authCheck === true) {
    deleteDialog.value = true;
  }
}
</script>
