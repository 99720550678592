<template>
  <div class="d-flex">
    <date-picker
      :model-value="date"
      :label="label"
      :min="min"
      :max="max"
      :prepend-icon="prependIcon"
      :allow-empty="allowEmpty"
      :show-footer-buttons="false"
      :disabled="disabled"
      @update:model-value="setDate"
    />
    <time-picker
      :model-value="time"
      class="ml-2"
      style="width: 11.25rem"
      :disabled="disabled"
      :allow-empty="allowEmpty"
      :granularity="timeGranularity"
      @update:model-value="setTime"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, type Ref, computed, watch, onMounted } from 'vue';
import DateTimeWrapper, { YYYY_MM_DD } from '@web-ui-root/helpers/date-utils/date-time-wrapper';
import { useUUID } from '@web-ui-root/composables/uuid';
import DatePicker from './date-picker.vue';
import TimePicker from './time-picker.vue';
import { type TimeKeys, useTimePicker } from './time-picker';

const { formatMap } = useTimePicker();

const { uuid } = useUUID();
uuid.value.toString();

type Props = {
  modelValue: string | Date | undefined;
  label?: string;
  min?: Date;
  max?: Date;
  allowEmpty?: boolean;
  disabled?: boolean;
  prependIcon?: string;
  timeGranularity?: TimeKeys;
};

const props = withDefaults(defineProps<Props>(), {
  modelValue: undefined,
  label: undefined,
  min: undefined,
  max: undefined,
  allowEmpty: true,
  disabled: false,
  prependIcon: 'mdi-calendar',
  timeGranularity: 'minutes',
});

const emit = defineEmits<{
  'update:modelValue': [time: string | Date | undefined];
}>();

const date: Ref<Date | undefined> = ref(undefined);
const time = ref('');

const emitValue = computed(() => {
  let dateTimeString: string | undefined;

  if (date.value === undefined) {
    dateTimeString = undefined;
  } else {
    const dateString = DateTimeWrapper.fromJSDate(date.value).toFormat(YYYY_MM_DD);
    dateTimeString = time.value === '' ? dateString : `${dateString}T${time.value}`;
  }

  if (props.modelValue instanceof Date) {
    return new Date(dateTimeString ?? '');
  }

  return dateTimeString;
});

watch(
  () => props.modelValue,
  () => {
    initialize();
  },
);

onMounted(() => {
  initialize();
});

function initialize() {
  const bothUndefined = props.modelValue === undefined && emitValue.value === undefined;
  const bothSameDate =
    props.modelValue instanceof Date &&
    emitValue.value instanceof Date &&
    (props.modelValue.valueOf() === emitValue.value.valueOf() ||
      (Number.isNaN(props.modelValue.valueOf()) && Number.isNaN(emitValue.value?.valueOf())));

  if (bothUndefined || bothSameDate) {
    return;
  }

  if (props.modelValue === undefined) {
    setToDefault();
    return;
  }

  const dt =
    props.modelValue instanceof Date
      ? DateTimeWrapper.fromJSDate(props.modelValue)
      : DateTimeWrapper.fromISO(props.modelValue);
  if (dt.isValid) {
    date.value = dt.startOf('day').toJSDate();
    time.value = dt.toFormat(formatMap[props.timeGranularity].format);
  }

  emit('update:modelValue', emitValue.value);
}

function setToDefault() {
  time.value = '';
  date.value = props.allowEmpty ? undefined : DateTimeWrapper.now().startOf('day').toJSDate();
}

function setDate(val: Date | undefined): void {
  date.value = val;
  if (val === undefined) {
    time.value = '';
  }

  emit('update:modelValue', emitValue.value);
}

function setTime(val: string): void {
  time.value = val;

  if (date.value === undefined && val !== '') {
    date.value = DateTimeWrapper.now().toJSDate();
  }

  emit('update:modelValue', emitValue.value);
}
</script>
