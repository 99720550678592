<template>
  <v-menu
    ref="menu"
    v-model="open"
    eager
    :offset="[inMenu ? -8 : 0, inMenu ? 0 : 8]"
    :max-width="maxWidth"
    :location="inMenu ? 'right' : 'bottom'"
    :close-on-content-click="false"
  >
    <template #activator="{ props: menuProps }">
      <v-list-item
        v-if="inMenu"
        v-bind="menuProps"
        min-height="40px"
      >
        <div>
          <v-list-item-title
            :class="{ active: hasValue }"
            class="filter"
          >
            {{ capitalizedNameSingular }}
          </v-list-item-title>
        </div>
        <template #append>
          <v-icon class="carret"> mdi-chevron-right </v-icon>
        </template>
      </v-list-item>
      <v-btn
        v-else
        class="my-0 px-0 toggle-button mx-2"
        :ripple="false"
        selected-class=""
        :color="hasValue ? 'info' : undefined"
        variant="text"
        v-bind="menuProps"
      >
        {{ nameSingular }}
        <v-icon>{{ open ? 'mdi-menu-up' : 'mdi-menu-down' }}</v-icon>
      </v-btn>
    </template>
    <slot
      v-if="!isDate && isAutocompleteInputEvent(modelValue) && options !== undefined"
      name="card"
    >
      <w-inline-autocomplete-card
        v-if="open"
        :model-value="modelValue"
        :name-manual-entry="nameManualEntry"
        :title="t('filterBy', [nameSingular])"
        :name-plural="namePlural"
        :options="options"
        :allow-duplicates="allowDuplicates"
        :multiple="multiple"
        :hide-search="hideSearch"
        :empty-option="emptyOption ? t('emptyOption', [nameSingular]) : null"
        :in-menu="inMenu"
        class="ml-2"
        @update:model-value="(val) => emit('update:modelValue', val)"
        @close="open = false"
      />
    </slot>

    <slot
      v-if="isDate"
      name="card"
    >
      <filter-date
        :model-value="modelValue"
        :is-date="isDate"
        :name-singular="nameSingular"
        :min-date="minDate"
        :max-date="maxDate"
        :key-start-date="keyStartDate"
        :key-end-date="keyEndDate"
        class="ml-2"
        @update:model-value="(val) => emit('update:modelValue', val)"
        @close="open = false"
      />
    </slot>
  </v-menu>
</template>

<script setup lang="ts">
import { ref, computed, watch, type Ref } from 'vue';
import { VMenu } from 'vuetify/lib/components/index.mjs';
import { useI18n } from 'vue-i18n';
import { useUUID } from '@web-ui-root/composables/uuid';
import { isAutocompleteInputEvent, type InputEvent, type GenericFilter } from './types';
import FilterDate from './filter-date.vue';
import WInlineAutocompleteCard from '../inputs/inline-autocomplete-card.vue';

const { t } = useI18n({
  messages: {
    en: {
      filterBy: 'Filter by {0}',
      emptyOption: 'Without {0}',
    },
    nl: {
      filterBy: 'Filter op {0}',
      emptyOption: 'Zonder {0}',
    },
  },
});

type Props = {
  modelValue?: Array<string> | [Array<string>, Array<string>];
  nameManualEntry?: string;
  nameSingular: string;
  namePlural: string;
  options?: GenericFilter['options'];
  multiple?: boolean;
  hideSearch?: boolean;
  emptyOption?: boolean;
  inMenu?: boolean;
  isDate?: boolean;
  minDate?: Date;
  maxDate?: Date;
  keyStartDate?: string;
  keyEndDate?: string;
  allowDuplicates?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  modelValue: () => [],
  nameManualEntry: 'hashId',
  options: undefined,
  multiple: false,
  hideSearch: false,
  emptyOption: false,
  inMenu: false,
  isDate: false,
  minDate: undefined,
  maxDate: undefined,
  keyStartDate: undefined,
  keyEndDate: undefined,
  allowDuplicates: true,
});

const emit = defineEmits<{
  'update:modelValue': [input: InputEvent];
}>();

const { uuid } = useUUID();
uuid.value.toString();
const open = ref(false);
const maxWidth = ref('inherit');
const menu: Ref<VMenu | null> = ref(null);

const capitalizedNameSingular = computed(
  () => props.nameSingular.charAt(0).toUpperCase() + props.nameSingular.slice(1),
);

const hasValue = computed(() => {
  if (props.isDate) {
    return (
      (props.modelValue[0] !== undefined && props.modelValue[0].length > 0) ||
      (props.modelValue[1] !== undefined && props.modelValue[1].length > 0)
    );
  }

  return props.modelValue.length > 0;
});

watch(open, (val, oldVal) => {
  if (val !== oldVal && val) {
    setTimeout(() => {
      if (menu.value !== null && menu.value.contentEl !== undefined) {
        const el: HTMLElement = menu.value.contentEl;
        maxWidth.value = `calc(100vw - ${el.style.getPropertyValue('left')} - 1rem)`;
      }
    }, 50);
  }
});

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore The function can be called by a parent with `ref.$.exposed.updateDimensions
function updateDimensions() {
  if (menu.value !== null && menu.value.updateLocation !== undefined) {
    menu.value.updateLocation(new UIEvent('resize'));
  }
}
</script>

<style scoped lang="scss">
.toggle-button {
  min-width: 0px;
}

.toggle-button:deep(.v-btn__overlay) {
  background-color: unset !important;
}

.filter {
  font-size: 0.8125rem;
  font-weight: 500;

  &.active {
    color: var(--theme-color-info);
  }
}

.carret {
  margin: 0.5rem 0 0.5rem 0rem;
}
</style>
