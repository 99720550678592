<template>
  <server-table-dialog
    ref="tableDialog"
    :title="t(`title.${tab}`)"
    :addable="canCreateEnvironment"
    :fullscreen="!mdAndUp"
    :objects-name="t(`objectsName.${tab}`)"
    searchable
    :sort-by-options="sortByOptions"
    :sort-by="'name'"
    :table-controller="tableController"
    :model-value="modelValue"
    clickable
    max-width="500px"
    :row-key="rowKey"
    :response-mapper="responseMapper"
    :query="query"
    :show-pagination-label="false"
    :content-classes="['environments-dialog']"
    immediate-search
    disable-route-updating
    @update:model-value="() => routeBack()"
    @new="newEnvironment"
    @row-click="(item: any) => setEnvironment(item)"
  >
    <template #header>
      <div style="width: 100%; flex: 0 0 auto">
        <v-tabs
          v-model="tab"
          slider-color="primary"
          grow
          @update:model-value="
            (val: unknown) => setEnvironmentType(typeof val !== 'number' ? 0 : val)
          "
        >
          <v-tab :text="t('tab.0')" />
          <v-tab :text="t('tab.1')" />
        </v-tabs>
      </div>
    </template>
  </server-table-dialog>
</template>

<script setup lang="ts">
import { ref, type Ref, computed, nextTick, inject, onMounted } from 'vue';
import { useDisplay } from 'vuetify';
import { type TableQuery, models } from '@withthegrid/amp-sdk';
import { useUUID } from '@web-ui-root/composables/uuid';
import { type ServerTableRow } from '@web-ui-root/components/server-table/types';
import ServerTableDialog, {
  type ServerTableDialogExposed,
} from '@web-ui-root/components/server-table/server-table-dialog.vue';
import { useTitle } from '@web-ui-root/composables/title';
import { useBusHandler } from '@web-ui-root/composables/bus-handler';
import { useI18n } from 'vue-i18n';
import { useUser } from '@web-ui-root/composables/user';
import { useEnvironment } from '@web-ui-root/composables/environment';
import { useIotEnvironment } from '@web-ui-root/composables/iot-environment';
import { useSDK } from '@web-ui-root/composables/sdk';
import Invariant from '@web-ui-root/helpers/invariant';
import { type RouteLocationRaw, useRouter } from 'vue-router';

type SortByOption = {
  value: string;
  title: string;
  descending: boolean;
  toggleable: boolean;
};

const pushRoute: ((location: RouteLocationRaw) => Promise<boolean>) | undefined =
  inject('pushRoute');
const goToDefaultRoute: (() => Promise<boolean>) | undefined = inject('goToDefaultRoute');
const hasRight: undefined | ((value: string) => boolean) = inject('hasRight');

const { mdAndUp } = useDisplay();
const { emit: busEmit } = useBusHandler();
const { uuid } = useUUID();
const { title } = useTitle({ uuid });
const { t } = useI18n({
  messages: {
    en: {
      name: 'Name',
      title: {
        0: 'Monitoring environments',
        1: 'Connectivity environments',
      },
      tab: {
        0: 'Monitoring',
        1: 'Connectivity',
      },
      objectsName: {
        0: 'monitoring environments',
        1: 'connectivity environments',
      },
      remainsAt: 'Environment "{0}" was already selected',
      switchedTo: {
        environment: 'Switched to monitoring environment "{0}"',
        supplier: 'Switched to connectivity environment "{0}"',
      },
      twofaRequired: 'Two factor authentication required',
    },
    nl: {
      name: 'Naam',
      title: {
        0: 'Monitoring-omgevingen',
        1: 'Connectiviteits-omgevingen',
      },
      tab: {
        0: 'Monitoring',
        1: 'Connectiviteit',
      },
      objectsName: {
        0: 'monitoring-omgevingen',
        1: 'connectiviteits-omgevingen',
      },
      remainsAt: 'Omgeving "{0}" was al geselecteerd',
      switchedTo: {
        environment: 'Gewisseld naar monitoring-omgeving "{0}"',
        supplier: 'Gewisseld naar connectiviteits-omgeving "{0}"',
      },
      twofaRequired: 'Tweefactorauthenticatie vereist',
    },
  },
});

title.value = t('title.0');

type Props = {
  modelValue?: boolean;
  query?: TableQuery;
};

withDefaults(defineProps<Props>(), {
  modelValue: true,
  query: () => ({}),
});
const emit = defineEmits<{
  'update:modelValue': [value: boolean];
}>();

const { twoFactorAuthenticationEnabled, federatedAuthentication } = useUser();
const { hashId: monitoringEnvHashId } = useEnvironment();
const { hashId: connectivityEnvHashId } = useIotEnvironment();
const { sdk } = useSDK();
const { currentRoute } = useRouter();

const tab = ref(0);
const sortByOptions: Ref<Array<SortByOption>> = ref([
  {
    value: 'name',
    descending: false,
    toggleable: false,
    title: t('name'),
  },
]);

const tableDialog: Ref<ServerTableDialogExposed<Row> | null> = ref(null);

const canCreateEnvironment = computed(() => {
  if (hasRight === undefined) {
    return false;
  }
  return hasRight('ADMIN');
});

const tableController = computed(() => {
  if (tab.value === 0) {
    return sdk.routes.environment.findTableController;
  }
  return sdk.routes.supplier.findTableController;
});

const rowKey = computed(() => (tab.value === 0 ? 'environment.hashId' : 'supplier.hashId'));

type Response<T extends 'environment' | 'supplier'> = T extends 'environment'
  ? {
      environment: models.environment.Environment;
      environmentRights: Array<string>;
    }
  : {
      supplier: models.supplier.Supplier;
      supplierRights: Array<string>;
    };

type Row = ServerTableRow & {
  environmentType: 'supplier' | 'environment';
  title: string;
  environment: models.environment.Environment | models.supplier.Supplier;
  environmentRights: Array<string>;
  requiresTwoFA: boolean;
  subTitle?: string;
};

function responseIsTableRow<T extends 'environment' | 'supplier'>(
  row: unknown,
): row is Response<T> {
  if (typeof row === 'object' && row !== null) {
    if ('environment' in row) {
      return 'environmentRights' in row && 'userEnvironmentSettings' in row;
    }
    if ('supplier' in row) {
      return 'supplierRights' in row;
    }
  }

  console.error(row);
  throw new Invariant('row is not a ServerTableRow');
}

function responseMapper(row: Response<'environment' | 'supplier'>): Row {
  if (tab.value === 0) {
    if (!responseIsTableRow<'environment'>(row)) {
      throw new Invariant('row is not an environment');
    }
    return {
      environmentType: 'environment',
      hashId: row.environment.hashId,
      title: row.environment.name,
      environment: row.environment,
      environmentRights: row.environmentRights,
      subTitle:
        row.environment.enforceTwoFactorAuthentication && !twoFactorAuthenticationEnabled
          ? t('twofaRequired')
          : undefined,
      requiresTwoFA: row.environment.enforceTwoFactorAuthentication,
    };
  }
  if (!responseIsTableRow<'supplier'>(row)) {
    throw new Invariant('supplier is not in row');
  }
  return {
    environmentType: 'supplier',
    hashId: row.supplier.hashId,
    title: row.supplier.name,
    environment: row.supplier,
    environmentRights: row.supplierRights,
    subTitle:
      row.supplier.enforceTwoFactorAuthentication && !twoFactorAuthenticationEnabled
        ? t('twofaRequired')
        : undefined,
    requiresTwoFA: row.supplier.enforceTwoFactorAuthentication,
  };
}

function setEnvironmentType(val: number): void {
  tab.value = val;
  nextTick(() => {
    tableDialog.value?.refresh();
  });
}

function setEnvironment(item: Row): void {
  const isSameEnvironment =
    item.environmentType === 'environment'
      ? monitoringEnvHashId.value === item.environment.hashId
      : connectivityEnvHashId.value === item.environment.hashId;
  if (isSameEnvironment) {
    busEmit('info', t('remainsAt', [item.environment.name]));
    emit('update:modelValue', false);
    return;
  }

  const redirectPrefix = item.environmentType === 'environment' ? '/e' : '/i';
  const redirect = `${redirectPrefix}/${item.environment.hashId}`;

  if (
    item.requiresTwoFA &&
    !twoFactorAuthenticationEnabled.value &&
    pushRoute !== undefined &&
    !federatedAuthentication.value
  ) {
    // not needed as server will return an AuthenticationError with key
    // 2fa_required on the first call from this environment, but this saves
    // comms _and_ looks cleaner as the environment isn't first loaded
    pushRoute({ path: '/two-factor-authentication-required', query: { redirect } });
    return;
  }

  emit('update:modelValue', false);
  if (pushRoute !== undefined) {
    nextTick(() => {
      pushRoute(redirect);
    });
    busEmit('info', t(`switchedTo.${item.environmentType}`, [item.environment.name]));
  }
}

function newEnvironment(): void {
  if (pushRoute !== undefined) {
    if (tab.value === 0) {
      pushRoute('/new-environment/monitoring');
    } else {
      pushRoute('/new-environment/connectivity');
    }
  }
}

async function routeBack(): Promise<void> {
  if (currentRoute.value.path === '/environments') {
    if (goToDefaultRoute !== undefined) {
      await goToDefaultRoute();
    }
  }
  emit('update:modelValue', false);
}

onMounted(() => {
  // @ts-expect-error global event defined in index.html
  document.dispatchEvent(loaderEvent);
});
</script>

<script lang="ts">
export default {
  name: 'EnvironmentsList',
};
</script>

<style>
@media screen and (min-width: 960px) {
  .environments-dialog {
    height: 90vh;
  }
}
</style>
