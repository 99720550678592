import { models } from '@withthegrid/amp-sdk';

type AutocompleteInputResult = Array<string>;
type DateInputResult = [Array<string>, Array<string>];
export type InputEvent = AutocompleteInputResult | DateInputResult;

export function isDateInputEvent(input: InputEvent): input is DateInputResult {
  return Array.isArray(input[0]) && Array.isArray(input[1]);
}

export function isAutocompleteInputEvent(input: InputEvent): input is AutocompleteInputResult {
  return !isDateInputEvent(input);
}

export type SearchChanges = {
  search?: string;
  sortBy?: string;
  descending?: boolean;
};

export type DateFilter = {
  key: string;
  isDate: true;
  keyStartDate?: string;
  keyEndDate?: string;
  nameSingular: string;
  namePlural: string;
};

export type FilterOptionsOption = {
  title: string;
  key: string;
  value?: string | object;
};

export type FilterOptions = {
  cancelFunction: null | (() => void);
  options: {
    options: Array<FilterOptionsOption>;
    nextPageOffset?: string | null;
  };
};

export type PromiseFilterOptions = {
  options: {
    value?: string | object;
    title: models.stringOrTranslations.StringOrTranslations;
    key: string;
  }[];
  nextPageOffset: string | null | undefined;
};

export type PromiseFilter = (
  search: string,
  rowsPerPage: number,
  nextPageOffset?: string,
) => {
  cancelFunction: null | (() => void);
  options: Promise<PromiseFilterOptions>;
};

export type GenericFilter = {
  key: string;
  isDate?: false;
  nameSingular: string;
  namePlural: string;
  nameManualEntry?: string;
  hideSearch?: boolean;
  multiple?: boolean;
  emptyOption?: boolean;
  allowDuplicates?: boolean;
  options:
    | (() => FilterOptions)
    | ((search: string, rowsPerPage: number, nextPageOffset?: string) => FilterOptions)
    | null
    | PromiseFilter;
};

export function isDateFilter(filter: Filter): filter is DateFilter {
  return filter.isDate === true;
}

export type Filter = DateFilter | GenericFilter;

export type SortByOption = {
  value: string;
  title: string;
  descending: boolean;
  toggleable?: boolean;
};

type RowAllowedValues<T> = {
  [P in keyof T]?: T[P][];
};

type Condition<T> = {
  properties: RowAllowedValues<T>;
  message: string;
};

export type RefreshStrategy<T> = {
  success: Condition<T>;
  stop: Condition<T>;
  interval: {
    baseInMs: number;
    growthFactor: number;
  };
  timeout: {
    message: string;
    durationInMs: number;
  };
};

export type ServerTableRow = {
  hashId: string;
  title: string;
  to?: string;
  action?: string;
  date?: string;
  subTitle?: string;
  titleClass?: string;
};

// TODO: possibly add this instead of & Record<string, unknown> to ServerTableRow,
// but in better manner:
//
// for pins.vue we need
// pinGroup: models.pinGroup.PinGroup & {
//   hashId: string;
// }
//
// for pin-groups.vue we need
// lastOnlineAt: string | null;
// level: 0 | 1 | 2;
// device: models.device.Device;
//
// for egdes.vue we need
// level: 0 | 1 | 2;
// mostRecentMeasurementAt: string;
//
// for condition-overview/table.vue we need:
// measurementFilter: models.measurementFilter.MeasurementFilter;
