import { computed, type ComputedRef, ref, type Ref } from 'vue';
import { models } from '@withthegrid/amp-sdk';
import { type ColorKeys, type Theme, ThemeManager, THEMES } from '@web-ui-root/helpers/theme';
import { useSDK } from '@web-ui-root/composables/sdk';
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from '@web-ui-root/helpers/local-storage/local-storage';
import { setLastEnvironmentHashId } from '@web-ui-root/helpers/local-storage/local-storage-user';
import Invariant from '@web-ui-root/helpers/invariant';
import { useLeftPanel } from './left-panel';
import { useBusHandler } from './bus-handler';
import { useRollbar } from './rollbar';

const pinGroupsAsDotsAtLowZoomLevelsLocalStorageKey = 'pinGroupsAsDotsAtLowZoomLevels';
const filteredOpacityLevelKey = 'filteredOpacityLevel';
export const mapLayersStorageKey = 'hiddenMapLayers';
const DEFAULT_THEME = THEMES['Withthegrid Original'];
const themeManager = ThemeManager.getInstance(DEFAULT_THEME);
const { info } = useRollbar();

type LocalStorageMapLayer = {
  environmentHashId: string;
  mapLayers: Array<string>;
};
type LocalStorageMapLayers = Array<LocalStorageMapLayer>;

type Fn<T> = (...args: any[]) => T;
type L10nVal<K> = K extends string ? string : Fn<string>;
type L10nKey = string;
type L10n = Record<L10nKey, L10nVal<unknown>>;

const t: Record<string, L10n> = {
  en: {
    switchedTo: (name) => `Monitoring environment '${name}'`,
  },
  nl: {
    switchedTo: (name) => `Monitoring-omgeving '${name}'`,
  },
};

type Environment = models.environment.Environment;
type EnvironmentRights = Array<string>;
type UserEnvironmentSettings = models.userEnvironmentSettings.UserEnvironmentSettings;
type MapLayer = models.mapLayer.MapLayer;
type BoundingBox = Environment['boundingBox'];
type FieldConfigurations = Environment['fieldConfigurations'];

enum NotificationLevel {
  All = 0,
  Serious = 1,
  Critical = 2,
}

type EnvironmentState = {
  hashId: Ref<string | null>;
  name: Ref<string | null>;
  mapLayers: Ref<Array<MapLayer>>;
  boundingBox: Ref<BoundingBox | null>;
  fieldConfigurations: Ref<FieldConfigurations>;
  locale: Ref<models.locale.Locale>;
  defaultGraphRange: Ref<models.environment.GraphRangeKey>;
  expiresAt: Ref<Date | null>;
  createdAt: Ref<Date>;
  rights: Ref<Array<string>>;
  notificationLevel: Ref<NotificationLevel | null>;
  defaultAnalyticsPanelHashId: Ref<string | null>;
  hasDevices: Ref<boolean>;
  hasReports: Ref<boolean>;
  hiddenMapLayerKeys: Ref<Array<string>>;
  pinGroupsAsDotsAtLowZoomLevels: Ref<boolean>;
  measurementsExpirationDays: Ref<number>;
  measurementsRetentionPolicyRange: Ref<models.environment.MeasurementsRetentionPolicyTimeRangeKey | null>;
  enforceTwoFactorAuthentication: Ref<boolean>;
  allowAutoCloseConnectivityIssues: Ref<boolean>;
  allowAutoCloseThresholdIssues: Ref<boolean>;
  issueAutoClosingTriggerAmount: Ref<number>;
  issueAutoClosingTriggerUnitType: Ref<
    models.environment.Environment['issueAutoClosingTriggerUnitType']
  >;
  logo: Ref<string | null>;
  theme: Ref<Theme>;
  filteredOpacityLevel: Ref<number>;
};

export type EnvironmentModel = {
  defaultAnalyticsPanelHashId: EnvironmentState['defaultAnalyticsPanelHashId'];
  hashId: EnvironmentState['hashId'];
  name: EnvironmentState['name'];
  locale: EnvironmentState['locale'];
  defaultGraphRange: EnvironmentState['defaultGraphRange'];
  enforceTwoFactorAuthentication: EnvironmentState['enforceTwoFactorAuthentication'];
  allowAutoCloseConnectivityIssues: EnvironmentState['allowAutoCloseConnectivityIssues'];
  allowAutoCloseThresholdIssues: EnvironmentState['allowAutoCloseThresholdIssues'];
  issueAutoClosingTriggerAmount: EnvironmentState['issueAutoClosingTriggerAmount'];
  issueAutoClosingTriggerUnitType: EnvironmentState['issueAutoClosingTriggerUnitType'];
  measurementsExpirationDays: EnvironmentState['measurementsExpirationDays'];
  measurementsRetentionPolicyRange: EnvironmentState['measurementsRetentionPolicyRange'];
  expiresAt: EnvironmentState['expiresAt'];
  boundingBox: EnvironmentState['boundingBox'];
  notificationLevel: EnvironmentState['notificationLevel'];
  fieldConfigurations: EnvironmentState['fieldConfigurations'];
  pinGroupsAsDotsAtLowZoomLevels: EnvironmentState['pinGroupsAsDotsAtLowZoomLevels'];
  mapLayers: EnvironmentState['mapLayers'];
  hasDevices: EnvironmentState['hasDevices'];
  rights: EnvironmentState['rights'];
  hasReports: EnvironmentState['hasReports'];
  logo: EnvironmentState['logo'];
  theme: EnvironmentState['theme'];
  filteredOpacityLevel: EnvironmentState['filteredOpacityLevel'];

  activeMapLayers: ComputedRef<Array<MapLayer>>;
  internalMapLayers: ComputedRef<Array<MapLayer>>;
  activeInternalMapLayers: ComputedRef<Array<MapLayer>>;

  /**
   * @deprecated Use the globally defined `hasRight` function instead.
   */
  hasRight: (right: string) => boolean;
  themeColor: (color: keyof ColorKeys) => string;
  themeHighlightColor: (color: keyof ColorKeys) => string;
  update: (environment: Environment) => void;
  load: (hashId: string, userLocale: string) => Promise<void>;
  set: (data: {
    environment: Environment;
    environmentRights: EnvironmentRights;
    userEnvironmentSettings: UserEnvironmentSettings;
  }) => Promise<void>;
  clear: () => void;
  queryReports: () => Promise<void>;
  queryDevices: () => Promise<void>;
  setHiddenMapLayerKeys: (hiddenMapLayerKeys: Array<string>) => void;
  setPinGroupsAsDotsAtLowZoomLevels: (pinGroupsAsDotsAtLowZoomLevels: boolean) => void;
  setFilteredOpacityLevel: (opacity: number) => void;
};

const { clear: leftPanelClear } = useLeftPanel();

const state: EnvironmentState = {
  hashId: ref(null),
  name: ref(null),
  mapLayers: ref([]),
  boundingBox: ref(null),
  fieldConfigurations: ref({
    pinGroups: [],
    edges: [],
    grids: [],
    pins: [],
  }),
  locale: ref('en'),
  defaultGraphRange: ref('30d'),
  expiresAt: ref(null),
  createdAt: ref(new Date()),
  rights: ref([]),
  notificationLevel: ref(null),
  defaultAnalyticsPanelHashId: ref(null),
  hasDevices: ref(false),
  hasReports: ref(true),
  hiddenMapLayerKeys: ref([]),
  pinGroupsAsDotsAtLowZoomLevels: ref(false),
  measurementsExpirationDays: ref(365),
  measurementsRetentionPolicyRange: ref(null),
  enforceTwoFactorAuthentication: ref(false),
  allowAutoCloseConnectivityIssues: ref(false),
  allowAutoCloseThresholdIssues: ref(false),
  issueAutoClosingTriggerAmount: ref(1),
  issueAutoClosingTriggerUnitType: ref('count'),
  logo: ref(null),
  theme: ref(themeManager.theme),
  filteredOpacityLevel: ref(0.3),
};

export function useEnvironment(): EnvironmentModel {
  const { emit } = useBusHandler();
  const { sdk } = useSDK();

  const loading = (hashId: string) => {
    state.hashId.value = hashId;
  };

  const loaded = (data: {
    environment: Environment;
    environmentRights: EnvironmentRights;
    userEnvironmentSettings: UserEnvironmentSettings;
    hiddenMapLayerKeys: Array<string>;
    pinGroupsAsDotsAtLowZoomLevels: boolean;
    filteredOpacityLevel: number;
  }): void => {
    const {
      environment,
      environmentRights,
      userEnvironmentSettings,
      hiddenMapLayerKeys,
      pinGroupsAsDotsAtLowZoomLevels,
      filteredOpacityLevel,
    } = data;
    info('Calling useEnvironment.loaded() filling the environment state');
    const defaultRange = environment.defaultGraphRange;
    const rights = environmentRights ?? [];

    state.hashId.value = environment.hashId;
    state.name.value = environment.name;
    state.mapLayers.value = environment.mapLayers;
    state.measurementsExpirationDays.value = environment.measurementsExpirationDays;
    state.measurementsRetentionPolicyRange.value = environment.measurementsRetentionPolicyRange;
    state.enforceTwoFactorAuthentication.value = environment.enforceTwoFactorAuthentication;
    state.allowAutoCloseConnectivityIssues.value = environment.allowAutoCloseConnectivityIssues;
    state.allowAutoCloseThresholdIssues.value = environment.allowAutoCloseThresholdIssues;
    state.issueAutoClosingTriggerAmount.value = environment.issueAutoClosingTriggerAmount;
    state.issueAutoClosingTriggerUnitType.value = environment.issueAutoClosingTriggerUnitType;
    state.boundingBox.value = environment.boundingBox;
    state.fieldConfigurations.value.pinGroups = environment.fieldConfigurations.pinGroups;
    state.fieldConfigurations.value.edges = environment.fieldConfigurations.edges;
    state.fieldConfigurations.value.grids = environment.fieldConfigurations.grids;
    state.fieldConfigurations.value.pins = environment.fieldConfigurations.pins;
    state.locale.value = environment.locale;
    state.defaultGraphRange.value = defaultRange;
    state.expiresAt.value = environment.expiresAt;
    state.createdAt.value = environment.createdAt;
    state.rights.value = rights;
    state.notificationLevel.value = userEnvironmentSettings.notificationLevel;
    state.defaultAnalyticsPanelHashId.value = userEnvironmentSettings.defaultAnalyticsPanelHashId;
    state.hasDevices.value = false;
    state.hasReports.value = true;
    state.hiddenMapLayerKeys.value = hiddenMapLayerKeys;
    state.pinGroupsAsDotsAtLowZoomLevels.value = pinGroupsAsDotsAtLowZoomLevels;
    state.logo.value = environment.logo?.url ?? null;
    state.filteredOpacityLevel.value = filteredOpacityLevel;

    const theme =
      environment.theme !== undefined && environment.theme !== null
        ? {
            light: {
              // reordering keys
              primary: environment.theme.light.primary,
              secondary: environment.theme.light.secondary,
              accent: environment.theme.light.accent,
              neutral: environment.theme.light.neutral,
              info: environment.theme.light.info,
              warning: environment.theme.light.warning,
              error: environment.theme.light.error,
              success: environment.theme.light.success,
            },
          }
        : THEMES['Withthegrid Original'];
    themeManager.switchTheme(theme);
    state.theme.value = theme;
  };

  const update = (data: Environment): void => {
    info('Calling useEnvironment.update() filling the environment state');
    state.name.value = data.name ?? state.name.value;
    state.mapLayers.value = data.mapLayers ?? state.mapLayers.value;
    state.fieldConfigurations.value = data.fieldConfigurations ?? state.fieldConfigurations.value;
    state.locale.value = data.locale ?? state.locale.value;
    state.defaultGraphRange.value = data.defaultGraphRange ?? state.defaultGraphRange.value;
    state.measurementsExpirationDays.value =
      data.measurementsExpirationDays ?? state.measurementsExpirationDays.value;
    state.measurementsRetentionPolicyRange.value =
      data.measurementsRetentionPolicyRange ?? state.measurementsRetentionPolicyRange.value;
    state.enforceTwoFactorAuthentication.value =
      data.enforceTwoFactorAuthentication ?? state.enforceTwoFactorAuthentication.value;
    state.allowAutoCloseConnectivityIssues.value =
      data.allowAutoCloseConnectivityIssues ?? state.allowAutoCloseConnectivityIssues.value;
    state.allowAutoCloseThresholdIssues.value =
      data.allowAutoCloseThresholdIssues ?? state.allowAutoCloseThresholdIssues.value;
    state.issueAutoClosingTriggerAmount.value =
      data.issueAutoClosingTriggerAmount ?? state.issueAutoClosingTriggerAmount.value;
    state.issueAutoClosingTriggerUnitType.value =
      data.issueAutoClosingTriggerUnitType ?? state.issueAutoClosingTriggerUnitType.value;
    state.logo.value = data.logo?.url ?? state.logo.value;
    state.theme.value = data.theme ?? state.theme.value;
  };

  const cleared = () => {
    info('Calling useEnvironment.cleared() clearing the environment state');
    state.hashId.value = null;
    state.name.value = null;
    state.mapLayers.value = [];
    state.measurementsExpirationDays.value = 365;
    state.measurementsRetentionPolicyRange.value = null;
    state.enforceTwoFactorAuthentication.value = false;
    state.allowAutoCloseConnectivityIssues.value = false;
    state.allowAutoCloseThresholdIssues.value = false;
    state.issueAutoClosingTriggerAmount.value = 1;
    state.issueAutoClosingTriggerUnitType.value = 'count';
    state.boundingBox.value = null;
    state.fieldConfigurations.value.pinGroups = [];
    state.fieldConfigurations.value.edges = [];
    state.fieldConfigurations.value.grids = [];
    state.fieldConfigurations.value.pins = [];
    state.locale.value = 'en';
    state.defaultGraphRange.value = '30d';
    state.expiresAt.value = null;
    state.createdAt.value = new Date();
    state.rights.value = [];
    state.notificationLevel.value = null;
    state.defaultAnalyticsPanelHashId.value = null;
    state.hasDevices.value = false;
    state.hasReports.value = true;
    state.hiddenMapLayerKeys.value = [];
    state.pinGroupsAsDotsAtLowZoomLevels.value = false;
    state.filteredOpacityLevel.value = 0.3;
  };

  const queryReports = async (): Promise<void> => {
    if (state.hashId.value === null) {
      return;
    }

    const request = sdk.routes.report.find({
      query: {
        rowsPerPage: 1,
      },
    });

    let hasReports = true;
    try {
      const { rows } = await request.response;
      hasReports = rows.length > 0;
    } catch (e) {
      return;
    }

    state.hasReports.value = hasReports;
  };

  const queryDevices = async (): Promise<void> => {
    if (state.hashId.value === null) {
      return;
    }

    const request = sdk.routes.device.find({
      query: {
        rowsPerPage: 1,
      },
    });

    let hasDevices = false;
    try {
      const { rows } = await request.response;
      hasDevices = rows.length > 0;
    } catch (e) {
      return;
    }

    state.hasDevices.value = hasDevices;
  };

  const set = async (data: {
    environment: Environment;
    environmentRights: EnvironmentRights;
    userEnvironmentSettings: UserEnvironmentSettings;
  }): Promise<void> => {
    info('Calling useEnvironment.set() setting the environment state');
    const { environment, environmentRights, userEnvironmentSettings } = data;
    const localStorageMapLayersAll: LocalStorageMapLayers =
      getLocalStorageItem(mapLayersStorageKey, true) ?? [];
    const localStorageMapLayers: LocalStorageMapLayer | undefined = localStorageMapLayersAll.find(
      (val) => val.environmentHashId === environment.hashId,
    );

    const hiddenMapLayerKeys =
      localStorageMapLayers === undefined || localStorageMapLayers.mapLayers.length === 0
        ? []
        : localStorageMapLayers.mapLayers;

    const pinGroupsAsDotsAtLowZoomLevelsLS: any =
      getLocalStorageItem(pinGroupsAsDotsAtLowZoomLevelsLocalStorageKey, false) ?? false;

    // convert to boolean
    const pinGroupsAsDotsAtLowZoomLevels = !!(
      pinGroupsAsDotsAtLowZoomLevelsLS === true || pinGroupsAsDotsAtLowZoomLevelsLS === 'true'
    );

    const filteredOpacityLevelLS = getLocalStorageItem(filteredOpacityLevelKey, false);
    let filteredOpacityLevel = 0.3;
    if (filteredOpacityLevelLS !== null) {
      try {
        filteredOpacityLevel = parseFloat(filteredOpacityLevelLS);
      } catch (e) {
        // ignore
      }
      if (filteredOpacityLevel > 1 || filteredOpacityLevel < 0) {
        filteredOpacityLevel = 0.3;
      }
    }

    loaded({
      environment,
      environmentRights,
      userEnvironmentSettings,
      hiddenMapLayerKeys,
      pinGroupsAsDotsAtLowZoomLevels,
      filteredOpacityLevel,
    });
    setLastEnvironmentHashId(environment.hashId);
    await queryReports();
    await queryDevices();
  };

  const load = async (hashId: string, userLocale: string): Promise<void> => {
    info('Calling useEnvironment.load() filling the environment state');
    cleared();
    loading(hashId);

    const { response } = sdk.routes.environment.get({ params: { hashId } });
    const { environment, environmentRights, userEnvironmentSettings } = await response.catch(
      (err) => {
        emit('commsError', err);
        throw err;
      },
    );

    await set({
      environment,
      environmentRights,
      userEnvironmentSettings,
    });

    emit('info', t[userLocale].switchedTo(environment.name), undefined, 1000);
  };

  const clear = () => {
    cleared();
    leftPanelClear();
  };

  const setHiddenMapLayerKeys = (hiddenMapLayerKeys: Array<string>): void => {
    if (state.hashId.value === null) {
      return;
    }
    const data = {
      environmentHashId: state.hashId.value,
      mapLayers: hiddenMapLayerKeys,
    };

    let currentData: LocalStorageMapLayers = getLocalStorageItem(mapLayersStorageKey, true) ?? [];
    // if map layers keys are empty array, then delete the map layer from localStorage
    if (data.mapLayers.length === 0) {
      currentData = currentData.filter((val) => val.environmentHashId !== data.environmentHashId);
    } else {
      const currentEnv = currentData.find(
        (val) => val.environmentHashId === data.environmentHashId,
      );
      if (currentEnv !== undefined) {
        currentEnv.mapLayers = data.mapLayers;
      } else {
        currentData.push(data);
        if (currentData.length > 5) {
          currentData.shift();
        }
      }
    }

    setLocalStorageItem(mapLayersStorageKey, currentData, true);
    state.hiddenMapLayerKeys.value = hiddenMapLayerKeys;
  };

  const setPinGroupsAsDotsAtLowZoomLevels = (pinGroupsAsDotsAtLowZoomLevels: boolean): void => {
    setLocalStorageItem(
      pinGroupsAsDotsAtLowZoomLevelsLocalStorageKey,
      pinGroupsAsDotsAtLowZoomLevels,
      false,
    );

    state.pinGroupsAsDotsAtLowZoomLevels.value = pinGroupsAsDotsAtLowZoomLevels;
  };

  const setFilteredOpacityLevel = (opacity: number): void => {
    Invariant.assert(
      opacity <= 1 && opacity >= 0,
      `Filtered features custom opacity level is not between 0 and 1: ${opacity}`,
    );

    setLocalStorageItem(filteredOpacityLevelKey, opacity, false);

    state.filteredOpacityLevel.value = opacity;
  };

  const hasRight = (right: string): boolean => {
    if (state.rights.value.includes('ADMIN')) {
      return true;
    }

    return state.rights.value.includes(right);
  };

  const activeMapLayers = computed(() =>
    state.mapLayers.value.filter((val) => !state.hiddenMapLayerKeys.value.includes(val.key)),
  );

  const internalMapLayers = computed(() =>
    state.mapLayers.value.filter(
      (layer) => layer.namedStyle === undefined && layer.style === undefined,
    ),
  );

  const activeInternalMapLayers = computed(() =>
    state.mapLayers.value.filter(
      (layer) =>
        layer.namedStyle === undefined &&
        layer.style === undefined &&
        !state.hiddenMapLayerKeys.value.includes(layer.key),
    ),
  );

  return {
    // state properties
    defaultAnalyticsPanelHashId: state.defaultAnalyticsPanelHashId,
    hashId: state.hashId,
    name: state.name,
    notificationLevel: state.notificationLevel,
    fieldConfigurations: state.fieldConfigurations,
    expiresAt: state.expiresAt,
    boundingBox: state.boundingBox,
    pinGroupsAsDotsAtLowZoomLevels: state.pinGroupsAsDotsAtLowZoomLevels,
    mapLayers: state.mapLayers,
    locale: state.locale,
    measurementsExpirationDays: state.measurementsExpirationDays,
    measurementsRetentionPolicyRange: state.measurementsRetentionPolicyRange,
    enforceTwoFactorAuthentication: state.enforceTwoFactorAuthentication,
    allowAutoCloseConnectivityIssues: state.allowAutoCloseConnectivityIssues,
    allowAutoCloseThresholdIssues: state.allowAutoCloseThresholdIssues,
    issueAutoClosingTriggerAmount: state.issueAutoClosingTriggerAmount,
    issueAutoClosingTriggerUnitType: state.issueAutoClosingTriggerUnitType,
    defaultGraphRange: state.defaultGraphRange,
    hasDevices: state.hasDevices,
    rights: state.rights,
    hasReports: state.hasReports,
    logo: state.logo,
    theme: state.theme,
    filteredOpacityLevel: state.filteredOpacityLevel,

    // computed properties
    activeMapLayers,
    internalMapLayers,
    activeInternalMapLayers,

    // getters
    hasRight,
    themeColor: (color: keyof ColorKeys) => themeManager.color(color),
    themeHighlightColor: (color: keyof ColorKeys) => themeManager.highlight(color),

    // methods
    update,
    load,
    set,
    clear,
    queryReports,
    queryDevices,
    setHiddenMapLayerKeys,
    setPinGroupsAsDotsAtLowZoomLevels,
    setFilteredOpacityLevel,
  };
}
