import { isNavigationFailure, type NavigationFailure, type RouteLocationRaw } from 'vue-router';
import router from '.';

/**
 * @deprecated Use the useRoute composable instead
 */
async function pushRoute(location: RouteLocationRaw): Promise<boolean> {
  // console.trace(`navigating to ${JSON.stringify(location)}`);
  /**
   * @see https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
   * router will also reject promise if path is redirected.
   * which is stupid.
   */
  try {
    await router.push(location).then((failure: void | NavigationFailure | undefined) => {
      if (failure instanceof Error) {
        console.trace(failure.message);
        return false;
      }
      return true;
    });
  } catch (e) {
    if (isNavigationFailure(e)) {
      // when updating to vue-router 4, navigation failures will not be thrown, but resolved,
      // see https://github.com/vuejs/rfcs/blob/master/active-rfcs/0033-router-navigation-failures.md
      // and https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
      // after update: I kept the try/catch because throwing an error in the `next` function
      // still thows, so we need to catch and log it (and throw if not known).
      console.trace(e.message);
      return false;
    }
    console.log(`Failed to navigate to ${JSON.stringify(location)}. Error will now be thrown`);
    throw e;
  }
  return true;
}

export default pushRoute;
