import type { models } from '@withthegrid/amp-sdk';
import DateTimeWrapper from '@web-ui-root/helpers/date-utils/date-time-wrapper';
import { useUser } from '@web-ui-root/composables/user';
import {
  ONE_DAY_IN_S,
  ONE_HOUR_IN_S,
  ONE_MINUTE_IN_S,
} from '@web-ui-root/helpers/constants/constants';
import {
  durationTypes,
  type DurationType,
  type Unit,
} from '@web-ui-root/composables/issue-trigger';
import type { ComposerTranslation } from 'vue-i18n';

const t = {
  en: {
    required: 'Cannot be empty',
    numeric: 'Not a number',
    integer: 'Not an integer',
    string: 'Not a string',
    date: 'Not an ISO 8601 date',
    zeroPrecision: 'No precision allowed',
    singleDigitPrecision: 'Only one digit precision is allowed',
    doubleDigitPrecision: 'Only two digit precision is allowed',
    dateWithFormat: (format: string) => `Must be "${format}"`,
    stringTooLong: (maxLength: number) => `Must be at most ${maxLength} characters long`,
    stringTooShort: (minLength: number) => `Must be at least ${minLength} characters long`,
    stringNotValidEmail: 'Incorrect email',
    stringNotValidOneTimePassword: 'Incorrect code',
    stringNotValidAlphaCamelCase: 'Key should be alphanumeric starting with a lowercase letter',
    array: {
      min: (min: number) => `Must contain at least ${min} items`,
      max: (max: number) => `Must contain at most ${max} items`,
      range: (min: number, max: number) => `Must contain between ${min} and ${max} items`,
    },
    lowerbound: (bound: number) => `Value must be greater than or equal to ${bound}`,
  },
  nl: {
    required: 'Kan niet leeg zijn',
    numeric: 'Geen getal',
    integer: 'Geen geheel getal',
    string: 'Geen string',
    date: 'Geen ISO 8601 datum',
    zeroPrecision: 'Geen precisie toegestaan',
    singleDigitPrecision: 'Slechts één cijfer precisie is toegestaan',
    doubleDigitPrecision: 'Slechts twee cijfer precisie is toegestaan',
    dateWithFormat: (format: string) => `Moet voldoen aan "${format}"`,
    stringTooLong: (maxLength: number) => `Mag maximaal ${maxLength} karakters lang zijn`,
    stringTooShort: (minLength: number) => `Moet minstens ${minLength} karakters lang zijn`,
    stringNotValidEmail: 'Email onjuist',
    stringNotValidOneTimePassword: 'Foute code',
    stringNotValidAlphaCamelCase: 'Key dient alfanumeriek te zijn, beginnend met een kleine letter',
    array: {
      min: (min: number) => `Moet minimal ${min} items bevatten`,
      max: (max: number) => `Mag maximaal ${max} items bevatten`,
      range: (min: number, max: number) => `Moet tussen de ${min} en ${max} items bevatten`,
    },
    lowerbound: (bound: number) => `Waarde moet groter of gelijk aan ${bound} zijn`,
  },
};

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const oneTimePasswordRegex = /[0-9]{6}/;
const alphaCamelCaseRegex = /^[a-z][a-zA-Z\d]*$/;

function defined(v: unknown): true | string {
  const { locale } = useUser();

  if (v === undefined || v === null) {
    return t[locale.value].required;
  }
  return true;
}

function definedExcludingEmptyString(v: unknown): true | string {
  const { locale } = useUser();

  if (v === undefined || v === null || v === '') {
    return t[locale.value].required;
  }
  return true;
}

function nonEmptyString(v: unknown): true | string {
  const { locale } = useUser();

  if (typeof v !== 'string' || v === '') {
    return t[locale.value].required;
  }
  return true;
}

function stringNotTooLong(maxLength: number): (v: unknown) => true | string {
  return (v: unknown) => {
    const { locale } = useUser();

    if (typeof v === 'string' && v.length > maxLength) {
      return t[locale.value].stringTooLong(maxLength);
    }
    return true;
  };
}

function stringNotTooShort(minLength: number): (v: unknown) => true | string {
  return (v: unknown) => {
    const { locale } = useUser();

    if (typeof v === 'string' && v.length < minLength) {
      return t[locale.value].stringTooShort(minLength);
    }
    return true;
  };
}

function stringIsValidEmail(v: string): true | string {
  const { locale } = useUser();

  if (v.match(emailRegex) === null) {
    return t[locale.value].stringNotValidEmail;
  }

  return true;
}

function stringIsValidOneTimePassword(v: string): true | string {
  const { locale } = useUser();

  if (v.match(oneTimePasswordRegex) === null) {
    return t[locale.value].stringNotValidOneTimePassword;
  }

  return true;
}

function stringIsAlphaCamelCase(v: string): true | string {
  const { locale } = useUser();

  if (v.match(alphaCamelCaseRegex) === null) {
    return t[locale.value].stringNotValidAlphaCamelCase;
  }

  return true;
}

// null and '' are also considered numeric by this code
// if we change that, also update edit-field.vue, which relies upon that
function numeric(v: unknown): true | string {
  const { locale } = useUser();

  if (Number.isNaN(Number(v))) {
    return t[locale.value].numeric;
  }
  return true;
}

function integer(v: unknown): true | string {
  const { locale } = useUser();

  const number = Number(v);
  if (Number.isNaN(number)) {
    return t[locale.value].numeric;
  }

  const int = parseInt(String(v), 10);
  if (int !== number) {
    return t[locale.value].integer;
  }
  return true;
}

function validDurationPrecision(v: unknown, durationType: Unit | undefined): true | string {
  const { locale } = useUser();

  const number = Number(v);
  if (Number.isNaN(number)) {
    return t[locale.value].numeric;
  }
  const str = number.toString();
  const dot = str.indexOf('.');
  const digitsAfterDot = dot !== -1 ? str.length - dot - 1 : 0;
  if (digitsAfterDot > 0 && durationType === 'second') {
    return t[locale.value].zeroPrecision;
  }
  if (durationType === 'minute' && digitsAfterDot > 1) {
    return t[locale.value].singleDigitPrecision;
  }
  if (digitsAfterDot > 2) {
    return t[locale.value].doubleDigitPrecision;
  }
  return true;
}

function getUnitFromDurationType(durationType: DurationType | Unit | undefined): Unit {
  // can happen when the view failed to load (e.g. 429) (see #1708)
  if (durationType === undefined) {
    return durationTypes[1].text; // minutes
  }

  // on load, the durationType is { text: string, value: string }
  // when the v-model updates, it's just the value
  return typeof durationType !== 'string' && 'value' in durationType
    ? durationType.text
    : durationType;
}

function issueDelayTimeUpperBound(
  translation: ComposerTranslation<
    Record<models.locale.Locale, Record<string, string>>,
    models.locale.Locale
  >,
  translationKey: string,
  durationType: Unit | undefined,
  val: number = 0,
): true | string {
  const maxPostgresInt = 2147483647;
  const bounds = {
    second: maxPostgresInt,
    minute: Math.floor(maxPostgresInt / ONE_MINUTE_IN_S),
    hour: Math.floor(maxPostgresInt / ONE_HOUR_IN_S),
    day: Math.floor(maxPostgresInt / ONE_DAY_IN_S),
  };
  const unit = getUnitFromDurationType(durationType);
  return val > bounds[unit]
    ? translation(`${translationKey}`, [bounds[unit], translation(unit, bounds[unit])])
    : true;
}

function date(v: unknown): true | string {
  const { locale } = useUser();

  if (typeof v !== 'string') {
    return t[locale.value].string;
  }

  const m = DateTimeWrapper.fromISO(v);
  if (!m.isValid) {
    return t[locale.value].date;
  }
  return true;
}

function dateWithFormat(format: string): (v: unknown) => true | string {
  return (v: unknown) => {
    const { locale } = useUser();

    if (typeof v !== 'string') {
      return t[locale.value].string;
    }

    const d = DateTimeWrapper.fromFormat(v, format);
    if (!d.isValid) {
      return t[locale.value].dateWithFormat(format);
    }
    return true;
  };
}

function regex(r: string | RegExp, errorMessage: string): true | ((v: string) => true | string) {
  let regexp: RegExp | undefined;
  try {
    regexp = new RegExp(r);
  } catch (e) {
    // invalid regex
    return true;
  }

  return (v: string) => regexp?.test(v) || errorMessage;
}

function lowerbound(l: number, errorMessage?: string): (v: unknown) => true | string {
  const { locale } = useUser();

  return (v: unknown) =>
    v === undefined ||
    v === null ||
    v === '' ||
    Number(v) >= l ||
    (errorMessage ?? t[locale.value].lowerbound(l));
}

function upperbound(u: number, errorMessage: string): (v: unknown) => true | string {
  return (v: unknown) =>
    v === undefined || v === null || v === '' || Number(v) <= u || errorMessage;
}

function arrayMinimumLength(min: number): (v: unknown[]) => true | string {
  return (v: unknown[]) => {
    const { locale } = useUser();

    if (Array.isArray(v) && v.length < min) {
      return t[locale.value].array.min(min);
    }
    return true;
  };
}

function arrayMaximumLength(max: number): (v: unknown[]) => true | string {
  return (v: unknown[]) => {
    const { locale } = useUser();

    if (Array.isArray(v) && v.length > max) {
      return t[locale.value].array.max(max);
    }
    return true;
  };
}

function arrayRangeLength(min: number, max: number): (v: unknown[]) => true | string {
  return (v: unknown[]) => {
    const { locale } = useUser();

    if (Array.isArray(v) && (v.length < min || v.length > max)) {
      return t[locale.value].array.range(min, max);
    }
    return true;
  };
}

export {
  arrayMaximumLength,
  arrayMinimumLength,
  arrayRangeLength,
  date,
  dateWithFormat,
  defined,
  definedExcludingEmptyString,
  getUnitFromDurationType,
  integer,
  issueDelayTimeUpperBound,
  lowerbound,
  nonEmptyString,
  numeric,
  regex,
  stringIsAlphaCamelCase,
  stringIsValidEmail,
  stringIsValidOneTimePassword,
  stringNotTooLong,
  stringNotTooShort,
  upperbound,
  validDurationPrecision,
};
