<template>
  <w-dialog
    :hide-actions="true"
    :title="t('title')"
    :model-value="modelValue"
    :scrollable="false"
    :state="dialogState"
    @update:model-value="(val: boolean) => emit('update:modelValue', val)"
  >
    <slot>
      <p class="mb-4">
        {{ text }}
      </p>
    </slot>
    <br />
    <p
      v-if="!requiresConfirmation"
      class="mb-2"
    >
      {{ t('deleteInstruction') }}
    </p>
    <i18n-t
      v-if="requiresConfirmation"
      :i18n="i18n as Composer"
      keypath="confirmationInstruction"
      tag="p"
    >
      <strong>{{ textToConfirm }}</strong>
    </i18n-t>
    <v-text-field
      v-if="requiresConfirmation"
      v-model="confirmationInput"
      :placeholder="textToConfirm"
      autofocus
      single-line
      variant="underlined"
      color="primary"
      @keydown.enter="onEnter"
    />

    <div class="d-flex">
      <v-spacer />
      <v-btn
        v-t="'cancel'"
        variant="outlined"
        @click="() => emit('update:modelValue', false)"
      />
      <v-btn
        class="ml-2"
        color="error"
        :disabled="!confirmed"
        :loading="deleting"
        @click="() => emit('confirm')"
      >
        <span v-t="'delete'" />
      </v-btn>
    </div>
  </w-dialog>
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import { useUUID } from '@web-ui-root/composables/uuid';
import { useI18n, type Composer } from 'vue-i18n';
import WDialog from './w-dialog.vue';

type Props = {
  deleting?: boolean;
  modelValue?: boolean;
  text: string;
  textToConfirm?: string;
};

const props = withDefaults(defineProps<Props>(), {
  deleting: false,
  modelValue: false,
  text: undefined,
  textToConfirm: undefined,
});

const i18n = useI18n({
  messages: {
    en: {
      title: 'Are you sure?',
      confirmationInstruction: 'Please type {0} and press delete to confirm.',
      deleteInstruction: 'Please press delete to confirm.',
      delete: 'Delete',
      cancel: 'Cancel',
    },
    nl: {
      title: 'Weet je het zeker?',
      confirmationInstruction: 'Voer hieronder {0} in en klik op verwijderen om te bevestigen.',
      deleteInstruction: 'Klik op verwijderen om te bevestigen.',
      delete: 'Verwijderen',
      cancel: 'Annuleer',
    },
  },
});
const { t } = i18n;

const emit = defineEmits<{
  'update:modelValue': [value: boolean];
  confirm: [];
}>();

const { uuid } = useUUID();
uuid.value.toString();
const confirmationInput = ref('');

const requiresConfirmation = computed(() => props.textToConfirm !== undefined);

const confirmed = computed(() => {
  if (!requiresConfirmation.value) {
    return true;
  }
  if (typeof props.textToConfirm !== 'string') {
    return false;
  }
  return props.textToConfirm.trim().toLowerCase() === confirmationInput.value.trim().toLowerCase();
});

const dialogState = computed(() => (props.deleting ? 'saving' : 'pristine'));

watch(
  () => props.modelValue,
  (newValue) => {
    if (newValue) {
      confirmationInput.value = '';
    }
  },
);

function onEnter() {
  if (!confirmed.value) {
    return;
  }
  emit('confirm');
}
</script>
