<template>
  <div class="time-picker-wrapper">
    <v-icon class="mr-2"> mdi-clock-time-four-outline </v-icon>
    <v-text-field
      :model-value="time"
      :rules="rules"
      :placeholder="placeholder"
      :hint="hint"
      :disabled="disabled"
      variant="underlined"
      color="primary"
      @update:model-value="update"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, type Ref, computed, watch, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import DateTimeWrapper from '@web-ui-root/helpers/date-utils/date-time-wrapper';
import { useUUID } from '@web-ui-root/composables/uuid';
import { nonEmptyString } from '@web-ui-root/helpers/rules';
import { useTimePicker, type TimeKeys } from './time-picker';

const { formatMap } = useTimePicker();

const { uuid } = useUUID();
uuid.value.toString();

const { t } = useI18n({
  messages: {
    en: {
      error: 'Please, enter a valid time',
    },
    nl: {
      error: 'Voer een geldige tijd in',
    },
  },
});

type Props = {
  modelValue?: string;
  granularity: TimeKeys;
  disabled?: boolean;
  allowEmpty?: boolean;
  hideIcon?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  modelValue: undefined,
  granularity: 'minutes',
  disbaled: false,
  allowEmpty: true,
  hideIcon: false,
});

const emit = defineEmits<{
  'update:modelValue': [time: string];
}>();

const time: Ref<string | undefined> = ref(props.modelValue);

const placeholder = computed(() => formatMap[props.granularity].placeholder);

const hint = computed(() => formatMap[props.granularity].hint);

const isTimeValid = computed(() => {
  const format = formatMap[props.granularity].format;
  if (time.value === undefined || time.value === '') {
    return props.allowEmpty;
  }
  return DateTimeWrapper.fromFormat(time.value, format).isValid;
});

const rules = computed(() => {
  const shadowRules = [isTimeValidRule];

  if (!props.allowEmpty) {
    shadowRules.push(nonEmptyString);
  }

  return shadowRules;
});

watch(
  () => props.modelValue,
  (val, oldVal) => {
    if (val !== oldVal) {
      time.value = val;
      update();
    }
  },
);

onMounted(() => {
  init();
});

function init() {
  if (time.value === undefined) {
    setToDefault();
    return;
  }

  const dt = DateTimeWrapper.fromISO(time.value);

  if (dt.isValid) {
    if (dt.millisecond !== 0) {
      time.value = dt.toFormat(formatMap.milliseconds.format);
    } else if (dt.second !== 0) {
      time.value = dt.toFormat(formatMap.seconds.format);
    } else if (dt.hour !== 0 || dt.minute !== 0 || !props.allowEmpty) {
      time.value = dt.toFormat(formatMap.minutes.format);
    } else {
      setToDefault();
    }
  } else {
    setToDefault();
  }
}

function setToDefault() {
  time.value = props.allowEmpty
    ? ''
    : DateTimeWrapper.now().toFormat(formatMap[props.granularity].format);

  update();
}

function isTimeValidRule(val: string) {
  if (props.allowEmpty && (val === '' || val === undefined)) {
    return true;
  }

  return DateTimeWrapper.fromFormat(val, formatMap[props.granularity].format).isValid
    ? true
    : t('error');
}

function emitIfValid() {
  if (isTimeValid.value && time.value !== undefined) {
    emit('update:modelValue', time.value);
  }
}

function update(shadowTime?: string) {
  if (shadowTime !== undefined) {
    time.value = shadowTime;
  }

  const appendColumnSeparator =
    (['minutes', 'seconds', 'milliseconds'].includes(props.granularity) &&
      time.value?.length === 2) ||
    (['seconds', 'milliseconds'].includes(props.granularity) && time.value?.length === 5);
  const appendDotSeparator = props.granularity === 'milliseconds' && time.value?.length === 8;

  if (appendColumnSeparator) {
    time.value += ':';
  } else if (appendDotSeparator) {
    time.value += '.';
  }

  emitIfValid();
}
</script>

<style scoped>
.time-picker-wrapper {
  display: flex;
  align-items: center;
}
</style>
