import {
  watch,
  type UnwrapRef,
  type ComputedRef,
  type Ref,
  ref,
  computed,
  onMounted,
  onBeforeUnmount,
  inject,
} from 'vue';
import { useI18n, type UseI18nOptions } from 'vue-i18n';
import { TableController, type TableQuery, errors as sdkErrors } from '@withthegrid/amp-sdk';
import { useBusHandler } from '@web-ui-root/composables/bus-handler';
import { splitSearchQuery, buildSearchQuery } from '@web-ui-root/helpers/search-query-splitter';
import router from '@web-ui-root/router';
import { isDeepEqual } from '@web-ui-root/helpers/object-helper';
import { type ServerTableRow, type Filter, type SortByOption } from './types';

const ROWS_PER_PAGE = 30;

type Option<T> = null | T;
type Maybe<T> = undefined | T;
type TextBool = 'true' | 'false';

type ControllerRequiredParams = (params: TableQuery) => TableController<unknown>;
type ControllerOptionalParams = (params?: TableQuery) => TableController<unknown>;
export type Controller = ControllerRequiredParams | ControllerOptionalParams;
export type DefaultMapper<T, D> = (row: D) => ServerTableRow & T;

type ServerTableModel<T extends ServerTableRow = ServerTableRow> = {
  loading: Ref<boolean>;
  rows: Ref<Array<T>>;
  pagesAcquired: Ref<Option<number>>;
  page: Ref<number>;
  rowsPerPage: Ref<number>;
  lastPage: Ref<Option<number>>;
  // might want to split this in another composable
  searchInput: Ref<string>;
  internalSearch: Ref<string>;
  sortBy: Ref<string>; // probably wanna use keyes here, and also move to a sort composable
  descending: Ref<boolean>;
  selectedFilterKeys: Ref<Record<string, Array<string>>>; // todo: type

  defaultSortBy: ComputedRef<Maybe<string>>; // todo: type
  defaultDescending: ComputedRef<Maybe<boolean>>;
  defaultDescendingAsText: ComputedRef<Maybe<string>>;
  // deal with dialogState that was present here before and should not have been
  // dialogState: ComputedRef<DialogState>; // 'loading' | 'pristine'
  paginationLabel: ComputedRef<string>;

  messages: UseI18nOptions['messages'];

  refresh: () => Promise<void>;
  initializeWithQuery: (forceRefresh: boolean, query?: TableQuery) => Promise<void>;
  initialize: (
    forceRefresh: boolean,
    sortBy: UnwrapRef<ServerTableModel['sortBy']>,
    search: string,
    descending: boolean,
  ) => Promise<void>;
  changePage: (next?: boolean) => Promise<void>;
  updateSearchToFilters: (filterType: string, selected: Array<string>) => void;
  updateFiltersToSearch: (search: string) => void;
  setAdditionalParams: (params: TableQuery) => void;

  changeController: (controller: Controller) => void;
};

type Query = {
  search?: string;
  sortBy?: string;
  descending?: boolean;
  rowsPerPage?: number;
};

export type ServerTableProps<
  C extends Controller,
  M extends DefaultMapper<unknown, ReturnType<C>['rows'][0]>,
> = {
  tableController: C;
  responseMapper: M;
  rowKey: string;
  addable: boolean;
  clickable: boolean;
  sortByOptions: Array<SortByOption>;
  additionalParams: TableQuery;
  objectsName: string;
  filters: Array<Filter>;
  query: TableQuery;
  defaultSearch: string;
  disableRouteUpdating: boolean;
  onPageChanged: (page: number) => void;
};

function boolText<T extends boolean>(bool: T): TextBool {
  return bool ? 'true' : 'false';
}

export function useServerTable<
  C extends Controller,
  M extends DefaultMapper<unknown, ReturnType<C>['rows'][0]>,
>(props: ServerTableProps<C, M>): ServerTableModel<ReturnType<M>> {
  const controller = ref<Controller>(props.tableController);
  const pushRoute: typeof router.push = inject('pushRoute')!;

  const messages: UseI18nOptions['messages'] = {
    en: {
      searchPlaceholder: 'Search in {0}',
      selectAll: 'Select all ({0})',
      unselectAll: 'Unselect all ({0})',
      noFound: 'No {0} found.',
      close: 'Close',
      add: 'new',
      helpTitle: 'Search syntax',
      helpIntro: 'The following syntax can be used when composing a search string:',
      helpSyntax: {
        word: 'Only show device types where the name contains the provided string.',
      },
      previous: 'Previous',
      next: 'Next',
      refresh: 'Refresh',
      to: 'to',
      tooltipInformation:
        "Put words inside quotes to search for an exact match, for example: 'Current AC'.",
    },
    nl: {
      searchPlaceholder: 'Zoek op {0}',
      selectAll: 'Selecteer alle ({0})',
      unselectAll: 'Deselecteer alle ({0})',
      noFound: 'Geen {0} gevonden.',
      close: 'Sluit',
      add: 'nieuw',
      helpTitle: 'Zoeksyntax',
      helpIntro: 'De volgende syntax kan worden gebruikt voor het opstellen van een zoekopdracht:',
      helpSyntax: {
        word: 'Alleen device-soorten waarvan de naam de opgegeven tekenreeks bevat worden getoond.',
      },
      previous: 'Vorige',
      next: 'Volgende',
      refresh: 'Verversen',
      to: 'tot',
      tooltipInformation:
        "Plaats woorden tussen aanhalingstekens om naar een exacte overeenkomst te zoeken, bijvoorbeeld: 'Current AC'.",
    },
  };
  const { emit: busEmit } = useBusHandler();
  const { t } = useI18n({ messages });

  const loading = ref(false);
  const rows: Ref<Array<ReturnType<M>>> = ref([]);
  const pagesAcquired = ref(0);
  const page = ref(1);
  const rowsPerPage = ref(10);
  const lastPage: Ref<Option<number>> = ref(null);
  const searchInput = ref('');
  const sortBy = ref('hashId');
  const descending = ref(false);
  const selectedFilterKeys: Ref<Record<string, Array<string>>> = ref({});

  const internalSearch = ref('');
  let tableControllerInstance: null | TableController<unknown> = null;

  const defaultSortBy = computed(() => props.sortByOptions.at(0)?.value);
  const defaultDescending = computed(() => props.sortByOptions.at(0)?.descending);
  const defaultDescendingAsText = computed(() =>
    defaultDescending.value === true ? 'true' : 'false',
  );
  const paginationLabel = computed(() => {
    if (rows.value.length === 0 || loading.value) {
      return '';
    }
    const start = page.value * rowsPerPage.value;
    const end = start + rows.value.length;

    return `${start + 1} ${t('to')} ${end}`;
  });

  function updateFiltersToSearch(search: string): void {
    const searchTokens = splitSearchQuery(search);

    for (let i = 0, len = props.filters.length; i < len; i += 1) {
      const filter = props.filters.at(i)!;
      if (filter.isDate === true) {
        if (filter.keyStartDate !== undefined) {
          selectedFilterKeys.value[filter.keyStartDate] = [];
        }
        if (filter.keyEndDate !== undefined) {
          selectedFilterKeys.value[filter.keyEndDate] = [];
        }
      } else {
        selectedFilterKeys.value[filter.key] = [];
      }
    }

    for (let i = 0, len = searchTokens.length; i < len; i += 1) {
      const [key, val] = searchTokens.at(i)!;
      if (key !== null && selectedFilterKeys.value[key] !== undefined) {
        selectedFilterKeys.value[key].push(val);
      }
    }
  }

  async function changePage(next = true): Promise<void> {
    const nextPage = page.value + 1;
    if (
      next &&
      nextPage === tableControllerInstance?.pagesAcquired &&
      (lastPage.value === null || lastPage.value >= nextPage)
    ) {
      loading.value = true;
      try {
        pagesAcquired.value = await tableControllerInstance.acquire();
      } catch (e) {
        if (!(e instanceof sdkErrors.CommsCanceled)) {
          busEmit('commsError', e);
          loading.value = false;
        }
        return;
      }

      loading.value = false;

      if (tableControllerInstance.endReached) {
        lastPage.value = tableControllerInstance.pagesAcquired - 1;
      }
    }

    if (tableControllerInstance !== null) {
      page.value += next ? 1 : -1;

      rows.value = tableControllerInstance
        .get(page.value)
        .map((row) => props.responseMapper(row) as ReturnType<M>);

      props.onPageChanged(page.value);
    }
  }

  async function initialize(
    forceRefresh: boolean,
    providedSortBy: string,
    search: string,
    providedDescending: boolean,
  ): Promise<void> {
    const descendingAsText = boolText(providedDescending);
    rowsPerPage.value = props.additionalParams.rowsPerPage ?? ROWS_PER_PAGE;
    internalSearch.value = search;

    if (
      internalSearch.value !== (props.query.search ?? props.defaultSearch) ||
      providedSortBy !== (props.query.sortBy ?? defaultSortBy.value) ||
      providedDescending !== (props.query.descending ?? defaultDescending.value)
    ) {
      if (!props.disableRouteUpdating) {
        await pushRoute({
          query: {
            search,
            sortBy: providedSortBy,
            descending: descendingAsText,
          },
        });

        props.query = {
          search,
          sortBy: providedSortBy,
          descending: descendingAsText.toLowerCase() === 'true',
        };
      }
    }

    updateFiltersToSearch(internalSearch.value);
    searchInput.value = internalSearch.value;

    if (
      tableControllerInstance !== null &&
      providedSortBy === tableControllerInstance.parameters?.sortBy &&
      internalSearch.value === tableControllerInstance.parameters?.search &&
      rowsPerPage.value === tableControllerInstance.parameters?.rowsPerPage &&
      providedDescending === tableControllerInstance.parameters?.descending &&
      !forceRefresh
    ) {
      return;
    }

    rows.value = [];
    page.value = -1;
    lastPage.value = null;
    sortBy.value = providedSortBy;
    descending.value = providedDescending;
    pagesAcquired.value = 0;

    const params: Query = {
      sortBy: providedSortBy,
      descending: providedDescending,
      rowsPerPage: rowsPerPage.value,
      search: internalSearch.value,
      ...props.additionalParams,
    };

    if (tableControllerInstance !== null) {
      tableControllerInstance.destroy();
    }

    tableControllerInstance = controller.value(params);

    await changePage();
  }

  async function updateSearchToFilters(filterType: string, selected: Array<string>): Promise<void> {
    selectedFilterKeys.value[filterType] = selected;

    const currentSearchTokens = splitSearchQuery(internalSearch.value ?? '').filter(
      (token) => token.at(0)! !== filterType,
    );

    for (let i = 0, len = selected.length; i < len; i += 1) {
      const s = selected.at(i)!;
      currentSearchTokens.push([filterType, s]);
    }

    const search = buildSearchQuery(currentSearchTokens);

    await initialize(false, sortBy.value, search, descending.value);
  }

  function changeController(newController: Controller): void {
    controller.value = newController;
  }

  async function initializeWithQuery(forceRefresh: boolean, query: TableQuery = {}): Promise<void> {
    const sortByOption = props.sortByOptions.findIndex((s) => s.value === query.sortBy);
    const computedSortBy =
      sortByOption !== -1
        ? (query.sortBy ?? sortBy.value) // todo: (hmmm) not sure about the coalescing here
        : props.sortByOptions.at(0)!.value!; // todo: (hmmm) lots of defaults
    const computedDescending =
      sortByOption !== -1
        ? (query.descending ?? defaultDescendingAsText.value) === 'true'
        : props.sortByOptions.at(0)!.descending; // todo: (hmmm) lots of defaults

    await initialize(
      forceRefresh,
      computedSortBy,
      query.search ?? props.defaultSearch,
      computedDescending,
    );
  }

  watch(
    () => props.query,
    async (val, oldVal) => {
      if (!isDeepEqual(val, oldVal)) {
        await initializeWithQuery(false, val);
      }
    },
  );

  onMounted(async () => {
    await initializeWithQuery(true, props.query);
  });

  onBeforeUnmount(() => {
    if (tableControllerInstance !== null) {
      tableControllerInstance.destroy();
    }
  });

  async function refresh(): Promise<void> {
    await initialize(true, sortBy.value, internalSearch.value, descending.value);
  }

  function setAdditionalParams(params: TableQuery): void {
    props.additionalParams = params;
  }

  return {
    messages,

    loading,
    rows,
    pagesAcquired,
    page,
    rowsPerPage,
    lastPage,
    searchInput,
    internalSearch,
    sortBy,
    descending,
    selectedFilterKeys,

    defaultSortBy,
    defaultDescending,
    defaultDescendingAsText,
    paginationLabel,

    refresh,
    changePage,
    initialize,
    initializeWithQuery,
    updateSearchToFilters,
    updateFiltersToSearch,
    changeController,
    setAdditionalParams,
  };
}
