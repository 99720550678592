<template>
  <div style="display: flex; flex-flow: column; height: 100%">
    <v-app-bar
      v-if="!mdAndUp && !noAppBar"
      density="compact"
      style="flex: 0 0 auto; z-index: 1"
      color="white"
    >
      <v-app-bar-nav-icon @click="toolbar = true" />
      <v-toolbar-title>
        {{ title }}
      </v-toolbar-title>
    </v-app-bar>

    <v-navigation-drawer
      v-model="toolbar"
      class="left-sidebar"
      :width="navigationDrawerWidth"
      :permanent="mdAndUp"
      color="white"
      style="top: 0; height: 100%"
      :rail="miniVariant"
      rail-width="60"
    >
      <v-list
        density="compact"
        class="py-0"
      >
        <v-list-item
          v-if="!miniVariant && environmentLogo !== null"
          class="pb-2 pt-3"
        >
          <div style="display: flex; align-items: center">
            <img
              class="image"
              :src="environmentLogo"
            />
          </div>
        </v-list-item>
        <v-list-item class="pb-2 pt-3">
          <v-list-item-title>
            <!-- <span -->
            <!--   v-if="!miniVariant" -->
            <!--   class="text-body-2 text-grey-darken-1" -->
            <!-- > -->
            <!--   {{ environmentName }} -->
            <!-- </span> -->
            <text-overflow
              v-if="!miniVariant"
              bottom
              classes="text-body-2 text-grey-darken-1"
            >
              {{ environmentName }}
            </text-overflow>
          </v-list-item-title>
          <template #append>
            <div
              v-if="!miniVariant"
              class="ml-2 mr-4"
            >
              <v-icon
                class="text-grey-darken-1"
                @click="showEnvironmentsDialog = true"
              >
                mdi-swap-horizontal
              </v-icon>
              <v-tooltip
                activator="parent"
                location="bottom"
                :text="t('environments')"
              />
            </div>
            <div>
              <v-btn
                :icon="miniVariant ? 'mdi-chevron-right' : 'mdi-chevron-left'"
                variant="text"
                density="compact"
                class="text-grey-lighten-1"
                @click="() => (miniVariant = !miniVariant)"
              />
              <v-tooltip
                activator="parent"
                location="bottom"
                :text="miniVariant ? t('expand') : t('collapse')"
              />
            </div>
          </template>
        </v-list-item>
        <slot :mini-variant="miniVariant" />
      </v-list>

      <template #append>
        <last-feature-update :mini-variant="miniVariant" />
        <feedback-view :mini-variant="miniVariant" />
        <user-menu
          :mini-variant="miniVariant"
          @logout="logOut"
        />
      </template>
    </v-navigation-drawer>

    <v-main
      style="flex: 1 0 0; z-index: 0"
      :class="{
        'ma-4': mainPadding && mdAndUp,
        'mb-2': !mdAndUp,
      }"
    >
      <v-container
        fluid
        style="height: 100%"
        class="pa-0"
      >
        <router-view
          v-if="isLoggedIn && environmentName !== null"
          @update:model-value="(show: boolean) => toggleRouterView(show)"
          @toggle-toolbar="showToolbar"
        />
      </v-container>
    </v-main>

    <environments-dialog v-model="showEnvironmentsDialog" />
  </div>
</template>

<script setup lang="ts">
import { ref, computed, inject, onBeforeMount, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import { useView } from '@web-ui-root/composables/view';
import { useUser } from '@web-ui-root/composables/user';
import { useDisplay } from 'vuetify/lib/framework.mjs';
import { useUUID } from '@web-ui-root/composables/uuid';
import { useEnvironment } from '@web-ui-root/composables/environment';
import { useIotEnvironment } from '@web-ui-root/composables/iot-environment';
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from '@web-ui-root/helpers/local-storage/local-storage';

import TextOverflow from '@web-ui-root/components/text-overflow.vue';
import FeedbackView from '@web-ui-root/views/shared/feedback-view.vue';
import LastFeatureUpdate from '@web-ui-root/views/shared/last-feature-update.vue';
import EnvironmentsDialog from '@web-ui-root/views/environments/environments.vue';
import { useMap } from '@web-ui-root/views/monitoring-environment/map-view/map/map';
import UserMenu from './user-menu.vue';

type Props = {
  noAppBar?: boolean;
  mainPadding?: boolean;
  navigationDrawerWidth?: number | string;
  environmentName?: string | null;
  environmentType: string | null;
};

const props = withDefaults(defineProps<Props>(), {
  noAppBar: false,
  mainPadding: false,
  navigationDrawerWidth: 220,
  environmentName: null,
});

type Option<T> = T | undefined;

const goToDefaultRoute: Option<() => void> = inject('goToDefaultRoute');
const miniVariantLocalStorageKey = 'miniVariantNavigationBar';

const { t } = useI18n({
  messages: {
    en: {
      environments: 'Switch environment',
      expand: 'Expand',
      collapse: 'Collapse',
    },
    nl: {
      environments: 'Wissel omgeving',
      expand: 'Uitvouwen',
      collapse: 'Invouwen',
    },
  },
});

const { isLoggedIn, logOut: userLogOut } = useUser();
const { viewTitle } = useView();
const { mdAndUp } = useDisplay();
const { uuid } = useUUID();
uuid.value.toString();
const { map } = useMap();
const { logo: environmentLogo } =
  props.environmentType === 'monitoring' ? useEnvironment() : useIotEnvironment();

const toolbar = ref(true);
const miniVariant = ref(false);
const showEnvironmentsDialog = ref(false);

const title = computed(() => {
  if (viewTitle.value !== null) {
    return viewTitle.value ?? '';
  }
  return props.environmentName ?? '';
});

watch(miniVariant, (val) => {
  setLocalStorageItem(miniVariantLocalStorageKey, val, true);
  setTimeout(() => {
    map.value?.resize();
  }, 500);
});

onBeforeMount(() => {
  if (mdAndUp) {
    const miniVariantStored = getLocalStorageItem(miniVariantLocalStorageKey, true);
    if (typeof miniVariantStored === 'boolean') {
      miniVariant.value = miniVariantStored;
    }
  }
});

function toggleRouterView(show: boolean) {
  if (!show && goToDefaultRoute !== undefined) {
    goToDefaultRoute();
  }
}

async function logOut() {
  await userLogOut();
  if (goToDefaultRoute !== undefined) {
    goToDefaultRoute();
  }
}

function showToolbar() {
  toolbar.value = true;
}
</script>

<style scoped>
.image {
  max-height: 64px;
  max-width: 100%;
  object-fit: contain;
}
</style>
