import type { models } from '@withthegrid/amp-sdk';
import globalT from '@web-ui-root/views/monitoring-environment/analytics/query-builder/i18n';
import { useI18n } from 'vue-i18n';
import {
  ONE_DAY_IN_S,
  ONE_HOUR_IN_S,
  ONE_MINUTE_IN_S,
} from '@web-ui-root/helpers/constants/constants';
import { useSDK } from './sdk';

type IssueTriggerModel = {
  getIssueDelayString: (
    issueDelayCount: number | undefined,
    durationNumber: number | undefined,
    durationType: string | undefined,
  ) => string;
};

export type Unit = 'second' | 'minute' | 'hour' | 'day';
export type Duration = { durationNumber: number; unit: Unit };
export type DurationType = { value: Unit; text: Unit };
export type ConnectivityThreshold = models.connectivityThreshold.ConnectivityThreshold & {
  durationType?: Duration['unit'];
};
type measurementThreshold = {
  issueDelayS: number | null;
  durationNumber: number | undefined;
  durationType?: Duration['unit'];
};
export type MeasurementRow = Awaited<
  ReturnType<typeof sdk.routes.measurementThreshold.find>['response']
>['rows'][0] & { threshold: measurementThreshold };

export const durationTypes: DurationType[] = [
  {
    value: 'second',
    text: 'second',
  },
  {
    value: 'minute',
    text: 'minute',
  },
  {
    value: 'hour',
    text: 'hour',
  },
  {
    value: 'day',
    text: 'day',
  },
];

const { sdk } = useSDK();

export function secondsToDuration(s: number): Duration {
  const toFixedDigits = (value: number, digits: number): number =>
    parseFloat(value.toFixed(digits));

  let val: number = toFixedDigits(s / ONE_DAY_IN_S, 2);
  if (val >= 1 && Math.abs(val - s / ONE_DAY_IN_S) < Number.EPSILON) {
    return { durationNumber: val, unit: 'day' };
  }

  val = toFixedDigits(s / ONE_HOUR_IN_S, 2);
  if (val >= 1 && Math.abs(val - s / ONE_HOUR_IN_S) < Number.EPSILON) {
    return { durationNumber: val, unit: 'hour' };
  }

  val = toFixedDigits(s / ONE_MINUTE_IN_S, 1);
  if (val >= 1 && Math.abs(val - s / ONE_MINUTE_IN_S) < Number.EPSILON) {
    return { durationNumber: val, unit: 'minute' };
  }

  return { durationNumber: s, unit: 'second' };
}

export function durationToSeconds(val: number, unit: Unit): number {
  if (unit === 'day') {
    return val * ONE_DAY_IN_S;
  }
  if (unit === 'hour') {
    return val * ONE_HOUR_IN_S;
  }
  if (unit === 'minute') {
    return val * ONE_MINUTE_IN_S;
  }
  if (unit === 'second') {
    return val;
  }
  throw new Error('Invalid unit');
}

export function formatTimeConnectivityThreshold(
  threshold: ConnectivityThreshold,
): ConnectivityThreshold {
  if (
    threshold.durationType !== undefined &&
    typeof threshold.durationType !== 'string' &&
    'value' in threshold.durationType &&
    'text' in threshold.durationType
  ) {
    return threshold;
  }

  const { durationNumber, unit } = secondsToDuration(threshold.offlineForSeconds);

  return {
    ...threshold,
    durationType: unit,
    offlineForSeconds: durationNumber,
  };
}

export function formatTimeMeasurementThreshold(measurement: MeasurementRow): MeasurementRow {
  if (
    (typeof measurement.threshold.durationType !== 'string' &&
      measurement.threshold.durationType !== undefined &&
      'value' in measurement.threshold.durationType &&
      'text' in measurement.threshold.durationType) ||
    measurement.threshold.issueDelayS === null
  ) {
    return measurement;
  }

  const { durationNumber, unit } = secondsToDuration(measurement.threshold.issueDelayS);
  measurement.threshold = {
    ...measurement.threshold,
    durationType: unit,
    durationNumber,
  };
  return measurement;
}

export function useIssueTrigger(): IssueTriggerModel {
  const { t, mergeLocaleMessage } = useI18n();

  mergeLocaleMessage('en', globalT.en.timeUnits);
  mergeLocaleMessage('nl', globalT.nl.timeUnits);

  const getIssueDelayString = (
    issueDelayCount: number | undefined,
    durationNumber: number | undefined,
    durationType: string | undefined,
  ) => {
    if (
      issueDelayCount === undefined ||
      durationNumber === undefined ||
      durationType === undefined ||
      issueDelayCount === 0
    ) {
      return '-';
    }
    if (durationNumber === 0) {
      return `${issueDelayCount}x`;
    }
    return `${issueDelayCount}x & ${durationNumber} ${t(durationType, durationNumber)}`;
  };

  return {
    getIssueDelayString,
  };
}
