<template>
  <w-dialog
    key="auth-dialog"
    v-model="modelValue"
    :title="t('authenticate')"
    :scrollable="false"
  >
    <p v-t="'authenticateDescription'" />

    <v-form
      v-model="isFormValid"
      @submit.prevent
    >
      <v-text-field
        v-if="showPasswordField"
        key="password"
        ref="password"
        v-model="passwordValue"
        :label="t('password')"
        :append-inner-icon="hidePassword ? 'mdi-eye' : 'mdi-eye-off'"
        :type="hidePassword ? 'password' : 'text'"
        autocomplete="password"
        name="password"
        autofocus
        :rules="[nonEmptyString, stringNotTooShort(8)]"
        :error="errorMessages.length > 0"
        variant="underlined"
        color="primary"
        @click:append-inner="hidePassword = !hidePassword"
        @keydown.enter="validate"
      />
      <v-text-field
        v-if="showCodeField"
        key="code"
        ref="code"
        v-model="codeValue"
        :label="t('code')"
        type="text"
        :rules="[nonEmptyString, stringIsValidOneTimePassword]"
        :error="errorMessages.length > 0"
        inputtype="numeric"
        autocomplete="one-time-code"
        name="one-time-code"
        variant="underlined"
        color="primary"
        @keydown.enter="validate"
      />
      <div v-if="errorMessages.length > 0">
        <div
          v-for="error in errorMessages"
          :key="error"
          class="ml-2 text-caption text-error text-no-wrap"
        >
          {{ error }}
        </div>
      </div>
    </v-form>
    <template #main-action>
      <v-btn
        ripple
        color="primary"
        elevation="0"
        :disabled="!isFormValid"
        variant="flat"
        @click.stop="validate"
      >
        <span v-t="'submit'" />
      </v-btn>
    </template>
  </w-dialog>
</template>

<script setup lang="ts">
/**
 * - [ ] When mode is full and 2fa is enabled, show both password and 2fa
 * - [ ] When mode is partial and 2fa is enabled, show only 2fa
 * - [ ] When mode is full and 2fa is disabled, show only password
 * - [ ] When mode is partial and 2fa is disabled, show only password
 */
import { onMounted, ref, computed, type Ref, watch, inject } from 'vue';
import { useUser } from '@web-ui-root/composables/user';
import { useI18n } from 'vue-i18n';
import {
  nonEmptyString,
  stringNotTooShort,
  stringIsValidOneTimePassword,
} from '@web-ui-root/helpers/rules';
import type { routes } from '@withthegrid/amp-sdk';
import { errors as sdkErrors } from '@withthegrid/amp-sdk';
import type { RouteLocationRaw } from 'vue-router';
import { useBusHandler } from '@web-ui-root/composables//bus-handler';
import { dashOrSnakeToCamel } from '@web-ui-root/helpers/object-helper';
import wDialog from './w-dialog.vue';

const pushRoute: ((location: RouteLocationRaw) => Promise<boolean>) | undefined =
  inject('pushRoute');
type Option<T> = T | undefined;
const goToDefaultRoute: Option<() => void> = inject('goToDefaultRoute');

const { twoFactorAuthenticationEnabled, checkAuthentication, authChallenge } = useUser();
const { emit: busEmit } = useBusHandler();

type Props = {
  mode: routes.authentication.authChallenge.Request['body']['type'];
  authOnMount?: boolean;
  goToDefaultRouteOnClose?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  authOnMount: true,
});

const modelValue = defineModel<boolean>('modelValue', { default: false });
const authenticated = defineModel<boolean>('authenticated', { default: false });

const isFormValid = ref(false);
const passwordValue: Ref<string | undefined> = ref(undefined);
const codeValue: Ref<string | undefined> = ref(undefined);
const hidePassword = ref(true);

defineExpose({
  authCheck,
});

const { t } = useI18n({
  messages: {
    en: {
      authenticate: 'Authenticate',
      authenticateDescription: 'Please authenticate to continue.',
      password: 'Password',
      code: 'Enter code from your authenticator app',
      submit: 'Submit',
      error: {
        comms: 'The server cannot be reached, are you online?',
        invalidCredentials: 'Incorrect password, code or too many failed attempts.',
        passwordNotSet: 'You have not set a password yet (check your inbox)',
        passwordRequired: 'Password is required',
        codeRequired: 'Code is required',
        sessionExpired: 'Session expired, please log in again.',
        userExpired: 'A error occurred, please log in again.',
      },
    },
    nl: {
      authenticate: 'Authenticeren',
      authenticateDescription: 'Authenticeer om door te gaan.',
      password: 'Wachtwoord',
      code: 'Voer de code van uw authenticator-app in',
      submit: 'Verzenden',
      error: {
        comms: 'De server is niet bereikbaar. Heb je internet verbinding?',
        invalidCredentials: 'Wachtwoord of code is onjuist of te vaak fout ingevoerd.',
        passwordNotSet: 'Je hebt nog geen wachtwoord ingesteld (check je inbox)',
        passwordRequired: 'Wachtwoord is verplicht',
        codeRequired: 'Code is verplicht',
        sessionExpired: 'Sessie verlopen, log opnieuw in.',
        userExpired: 'Er is een fout opgetreden, log opnieuw in.',
      },
    },
  },
});

const errorKeys = [
  'invalid_credentials',
  'password_not_set',
  'password_required',
  '2fa_code_required',
] as const;

type AuthCodeErrorKeys = (typeof errorKeys)[number];

const errorMessagesMap: Record<AuthCodeErrorKeys, string> = {
  invalid_credentials: t('error.invalidCredentials'),
  password_not_set: t('error.passwordNotSet'),
  password_required: t('error.passwordRequired'),
  '2fa_code_required': t('error.codeRequired'),
};

const errorMessages: Ref<string[]> = ref([]);

const showPasswordField = computed(() => {
  return (
    props.mode === 'full' ||
    (props.mode === 'simple' && twoFactorAuthenticationEnabled.value === false)
  );
});

const showCodeField = computed(() => {
  return twoFactorAuthenticationEnabled.value === true;
});

watch(passwordValue, () => {
  if (errorMessages.value.length > 0) {
    errorMessages.value = [];
  }
});

watch(codeValue, () => {
  if (errorMessages.value.length > 0) {
    errorMessages.value = [];
  }
});

watch(modelValue, (newVal, oldVal) => {
  if (
    newVal === false &&
    oldVal === true &&
    props.goToDefaultRouteOnClose === true &&
    authenticated.value === false
  ) {
    if (goToDefaultRoute !== undefined) {
      goToDefaultRoute();
    }
  }
});

onMounted(async () => {
  if (props.authOnMount && authenticated.value === false) {
    await authCheck();
  }
});

async function authCheck() {
  const authenticationCheck = await checkAuthentication({ type: props.mode });

  if (authenticationCheck !== undefined && authenticationCheck !== authenticated.value) {
    authenticated.value = authenticationCheck;
  }

  if (authenticationCheck === undefined || authenticated.value === false) {
    modelValue.value = true;
  }

  return authenticated.value;
}

async function validate() {
  if (showPasswordField.value && !passwordValue.value) {
    // This should not happen
    throw new Error('Password is required');
  }
  if (showCodeField.value && !codeValue.value) {
    // This should not happen
    throw new Error('Code is required');
  }

  errorMessages.value = [];

  let authChallengeResponse: boolean | undefined;
  try {
    authChallengeResponse = await authChallenge({
      type: props.mode,
      password: passwordValue.value,
      code: codeValue.value,
    });
  } catch (e) {
    if (e instanceof sdkErrors.Authentication) {
      if (errorKeys.includes(e.key as AuthCodeErrorKeys)) {
        errorMessages.value = [errorMessagesMap[e.key as AuthCodeErrorKeys]];
      } else if (e.key === 'user_expired' || e.key === 'session_expired') {
        if (pushRoute !== undefined) {
          await pushRoute({ path: '/login' });
        }
        busEmit('error', t(`error.${dashOrSnakeToCamel(e.key)}`));
      } else {
        busEmit('commsError', e);
      }
    } else if (e instanceof sdkErrors.CommsRequest) {
      busEmit('error', t('error.comms'));
    } else {
      busEmit('commsError', e);
    }
    return;
  }

  authenticated.value = authChallengeResponse ?? false;
  if (authChallengeResponse && modelValue.value) {
    modelValue.value = false;
  }
}
</script>
