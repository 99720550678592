import Rollbar from 'rollbar';
import { APP_URL, ROLLBAR_TOKEN, ROLLBAR_ENVIRONMENT, DEV } from '@web-ui-root/helpers/vite';
// eslint-disable-next-line import/no-unresolved
import { browsersRegex } from 'virtual:supported-browsers';
import { version } from '../../package.json';
import { useBusHandler } from './bus-handler';

export type RollbarModel = {
  rollbar: Rollbar | undefined;

  debug: (message: string, ...extra: unknown[]) => void;
  info: (message: string, ...extra: unknown[]) => void;
  warn: (message: string, ...extra: unknown[]) => void;
  error: (message: string, ...extra: unknown[]) => void;
  critical: (message: string, ...extra: unknown[]) => void;
};

/**
 * These types are made by parsing the response during a error event in the browser.
 * Be aware that the types are incomplete and might be wrong.
 * I also made everything optional, because I don't know if there are situations where some fields
 * are not present.
 */
type RollbarPayload = {
  environment?: string;
  level?: unknown;
  endpoint?: string;
  platform?: string;
  framework?: string;
  language?: string;
  server?: unknown;
  uuid?: string;
  notifier?: unknown;
  request?: unknown;
  client?: unknown;
  body?: RollbarPayloadBody;
  person?: unknown;
};

type RollbarPayloadBody = {
  trace?: Trace;
  telemetry?: unknown[];
};

type Trace = {
  exception?: Exception;
  frames?: Frame[];
};

type Exception = {
  class?: string;
  message?: string;
};

type Frame = {
  filename?: string;
  lineno?: number;
  method?: string;
  colno?: number;
};

function isError(obj: any): obj is Error {
  return typeof obj === 'object' && 'message' in obj && typeof obj.message === 'string';
}

let rollbar: Rollbar | undefined;

export function useRollbar(): RollbarModel {
  const { emit } = useBusHandler();

  if (typeof ROLLBAR_TOKEN === 'string' && ROLLBAR_TOKEN !== '' && rollbar === undefined) {
    try {
      rollbar = new Rollbar({
        accessToken: ROLLBAR_TOKEN,
        captureUncaught: true,
        captureUnhandledRejections: true,
        code_version: version,
        payload: {
          // Add custom data to your events by adding custom key/value pairs like the one below
          environment: ROLLBAR_ENVIRONMENT,
          client: {
            javascript: {
              // Track your events to a specific version of code for better visibility
              // into version health
              code_version: version,
              source_map_enabled: true,
            },
          },
        },
        transform: (payload: RollbarPayload) => {
          /**
           * Transform the filename of the stack trace to use the dynamic host so the sourcemaps
           * work on multiple subdomains.
           *
           * @see https://docs.rollbar.com/docs/source-maps#using-source-maps-on-many-domains
           */
          if (ROLLBAR_ENVIRONMENT === 'production' && payload.body !== undefined) {
            const trace = payload.body.trace;
            if (trace !== undefined && trace.frames !== undefined) {
              for (let i = 0; i < trace.frames.length; i += 1) {
                const filename = trace.frames[i].filename;
                if (filename !== undefined) {
                  try {
                    const fileUrl = new URL(filename);
                    /** Be sure that all the trace files are using the same base_url because of the
                     *  different subdomains we use.
                     */
                    trace.frames[i].filename = `${APP_URL}${fileUrl.pathname}`;
                  } catch (error) {
                    console.error(`Error transforming Rollbar URL: ${filename}`, error);
                  }
                }
              }
            }
          }
        },
        checkIgnore: (isUncaught, args) => {
          if (!browsersRegex.test(navigator.userAgent)) {
            return true;
          }
          if (args.length > 0) {
            /** Vuetify seems to to have this error with the autocomplete and can't/won't fix it
             * @see https://github.com/vuetifyjs/vuetify/issues/18328#issuecomment-1732948060
             * @see https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded/50387233#50387233
             */
            if (
              isUncaught &&
              args.some(
                (e) =>
                  e !== undefined &&
                  typeof e === 'string' &&
                  (e.includes('ResizeObserver loop completed with undelivered notifications') ||
                    e.includes('ResizeObserver loop limit exceeded')),
              )
            ) {
              return true;
            }

            // Async component load
            if (isError(args[0]) && args[0].message.startsWith('Async component timed out after')) {
              // @ts-expect-error global event defined in index.html
              document.dispatchEvent(slowLoadEvent);

              return true;
            }

            /**
             * Script errors are caused by externally loaded scripts that we don't have. These
             * errors are probably caused by browser extensions, ad blockers, or network errors
             * which we can do nothing about.
             */
            if (isError(args[0]) && args[0].message.startsWith('Script error')) {
              return true;
            }
          }

          emit('errorReported');
          return false;
        },
      });
    } catch (error) {
      console.error('Error initializing Rollbar:', error);
    }
  } else if ((typeof ROLLBAR_TOKEN !== 'string' || ROLLBAR_TOKEN === '') && !DEV) {
    console.error('Rollbar token not found');
  }

  function log(level: Rollbar.Level) {
    return function logFunction(message: string, ...extra: unknown[]) {
      if (extra.length > 0) {
        return rollbar?.captureEvent(
          {
            message,
            ...extra,
          },
          level,
        );
      }
      return rollbar?.captureEvent({ message }, level);
    };
  }

  return {
    rollbar,
    debug: log('debug'),
    info: log('info'),
    warn: log('warning'),
    error: log('error'),
    critical: log('critical'),
  };
}
