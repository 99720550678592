<template>
  <div>
    <date-time-picker
      :model-value="startDateModelValue"
      :allow-empty="allowEmpty"
      :label="startDateLabel"
      :min="startMin"
      :max="startMax"
      @update:model-value="setStartDate"
    />
    <date-time-picker
      :model-value="endDateModelValue"
      :allow-empty="allowEmpty"
      :label="endDateLabel"
      :min="endMin"
      :max="endMax"
      @update:model-value="setEndDate"
    />
  </div>
</template>

<script setup lang="ts">
import dateTimePicker from '@web-ui-root/components/inputs/date-time-pickers/date-time-picker.vue';
import DateTimeWrapper from '@web-ui-root/helpers/date-utils/date-time-wrapper';
import { nextTick } from 'vue';

type Props = {
  startMin?: Date;
  startMax?: Date;
  endMin?: Date;
  endMax?: Date;
  startDateLabel?: string;
  endDateLabel?: string;
  allowEmpty?: boolean;
};

withDefaults(defineProps<Props>(), {
  startMin: undefined,
  startMax: undefined,
  endMin: undefined,
  endMax: undefined,
  startDateLabel: undefined,
  endDateLabel: undefined,
  allowEmpty: false,
});
const startDateModelValue = defineModel<string | Date | undefined>('startDateModelValue');
const endDateModelValue = defineModel<string | Date | undefined>('endDateModelValue');
let validRange = true;

function setStartDate(date: Date | string | undefined) {
  startDateModelValue.value = date;
  // wait for the model to update
  nextTick(() => {
    validate();
    if (
      !validRange &&
      startDateModelValue.value !== undefined &&
      endDateModelValue.value !== undefined
    ) {
      if (startDateModelValue.value instanceof Date && endDateModelValue.value instanceof Date) {
        endDateModelValue.value = new Date(startDateModelValue.value.valueOf());
      } else {
        endDateModelValue.value = startDateModelValue.value;
      }
      validRange = true;
    }
  });
}

function setEndDate(date: Date | string | undefined) {
  endDateModelValue.value = date;
  // wait for the model to update
  nextTick(() => {
    validate();
    if (
      !validRange &&
      startDateModelValue.value !== undefined &&
      endDateModelValue.value !== undefined
    ) {
      if (startDateModelValue.value instanceof Date && endDateModelValue.value instanceof Date) {
        startDateModelValue.value = new Date(endDateModelValue.value.valueOf());
      } else {
        startDateModelValue.value = endDateModelValue.value;
      }
      validRange = true;
    }
  });
}

function validate() {
  if (startDateModelValue.value && endDateModelValue.value) {
    const sd =
      startDateModelValue.value instanceof Date
        ? DateTimeWrapper.fromJSDate(startDateModelValue.value)
        : DateTimeWrapper.fromISO(startDateModelValue.value);
    const ed =
      endDateModelValue.value instanceof Date
        ? DateTimeWrapper.fromJSDate(endDateModelValue.value)
        : DateTimeWrapper.fromISO(endDateModelValue.value);
    validRange = sd < ed;
    return;
  }
  validRange = true;
}
</script>
