import { HH, HH_MM, HH_MM_SS, HH_MM_SS_SSS } from '@web-ui-root/helpers/date-utils';

export const timeKeys = ['hours', 'minutes', 'seconds', 'milliseconds'] as const;
export type TimeKeys = (typeof timeKeys)[number];

type TimeFormat = {
  hint: string;
  placeholder: string;
  format: string;
};

type TimePickerModel = {
  formatMap: Record<TimeKeys, TimeFormat>;
  granularityLevels: typeof timeKeys;
};

export function useTimePicker(): TimePickerModel {
  const formatMap: Record<TimeKeys, TimeFormat> = {
    hours: {
      hint: 'hh',
      placeholder: '12',
      format: HH,
    },
    minutes: {
      hint: 'hh:mm',
      placeholder: '12:34',
      format: HH_MM,
    },
    seconds: {
      hint: 'hh:mm:ss',
      placeholder: '12:34:56',
      format: HH_MM_SS,
    },
    milliseconds: {
      hint: 'hh:mm:ss.SSS',
      placeholder: '12:34:56.789',
      format: HH_MM_SS_SSS,
    },
  };

  const granularityLevels = Object.keys(formatMap) as unknown as typeof timeKeys;

  return {
    formatMap,
    granularityLevels,
  };
}
