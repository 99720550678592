<template>
  <w-framework
    :environment-name="name"
    :environment-type="'connectivity'"
    main-padding
    navigation-drawer-width="240"
  >
    <template #default="{ miniVariant }">
      <v-list-item
        :to="`/i/${environmentHashId}/activity`"
        prepend-icon="mdi-chart-timeline-variant"
        :title="t('activity')"
        @click.stop
      >
        <v-tooltip
          activator="parent"
          location="right"
          :disabled="!miniVariant"
          :text="t('activity')"
        />
      </v-list-item>

      <v-list-item
        :to="`/i/${environmentHashId}/devices`"
        prepend-icon="mdi-access-point"
        :title="t('devices')"
        @click.stop
      >
        <v-tooltip
          activator="parent"
          location="right"
          :disabled="!miniVariant"
          :text="t('devices')"
        />
      </v-list-item>

      <v-list-item
        :to="`/i/${environmentHashId}/commands`"
        prepend-icon="mdi-briefcase"
        :title="t('commands')"
        @click.stop
      >
        <v-tooltip
          activator="parent"
          location="right"
          :disabled="!miniVariant"
          :text="t('commands')"
        />
      </v-list-item>

      <v-list-item
        :to="`/i/${environmentHashId}/device-types`"
        prepend-icon="mdi-sim"
        :title="t('deviceTypes')"
        @click.stop
      >
        <v-tooltip
          activator="parent"
          location="right"
          :disabled="!miniVariant"
          :text="t('deviceTypes')"
        />
      </v-list-item>

      <v-list-group>
        <template #activator="{ props }">
          <v-list-item
            v-bind="props"
            prepend-icon="mdi-security"
            :title="t('authentication')"
            @click.stop
          >
            <v-tooltip
              activator="parent"
              location="right"
              :disabled="!miniVariant"
              :text="t('authentication')"
            />
          </v-list-item>
        </template>
        <v-list-item
          :to="`/i/${environmentHashId}/device-access/webhooks`"
          :title="t('webhooks')"
        />

        <v-list-item
          :to="`/i/${environmentHashId}/device-access/certificates`"
          :title="t('certificates')"
        />
      </v-list-group>

      <v-list-item
        :to="`/i/${environmentHashId}/quantities`"
        prepend-icon="mdi-speedometer"
        :title="t('quantities')"
        @click.stop
      >
        <v-tooltip
          activator="parent"
          location="right"
          :disabled="!miniVariant"
          :text="t('quantities')"
        />
      </v-list-item>

      <v-list-item
        :to="`/i/${environmentHashId}/credentials`"
        prepend-icon="mdi-key"
        :title="t('credentials')"
        @click.stop
      >
        <v-tooltip
          activator="parent"
          location="right"
          :disabled="!miniVariant"
          :text="t('credentials')"
        />
      </v-list-item>

      <v-list-item
        :to="`/i/${environmentHashId}/report-types`"
        prepend-icon="mdi-list-box-outline"
        :title="t('reportTypes')"
        @click.stop
      >
        <v-tooltip
          activator="parent"
          location="right"
          :disabled="!miniVariant"
          :text="t('reportTypes')"
        />
      </v-list-item>

      <v-list-item
        :to="`/i/${environmentHashId}/command-types`"
        prepend-icon="mdi-briefcase-outline"
        :title="t('commandTypes')"
        @click.stop
      >
        <v-tooltip
          activator="parent"
          location="right"
          :disabled="!miniVariant"
          :text="t('commandTypes')"
        />
      </v-list-item>

      <v-list-group>
        <template #activator="{ props }">
          <v-list-item
            v-bind="props"
            prepend-icon="mdi-cog"
            :title="t('settings')"
            @click.stop
          >
            <v-tooltip
              activator="parent"
              location="right"
              :disabled="!miniVariant"
              :text="t('settings')"
            />
          </v-list-item>
        </template>

        <v-list-item
          :to="`/i/${environmentHashId}/settings/users`"
          :title="t('users')"
        />

        <v-list-item
          :to="`/i/${environmentHashId}/settings/environment`"
          :title="t('environmentConfiguration')"
        />

        <v-list-item
          :to="`/i/${environmentHashId}/settings/audit-log`"
          :title="t('auditLog')"
        />
      </v-list-group>
    </template>
  </w-framework>
</template>

<script setup lang="ts">
import { useIotEnvironment } from '@web-ui-root/composables/iot-environment';
import { useUUID } from '@web-ui-root/composables/uuid';
import { useI18n } from 'vue-i18n';
import WFramework from '@web-ui-root/components/framework/w-framework.vue';
import { onMounted } from 'vue';

const { uuid } = useUUID();
uuid.value.toString();
const { name, hashId: environmentHashId } = useIotEnvironment();
const { t } = useI18n({
  messages: {
    en: {
      deviceTypes: 'Device types',
      devices: 'Devices',
      commands: 'Commands',
      authentication: 'Device access',
      users: 'Users',
      environmentConfiguration: 'Environment',
      certificates: 'Certificates',
      webhooks: 'Webhooks',
      quantities: 'Quantities',
      credentials: 'Credentials',
      reportTypes: 'Report types',
      commandTypes: 'Command types',
      activity: 'Activity',
      settings: 'Settings',
      auditLog: 'Audit log',
    },
    nl: {
      deviceTypes: 'Device-soorten',
      devices: 'Devices',
      commands: 'Opdrachten',
      authentication: 'Devicetoegang',
      users: 'Gebruikers',
      environmentConfiguration: 'Omgeving',
      certificates: 'Certificaten',
      webhooks: 'Webhooks',
      quantities: 'Kwantiteiten',
      credentials: 'Authenticatiegegevens',
      reportTypes: 'Rapportsoorten',
      commandTypes: 'Opdrachtsoorten',
      activity: 'Activiteit',
      settings: 'Instellingen',
      auditLog: 'Audit log',
    },
  },
});

onMounted(() => {
  // @ts-expect-error global event defined in index.html
  document.dispatchEvent(loaderEvent);
});
</script>
