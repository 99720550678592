type Option<T> = null | T;
export type SearchToken = [Option<string>, string];
export type SearchTokens = Array<SearchToken>;

const splitSearchQuery = (searchQuery: string): SearchTokens => {
  const tokenSplitter = /(?:([^\s':]+):)?(?:'((?:[^'\\]|\\.)+)'|([^'\s]+))/g;

  const searchTokens = [];

  let match;
  do {
    match = tokenSplitter.exec(searchQuery);
    if (match !== null) {
      /* Layout of match:
        0: whole match
        1: key
        2: value (escaped, in single quotes)
        3: value (unescaped, no quotes around it)
      */

      let unescapedValue;
      if (match[3] !== undefined) {
        unescapedValue = match[3];
      } else {
        unescapedValue = match[2].replace(/\\(.)/g, '$1');
      }

      searchTokens.push([
        match[1] === undefined ? null : match[1].toLowerCase(),
        unescapedValue,
      ] as SearchToken);
    }
  } while (match !== null);

  return searchTokens;
};

const buildSearchQuery = (searchTokens: SearchTokens): string =>
  searchTokens
    .map((s) => {
      const prefix = s[0] === null ? '' : `${s[0]}:`;
      let value;
      if (s[1].includes("'") || s[1].includes(' ')) {
        value = `'${s[1].replace(/\\/g, '\\\\').replace(/'/g, "\\'")}'`;
      } else {
        value = s[1];
      }
      return `${prefix}${value}`;
    })
    .join(' ');

export { splitSearchQuery, buildSearchQuery };
