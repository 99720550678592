<template>
  <div>
    <v-menu
      v-model="showMenu"
      :close-on-content-click="false"
      transition="scale-transition"
      :offset="[0, -8]"
      min-width="290px"
    >
      <template #activator="{ props: menuProps }">
        <slot :props="menuProps">
          <v-text-field
            v-model="inputDateString"
            :label="label"
            :disabled="disabled"
            :prepend-icon="prependIcon"
            :append-inner-icon="innerIcon"
            :error-messages="errorMessages"
            validate-on="blur"
            style="min-width: 170px"
            variant="underlined"
            color="primary"
            v-bind="menuProps"
            @click:append-inner="changeDate(undefined)"
            @keydown.enter="handleEnter"
            @keydown.tab="() => (showMenu = false)"
          />
        </slot>
      </template>
      <v-card variant="outlined">
        <v-card-text class="pa-0">
          <v-date-picker
            ref="picker"
            :model-value="date"
            :min="minDateString"
            :max="maxDateString"
            color="primary"
            :class="{ 'has-no-title': cardTitle === undefined && cardSubtitle === undefined }"
            @update:model-value="changeDate"
            @click:cancel="showMenu = false"
            @click:save="applyEdit"
          >
            <template #title>
              <template v-if="cardTitle !== undefined">
                <v-card-title>{{ cardTitle }}</v-card-title>
              </template>
              <template v-if="cardSubtitle !== undefined">
                <v-card-subtitle>{{ cardSubtitle }}</v-card-subtitle>
              </template>
              <template v-if="cardTitle === undefined && cardSubtitle === undefined">
                <span />
              </template>
            </template>
            <template
              v-if="showFooterButtons"
              #actions
            >
              <v-btn
                variant="text"
                @click="showMenu = false"
              >
                {{ t('cancel') }}
              </v-btn>
              <v-btn
                variant="elevated"
                color="primary"
                @click="applyEdit"
              >
                {{ t('ok') }}
              </v-btn>
            </template>

            <template #header>
              <span />
            </template>
          </v-date-picker>
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch, onBeforeMount } from 'vue';
import { useUUID } from '@web-ui-root/composables/uuid';
import DateTimeWrapper, { YYYY_MM_DD } from '@web-ui-root/helpers/date-utils/date-time-wrapper';
import { useI18n } from 'vue-i18n';
import { VDatePicker, VMenu, VTextField } from 'vuetify/lib/components/index.mjs';

function dateToString(date: Date): string {
  return DateTimeWrapper.fromJSDate(date).toFormat(YYYY_MM_DD);
}
function stringToDate(dateString: string): Date {
  return DateTimeWrapper.fromFormat(dateString, YYYY_MM_DD).toJSDate();
}

const { uuid } = useUUID();
uuid.value.toString();

type Props = {
  modelValue?: Date;
  min?: Date;
  max?: Date;
  disabled?: boolean;
  label?: string;
  cardTitle?: string;
  cardSubtitle?: string;
  prependIcon?: string;
  allowEmpty?: boolean;
  showFooterButtons?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  modelValue: undefined,
  min: undefined,
  max: undefined,
  disabled: false,
  label: undefined,
  cardTitle: undefined,
  cardSubtitle: undefined,
  prependIcon: 'mdi-calendar',
  allowEmpty: false,
  showFooterButtons: true,
});

const emit = defineEmits<{
  'update:modelValue': [date: Date | undefined];
  'edit-applied': [];
}>();

const { t } = useI18n({
  messages: {
    en: {
      dateFormatError: 'Must be in YYYY-MM-DD format',
      ok: 'OK',
      cancel: 'Cancel',
    },
    nl: {
      dateFormatError: 'Moet in YYYY-MM-DD formaat',
      ok: 'OK',
      cancel: 'Annuleren',
    },
  },
});

const date = ref<Date | undefined>(undefined);
const originalValue = ref<Date | undefined>(undefined);
const showMenu = ref(false);
const preventCancel = ref(false);
const inputDateString = ref<string | undefined>(undefined);
const picker = ref<null | typeof VDatePicker>(null);

const minDateString = computed(() => {
  if (props.min === undefined) {
    return undefined;
  }
  return dateToString(props.min);
});

const maxDateString = computed(() => {
  if (props.max === undefined) {
    return undefined;
  }
  return dateToString(props.max);
});

const innerIcon = computed(() =>
  props.allowEmpty && props.modelValue !== undefined ? 'mdi-close' : undefined,
);

const isValidDate = computed(() => {
  if (inputDateString.value === undefined) {
    return props.allowEmpty;
  }

  return DateTimeWrapper.fromFormat(inputDateString.value, YYYY_MM_DD).isValid;
});

const dateString = computed({
  get() {
    if (date.value === undefined) {
      return undefined;
    }
    return DateTimeWrapper.fromJSDate(date.value).toFormat(YYYY_MM_DD);
  },
  set(value: string | undefined) {
    if (value === undefined) {
      date.value = undefined;
      return;
    }
    date.value = DateTimeWrapper.fromFormat(value, YYYY_MM_DD).toJSDate();
  },
});

const errorMessages = computed(() => (isValidDate.value ? [] : [t('dateFormatError')]));

watch(
  () => props.modelValue,
  () => {
    initialize();
  },
);

function clickInsideHandler(target: MouseEvent) {
  if (picker.value === null || picker.value.$el === undefined) {
    return;
  }

  const bounds = picker.value.$el.getBoundingClientRect();
  const clickInside =
    target.clientX >= bounds.left &&
    target.clientX <= bounds.right &&
    target.clientY >= bounds.top &&
    target.clientY <= bounds.bottom;

  if (clickInside) {
    return;
  }
  if (showMenu.value === true) {
    showMenu.value = false;
  }
}

watch(showMenu, (value) => {
  if (value) {
    document.body.addEventListener('click', clickInsideHandler);
    originalValue.value = props.modelValue;
  } else if (!preventCancel.value) {
    document.body.removeEventListener('click', clickInsideHandler);
    emit('update:modelValue', originalValue.value);
  }
  preventCancel.value = false;
});

watch(inputDateString, () => {
  if (props.allowEmpty && inputDateString.value === '') {
    inputDateString.value = undefined;
  }
  if (isValidDate.value && dateString.value !== inputDateString.value) {
    dateString.value = inputDateString.value;
    if (dateString.value !== undefined) {
      changeDate(stringToDate(dateString.value));
    }
    if (!props.showFooterButtons) {
      applyEdit();
    }
  }
});

function initialize(): void {
  if (props.modelValue === undefined) {
    if (props.allowEmpty) {
      dateString.value = undefined;
      inputDateString.value = dateString.value;
    } else {
      dateString.value = dateToString(new Date());
      inputDateString.value = dateString.value;
    }
    return;
  }

  dateString.value = dateToString(props.modelValue);
  inputDateString.value = dateString.value;
}

function applyEdit() {
  preventCancel.value = true;
  showMenu.value = false;
  emit('edit-applied');
}

function changeDate(newDate?: Date) {
  if (newDate === undefined && props.allowEmpty) {
    dateString.value = newDate;
    emit('update:modelValue', newDate);
    emit('edit-applied');
    return;
  }
  if (!(newDate instanceof Date)) {
    return;
  }
  if (!props.showFooterButtons) {
    preventCancel.value = true;
  }
  dateString.value = newDate.toString();

  if (!props.showFooterButtons) {
    showMenu.value = false;
  }
  emit('update:modelValue', newDate);
}

function handleEnter() {
  if (!props.showFooterButtons) {
    return;
  }
  applyEdit();
}

onBeforeMount(() => {
  initialize();
});
</script>

<style scoped lang="scss">
.has-no-title :deep(.v-picker-title) {
  padding-top: 0;
  padding-bottom: 0;
}

// https://github.com/vuetifyjs/vuetify/issues/17631#issuecomment-1816518600
* :deep(.v-date-picker) > div:first-child.bg-primary {
  // grey-lighten-3 (previously was transparent/white, but I prefer the clear separation
  background-color: #eee !important;
}

// https://github.com/vuetifyjs/vuetify/issues/18863
* :deep(.v-date-picker) .v-date-picker-month__day--hide-adjacent:nth-last-child(-n + 10) {
  display: none;
}
</style>
