import type { models } from '@withthegrid/amp-sdk';

import en1 from './en/1.md?raw';
import nl1 from './nl/1.md?raw';
import en2 from './en/2.md?raw';
import nl2 from './nl/2.md?raw';
import en3 from './en/3.md?raw';
import nl3 from './nl/3.md?raw';

export type Update = {
  content: string;
  title: string;
};

type Updates = Record<models.locale.Locale, Record<number, Update>>;

/**
 * 🚩 Remember to ask Pieter to review the content of your updates.
 * (See "What's new updates" in the web-ui readme.md)
 */
export const updates: Updates = {
  en: {
    1: {
      content: en1,
      title: 'Info and connectivity issue map filters',
    },
    2: {
      content: en2,
      title: 'E-mail redesign',
    },
    3: {
      content: en3,
      title: 'Device connectivity graph',
    },
  },
  nl: {
    1: {
      content: nl1,
      title: 'Kaartfilters voor info- en connectiviteitsissues',
    },
    2: {
      content: nl2,
      title: 'E-mail redesign',
    },
    3: {
      content: nl3,
      title: 'Device connectiviteit grafiek',
    },
  },
};
