<template>
  <w-framework
    :no-app-bar="noAppBar"
    :environment-name="name"
    :environment-type="'monitoring'"
  >
    <template #default="{ miniVariant }">
      <v-list-item
        :exact="!isMap"
        :to="`/e/${hashId}`"
        prepend-icon="mdi-map"
        :title="t('map')"
        @click.stop
      >
        <v-tooltip
          activator="parent"
          location="right"
          :disabled="!miniVariant"
          :text="t('map')"
        />
      </v-list-item>

      <v-list-item
        :to="`/e/${hashId}/groups/pinGroup`"
        prepend-icon="mdi-map-marker-multiple"
        :title="t('groups')"
        @click.stop
      >
        <v-tooltip
          activator="parent"
          location="right"
          :disabled="!miniVariant"
          :text="t('groups')"
        />
      </v-list-item>

      <v-list-item
        :to="`/e/${hashId}/groups/pin`"
        prepend-icon="mdi-video-input-component"
        :title="t('pinGrid')"
        @click.stop
      >
        <v-tooltip
          activator="parent"
          location="right"
          :disabled="!miniVariant"
          :text="t('pinGrid')"
        />
      </v-list-item>

      <v-list-item
        :to="`/e/${hashId}/pin-groups`"
        prepend-icon="mdi-map-marker"
        :title="t('pinGroups')"
        @click.stop
      >
        <v-tooltip
          activator="parent"
          location="right"
          :disabled="!miniVariant"
          :text="t('pinGroups')"
        />
      </v-list-item>

      <v-list-item
        :to="`/e/${hashId}/pins`"
        prepend-icon="mdi-video-input-hdmi"
        :title="t('pins')"
        @click.stop
      >
        <v-tooltip
          activator="parent"
          location="right"
          :disabled="!miniVariant"
          :text="t('pins')"
        />
      </v-list-item>

      <v-list-item
        :to="`/e/${hashId}/edges`"
        prepend-icon="mdi-link"
        :title="t('edges')"
        @click.stop
      >
        <v-tooltip
          activator="parent"
          location="right"
          :disabled="!miniVariant"
          :text="t('edges')"
        />
      </v-list-item>

      <v-list-item
        :to="`/e/${hashId}/devices`"
        prepend-icon="mdi-access-point"
        :title="t('devices')"
        @click.stop
      >
        <v-tooltip
          activator="parent"
          location="right"
          :disabled="!miniVariant"
          :text="t('devices')"
        />
      </v-list-item>

      <v-list-item
        :to="`/e/${hashId}/analytics`"
        prepend-icon="mdi-poll"
        :title="t('analytics')"
        @click.stop
      >
        <v-tooltip
          activator="parent"
          location="right"
          :disabled="!miniVariant"
          :text="t('analytics')"
        />
        <template #append>
          <v-chip
            size="x-small"
            color="red"
            class="ml-1"
            text="beta"
          />
        </template>
      </v-list-item>

      <v-list-item
        :to="`/e/${hashId}/condition-overview`"
        prepend-icon="mdi-table-multiple"
        :title="t('conditionOverview')"
        @click.stop
      >
        <v-tooltip
          activator="parent"
          location="right"
          :disabled="!miniVariant"
          :text="t('conditionOverview')"
        />
      </v-list-item>

      <v-list-item
        v-if="safeHasRight('EXPORT')"
        :to="`/e/${hashId}/exports`"
        prepend-icon="mdi-database-export-outline"
        :title="t('dataExport')"
        @click.stop
      >
        <v-tooltip
          activator="parent"
          location="right"
          :disabled="!miniVariant"
          :text="t('dataExport')"
        />
      </v-list-item>

      <v-list-item
        v-if="safeHasRight('IMPORT')"
        :to="`/e/${hashId}/imports`"
        prepend-icon="mdi-database-import-outline"
        :title="t('dataImport')"
        @click.stop
      >
        <v-tooltip
          activator="parent"
          location="right"
          :disabled="!miniVariant"
          :text="t('dataImport')"
        />
      </v-list-item>

      <v-list-item
        v-if="
          safeHasRight('ENVIRONMENT_ADMIN') ||
          (safeHasRight('USERS') && safeHasRight('AUDIT_TRAIL'))
        "
        :to="settingsLink"
        prepend-icon="mdi-cog"
        :title="t('settings')"
        @click.stop
      >
        <v-tooltip
          activator="parent"
          location="right"
          :disabled="!miniVariant"
          :text="t('settings')"
        />
      </v-list-item>

      <v-list-item
        v-if="
          !safeHasRight('ENVIRONMENT_ADMIN') &&
          !safeHasRight('USERS') &&
          safeHasRight('AUDIT_TRAIL')
        "
        :to="`/e/${hashId}/environment/audit-log`"
        prepend-icon="mdi-schedule"
        :title="t('auditLog')"
        @click.stop
      >
        <v-tooltip
          activator="parent"
          location="right"
          :disabled="!miniVariant"
          :text="t('auditLog')"
        />
      </v-list-item>

      <v-list-item
        v-if="
          !safeHasRight('ENVIRONMENT_ADMIN') &&
          safeHasRight('USERS') &&
          !safeHasRight('AUDIT_TRAIL')
        "
        :to="`/e/${hashId}/environment/users`"
        prepend-icon="mdi-account-multiple"
        :title="t('users')"
        @click.stop
      >
        <v-tooltip
          activator="parent"
          location="right"
          :disabled="!miniVariant"
          :text="t('users')"
        />
      </v-list-item>
    </template>
  </w-framework>
</template>

<script setup lang="ts">
import { ref, computed, watch, onBeforeMount, onUnmounted, type Ref, inject } from 'vue';
import { type RouteLocationRaw } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useEnvironment } from '@web-ui-root/composables/environment';
import { useUUID } from '@web-ui-root/composables/uuid';

import WFramework from '@web-ui-root/components/framework/w-framework.vue';
import { onMounted } from 'vue';

type Props = {
  isMap?: boolean;
  noAppBar?: boolean;
};

type Option<T> = T | undefined;

withDefaults(defineProps<Props>(), {
  isMap: false,
  noAppBar: false,
});

const { t } = useI18n({
  messages: {
    en: {
      map: 'Map',
      analytics: 'Analytics',
      conditionOverview: 'Condition overview',
      devices: 'Devices',
      pinGroups: 'Locations',
      groups: 'Location groups',
      pins: 'Ports',
      edges: 'Lines',
      dataImport: 'Data import',
      dataExport: 'Data export',
      settings: 'Settings',
      auditLog: 'Audit log',
      users: 'Users',
      pinGrid: 'Port groups',
    },
    nl: {
      map: 'Kaart',
      analytics: 'Analytics',
      conditionOverview: 'Conditieoverzicht',
      devices: 'Devices',
      pinGroups: 'Locaties',
      groups: 'Locatie groepen',
      pins: 'Poorten',
      edges: 'Lijnen',
      dataImport: 'Data import',
      dataExport: 'Data export',
      settings: 'Instellingen',
      auditLog: 'Audit log',
      users: 'Gebruikers',
      pinGrid: 'Poort groepen',
    },
  },
});

const pushRoute: Option<(location: RouteLocationRaw) => Promise<boolean>> = inject('pushRoute');
const hasRight: undefined | ((value: string) => boolean) = inject('hasRight');

let timestampIntervalId: ReturnType<typeof setInterval> | null = null;
const timestamp: Ref<Date> = ref(new Date(Date.now()));

const { name, hashId, expiresAt } = useEnvironment();
const { uuid } = useUUID();
uuid.value.toString();
const environmentExpired = computed(
  () => expiresAt.value !== null && expiresAt.value < timestamp.value,
);

function safeHasRight(value: string) {
  if (hasRight !== undefined) {
    return hasRight(value);
  }
  return false;
}

const settingsLink = computed(() => {
  let link = '';

  if (safeHasRight('ENVIRONMENT_ADMIN')) {
    link = `/e/${hashId.value}/environment/general`;
  } else if (safeHasRight('USERS')) {
    link = `/e/${hashId.value}/environment/users`;
  } else {
    link = `/e/${hashId.value}/environment/audit-log`;
  }

  return link;
});

watch(environmentExpired, (val) => {
  if (val && pushRoute !== undefined) {
    pushRoute('/expired');
  }
});

onBeforeMount(() => {
  timestamp.value = new Date(Date.now());
  timestampIntervalId = setInterval(() => {
    timestamp.value = new Date(Date.now());
  }, 5000);
  if (environmentExpired.value && pushRoute !== undefined) {
    pushRoute('/expired');
  }
});

onMounted(() => {
  // @ts-expect-error global event defined in index.html
  document.dispatchEvent(loaderEvent);
});

onUnmounted(() => {
  if (timestampIntervalId !== null) {
    clearInterval(timestampIntervalId);
  }
});
</script>
